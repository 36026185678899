import React, { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { css } from '@emotion/react';
import {
  Criteria,
  EuiAccordion,
  EuiCallOut,
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiIcon,
  EuiInMemoryTable,
  EuiLink,
  EuiLoadingContent,
  EuiMark,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiToolTip,
  Pagination,
  PropertySort,
} from '@elastic/eui';
import {
  AsyncData,
  formatAum,
  Fund,
  PAGINATION,
  FundAssetExtended,
  formatWeight,
  SerializedAssetProfile,
  SerializedFund,
  AssetProfile,
  deserializeFund,
  deserializeAssetProfile,
} from '../model';
import { PageContext } from '../page_container';
import { AssetAlertsPanel, AssetIdLabel, FundDetailsFlyout, FundNameLabel } from '.';

export interface AssetDetailsFlyoutProps {
  assetSourceId: string;
  assetName: string;
  onClose: () => void;
}

export interface AssetDetails {
  profile: AssetProfile;
  funds: FundAssetExtended[];
}

export function AssetDetailsFlyout({ assetSourceId, assetName, onClose }: AssetDetailsFlyoutProps) {
  const [assetDetails, setAssetDetails] = useState<AsyncData<AssetDetails>>({ status: 'pending' });
  const [enhancedProfile, setEnhancedProfile] = useState<AsyncData<AssetProfile>>({ status: 'pending' });
  const [uniqueFunds, setUniqueFunds] = useState<number>(0);
  const { settings, parameters, getURL } = useContext(PageContext);

  useEffect(() => {
    if (!parameters.synced) {
      return;
    }

    axios
      .post(`/api/assets/profile`, {
        domicile: parameters.domicileCode,
        ids: [assetSourceId],
        includeFunds: true,
        resolveFunds: true,
      })
      .then(
        ({ data }: { data: { profiles: Array<SerializedAssetProfile | null>; funds?: Array<SerializedFund> } }) => {
          const serializedAssetProfile = data.profiles[0];
          if (!serializedAssetProfile) {
            setAssetDetails({ status: 'failed', error: 'Asset information is not available.' });
            return;
          }

          const profile = deserializeAssetProfile(serializedAssetProfile);
          const funds = new Map(
            data.funds?.map((serializedFund) => {
              const fund = deserializeFund(serializedFund);
              return [fund.isin, fund];
            }) ?? [],
          );

          const mappedFunds = [];
          for (const [fundId, fundAsset] of profile.funds ?? []) {
            const fund = funds.get(fundId);
            if (fund) {
              mappedFunds.push({ fund, ...fundAsset });
            }
          }

          setAssetDetails({ status: 'succeeded', data: { profile, funds: mappedFunds } });
          setUniqueFunds(new Set(mappedFunds.map(({ fund }) => fund.isin)).size);
          setPagination({ ...pagination, totalItemCount: mappedFunds.length });
        },
        (err) => {
          setAssetDetails({ status: 'failed', error: err?.message ?? err });
        },
      );

    axios
      .post(`/api/assets/profile`, {
        domicile: parameters.domicileCode,
        ids: [assetSourceId],
        includeBasics: true,
        includeFinancials: true,
      })
      .then(
        ({ data }: { data: { profiles: Array<SerializedAssetProfile | null> } }) => {
          const serializedAssetProfile = data.profiles[0];
          if (!serializedAssetProfile) {
            setEnhancedProfile({ status: 'failed', error: 'Asset profile is not available.' });
            return;
          }

          setEnhancedProfile({ status: 'succeeded', data: deserializeAssetProfile(serializedAssetProfile) });
        },
        (err) => {
          setEnhancedProfile({ status: 'failed', error: err?.message ?? err });
        },
      );
  }, [assetSourceId, parameters]);

  const [sorting, setSorting] = useState<{ sort: PropertySort }>({ sort: { field: 'weight', direction: 'desc' } });
  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: settings.defaultPageSize,
    pageSizeOptions: PAGINATION.pageSizeOptions,
    totalItemCount: 0,
  });
  const onTableChange = useCallback(
    ({ page, sort }: Criteria<FundAssetExtended>) => {
      setPagination({
        ...pagination,
        pageIndex: page?.index ?? 0,
        pageSize: page?.size ?? settings.defaultPageSize,
      });

      if (sort) {
        setSorting({ sort });
      }
    },
    [setPagination, pagination, settings],
  );

  const [fundToInspect, setFundToInspect] = useState<Fund | null>(null);
  const toggleFundDetails = useCallback(
    (fund?: Fund) => {
      setFundToInspect(fund === fundToInspect ? null : fund ?? null);
    },
    [fundToInspect],
  );

  let noFundsMessage;
  switch (assetDetails.status) {
    case 'failed':
      noFundsMessage = (
        <EuiCallOut title="Failed to retrieve asset details" color="danger" iconType="alert">
          <p>
            It is not possible to retrieve details for &nbsp;<EuiMark>{assetName}</EuiMark> at the moment.
          </p>
        </EuiCallOut>
      );
      break;
    case 'succeeded':
      noFundsMessage = (
        <EuiCallOut title="No funds found" color="warning" iconType="alert">
          <p>
            There are no funds available for the <EuiMark>{assetName}</EuiMark> in{' '}
            <EuiMark>
              {parameters.domiciles.find((domicile) => domicile.code === parameters.domicileCode)?.displayName ??
                'Unknown'}
            </EuiMark>
            .
          </p>
          <p>Try to change domicile.</p>
        </EuiCallOut>
      );
      break;
    case 'pending':
      noFundsMessage = 'Loading...';
      break;
  }

  const fundDetailsFlyout = fundToInspect ? (
    <FundDetailsFlyout fundOrProfile={fundToInspect} onClose={toggleFundDetails} />
  ) : null;

  const id = assetDetails.status === 'succeeded' ? assetDetails.data.profile.id : '-';
  const assetClass =
    (assetDetails.status === 'succeeded'
      ? parameters.assetClasses.get(assetDetails.data.profile.funds?.[0]?.[1]?.assetClass ?? '')?.name
      : '-') ?? '-';

  const enhancedDataLoading = (
    <EuiLoadingContent
      css={css`
        width: 100%;
      `}
      lines={2}
    />
  );

  const infoLink =
    enhancedProfile.status !== 'pending' ? (
      <EuiLink
        target={'_blank'}
        href={
          enhancedProfile.status === 'succeeded' && enhancedProfile.data.basics?.url
            ? enhancedProfile.data.basics?.url
            : `https://www.google.com/search?q=${encodeURIComponent(assetName)}`
        }
      >
        Learn more
      </EuiLink>
    ) : (
      enhancedDataLoading
    );

  const marketCap =
    enhancedProfile.status !== 'pending'
      ? enhancedProfile.status === 'succeeded' && enhancedProfile.data.financials?.baseMarketCap
        ? formatAum(enhancedProfile.data.financials?.baseMarketCap)
        : '-'
      : enhancedDataLoading;

  const country =
    enhancedProfile.status !== 'pending'
      ? enhancedProfile.status === 'succeeded'
        ? enhancedProfile.data.basics?.country ?? '-'
        : '-'
      : enhancedDataLoading;

  const sector =
    enhancedProfile.status !== 'pending'
      ? enhancedProfile.status === 'succeeded'
        ? enhancedProfile.data.basics?.sector ?? '-'
        : '-'
      : enhancedDataLoading;

  const description = (
    <>
      <EuiPanel hasShadow={false} color="subdued">
        <EuiAccordion
          id="asset-alerts"
          arrowDisplay="right"
          element="fieldset"
          buttonContent={
            <EuiFlexGroup gutterSize="s" alignItems="center" responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiIcon type="notebookApp" size="m" />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiTitle size="xs">
                  <h3>Description</h3>
                </EuiTitle>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
          paddingSize="m"
        >
          {enhancedProfile.status !== 'pending' ? (
            <EuiText>
              {enhancedProfile.status === 'succeeded' && enhancedProfile.data.basics?.description
                ? enhancedProfile.data.basics?.description
                : 'Description is not available.'}
            </EuiText>
          ) : (
            <EuiLoadingContent lines={3} />
          )}
        </EuiAccordion>
      </EuiPanel>
      <EuiSpacer />
    </>
  );

  const isLoading = assetDetails.status === 'pending';
  return (
    <EuiFlyout size="l" onClose={() => onClose()} ownFocus={true}>
      <EuiFlyoutHeader>
        <EuiTitle size="s">
          <h1>{assetName}</h1>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiPanel hasShadow={false} color="subdued">
          <EuiFlexGroup>
            <EuiFlexItem
              css={css`
                max-width: 65%;
              `}
            >
              <EuiDescriptionList
                descriptionProps={{ style: { height: '40px' } }}
                listItems={[
                  { title: 'ID', description: id },
                  {
                    title: 'Asset Class',
                    description: assetClass,
                  },
                  { title: 'Info link', description: infoLink },
                ]}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiDescriptionList
                descriptionProps={{ style: { height: '40px' } }}
                listItems={[
                  { title: 'Market cap', description: marketCap },
                  { title: 'Country', description: country },
                  { title: 'Sector', description: sector },
                ]}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size={'s'} />
          <EuiText size={'xs'} color={'subdued'}>
            The basic stock information is sourced from{' '}
            <EuiLink href="https://www.investing.com/" target={'_blank'}>
              Investing.com
            </EuiLink>
            , refer to{' '}
            <EuiLink href={getURL('/terms#stocks-data')} target={'_blank'}>
              Terms
            </EuiLink>{' '}
            for more details.
          </EuiText>
        </EuiPanel>
        <EuiSpacer />
        {description}
        <AssetAlertsPanel
          assetProfile={assetDetails.status === 'succeeded' ? assetDetails.data.profile : undefined}
          assetSourceId={assetSourceId}
        />
        <EuiSpacer />
        <EuiInMemoryTable
          items={assetDetails.status === 'succeeded' ? assetDetails.data.funds : []}
          loading={assetDetails.status === 'pending'}
          // @ts-expect-error no definition
          noItemsMessage={noFundsMessage}
          pagination={pagination}
          allowNeutralSort={true}
          sorting={sorting}
          onTableChange={onTableChange}
          columns={[
            {
              name: (
                <EuiToolTip content="ID of the asset from the source data feed">
                  <span>
                    Source ID <EuiIcon size="s" color="subdued" type="questionInCircle" className="eui-alignTop" />
                  </span>
                </EuiToolTip>
              ),
              field: 'id',
              width: '145px',
              render: (_, fundAsset) => <AssetIdLabel id={fundAsset.id} sourceId={fundAsset.sourceId} />,
            },
            {
              name: (
                <EuiToolTip content="Name of the asset from the source data feed">
                  <span>
                    Source name <EuiIcon size="s" color="subdued" type="questionInCircle" className="eui-alignTop" />
                  </span>
                </EuiToolTip>
              ),
              field: 'name',
              textOnly: true,
              truncateText: true,
            },
            {
              name: 'Weight',
              field: 'weight',
              width: '120px',
              render: (_, fundAsset) => formatWeight(fundAsset.weight),
              sortable: ({ weight }) => weight,
            },
            {
              name: 'ETF name',
              field: 'fundName',
              textOnly: true,
              render: (_, fundAsset) => <FundNameLabel fundOrProfile={fundAsset.fund} displayHints displayProvider />,
              footer:
                assetDetails.status === 'succeeded' && assetDetails.data.funds.length > uniqueFunds ? (
                  <EuiToolTip content="Some funds might include different share classes at the same time">
                    <span>
                      <strong>{assetDetails.status === 'succeeded' ? assetDetails.data.funds.length : 0}</strong>{' '}
                      positions in <strong>{uniqueFunds}</strong> funds{' '}
                      <EuiIcon size="s" color="subdued" type="questionInCircle" className="eui-alignTop" />
                    </span>
                  </EuiToolTip>
                ) : (
                  <span>
                    <strong>{assetDetails.status === 'succeeded' ? assetDetails.data.funds.length : 0}</strong> funds
                  </span>
                ),
            },
            {
              name: 'Actions',
              field: 'fund',
              width: '65px',
              actions: [
                {
                  name: 'View fund details',
                  description: 'View fund details',
                  icon: 'inspect',
                  type: 'icon',
                  enabled: () => !isLoading,
                  onClick: (fundAsset) => toggleFundDetails(fundAsset.fund),
                },
              ],
            },
          ]}
        />
      </EuiFlyoutBody>
      {fundDetailsFlyout}
    </EuiFlyout>
  );
}
