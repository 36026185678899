import React, { MouseEventHandler, useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EuiButtonEmpty,
  EuiContextMenuItem,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiNotificationEvent,
  EuiPanel,
  EuiTitle,
} from '@elastic/eui';
import {
  getNotificationEvent,
  Notification,
  NotificationEvent,
  NotificationStatus,
  NotificationStatusType,
  settingsSetNotificationAsRead,
  settingsSetNotificationAsRemoved,
} from '../model';
import { PageContext } from './page_context';

export interface Props {
  notifications: Notification[];
  onClose: () => void;
}

export function NotificationsFlyout({ onClose, notifications }: Props) {
  const { settings, setSettings, getURL } = useContext(PageContext);
  const navigate = useNavigate();
  const notificationStatuses = useMemo<Map<number, NotificationStatus>>(
    () => new Map(settings.notifications?.statuses.map((status) => [status.id, status]) ?? []),
    [settings],
  );

  const notificationEvents = useMemo<NotificationEvent[]>(() => {
    const notificationEvents = [];
    for (const notification of notifications) {
      const status = notificationStatuses.get(notification.id);
      if (status?.type !== NotificationStatusType.Removed) {
        notificationEvents.push(getNotificationEvent(notification, status?.type === NotificationStatusType.Read));
      }
    }

    return notificationEvents;
  }, [notifications, notificationStatuses]);

  const onNotificationRead = useCallback(
    (id: string, isRead: boolean) => {
      setSettings(settingsSetNotificationAsRead(settings, +id, !isRead));
    },
    [settings],
  );

  const onClickTitle = useCallback(
    (id: string) => {
      const notificationEvent = notificationEvents.find((notificationEvent) => notificationEvent.id === id);
      if (notificationEvent?.link) {
        window.open(notificationEvent.link, '_blank', 'noopener,noreferrer');
      }
    },
    [notificationEvents],
  );

  const onNotificationRemove = useCallback(
    (id: number) => {
      setSettings(settingsSetNotificationAsRemoved(settings, id));
    },
    [settings],
  );

  const onOpenContextMenu = useCallback(
    (id: string) => {
      const notification = notificationEvents.find((notification) => notification.id === id);
      if (!notification) {
        return [];
      }

      return [
        <EuiContextMenuItem key="contextMenuItemA" onClick={() => onNotificationRead(id, notification.isRead)}>
          {notification.isRead ? 'Mark as unread' : 'Mark as read'}
        </EuiContextMenuItem>,
        <EuiContextMenuItem key="contextMenuItemC" onClick={() => onNotificationRemove(+id)}>
          Remove
        </EuiContextMenuItem>,
      ];
    },
    [notificationEvents],
  );

  const onNavigateToAlertsPage: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      e.preventDefault();
      navigate(getURL('/alerts'));
      onClose();
    },
    [getURL],
  );

  const content =
    notificationEvents.length > 0 ? (
      notificationEvents.map((notification) => {
        return (
          <EuiNotificationEvent
            key={notification.id}
            id={notification.id}
            type={notification.type}
            iconType={notification.iconType}
            iconAriaLabel={notification.iconAriaLabel}
            badgeColor={notification.badgeColor}
            time={notification.time}
            title={notification.title}
            isRead={notification.isRead}
            messages={notification.messages}
            onRead={onNotificationRead}
            onOpenContextMenu={onOpenContextMenu}
            onClickTitle={notification.link ? onClickTitle : undefined}
          />
        );
      })
    ) : (
      <EuiEmptyPrompt
        iconType="bell"
        iconColor="default"
        title={<h2>You're all caught up</h2>}
        titleSize="xs"
        body={
          <p>When we have any news to share with you, or any of your alerts are triggered, you’ll be notified here.</p>
        }
      />
    );

  return (
    <EuiFlyout size="s" onClose={onClose} ownFocus={true}>
      <EuiFlyoutHeader>
        <EuiTitle size="s">
          <EuiFlexGroup alignItems={'center'} gutterSize="s">
            <EuiFlexItem>
              <h1>Notifications</h1>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                aria-label={'Manage alerts'}
                iconType="gear"
                color="primary"
                onClick={onNavigateToAlertsPage}
                title={'Manage alerts'}
                href={getURL('/alerts')}
              >
                Manage alerts
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem grow={false} />
          </EuiFlexGroup>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiPanel role="feed" paddingSize="none" hasShadow={false} hasBorder={true}>
          {content}
        </EuiPanel>
      </EuiFlyoutBody>
    </EuiFlyout>
  );
}
