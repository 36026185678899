import React from 'react';
import { EuiToolTip, EuiText } from '@elastic/eui';
import { DetailedPortfolioFund } from './portfolios_funds_table';
import { DetailedPortfolioAsset } from './portfolios_assets_table';
import { formatPrice } from '../../model';

export interface PortfolioPositionPriceProps {
  position: DetailedPortfolioAsset | DetailedPortfolioFund;
  multiplier?: number;
}

export function PortfolioPositionPrice({ position, multiplier = 1 }: PortfolioPositionPriceProps) {
  const { basePrice } = position.profile?.financials ?? {};
  const priceValue = (basePrice ?? position.price ?? 0) * multiplier;
  if (typeof basePrice !== 'number' && typeof position.price === 'number') {
    return (
      <EuiToolTip
        content={"The price for this position isn't automatically updated and might be incorrect or outdated"}
      >
        <EuiText size="s" className="eui-textNoWrap" color={'danger'}>
          <b>{formatPrice(priceValue)}</b>
        </EuiText>
      </EuiToolTip>
    );
  }

  return (
    <EuiText size="s" className="eui-textNoWrap">
      {formatPrice(priceValue)}
    </EuiText>
  );
}
