import { useEffect } from 'react';

export function usePageMeta(pageTitle: string, pageContent?: string) {
  useEffect(() => {
    document.title = `AZbyte | ETF - ${pageTitle}`;

    const description = document.querySelector('meta[name="description"]');
    if (description) {
      description.setAttribute(
        'content',
        pageContent ?? `Explore UCITS compliant exchange traded funds (ETFs) and see what's inside.`,
      );
    }
  }, [pageTitle, pageContent]);
}
