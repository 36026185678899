import { EuiCallOut } from '@elastic/eui';
import React, { useContext } from 'react';
import { PageContext } from '../page_container';

export interface FundNotSupportedDomicileNoticeProps {
  fundDomiciles: string[];
}

export function FundNotSupportedDomicileNotice({ fundDomiciles }: FundNotSupportedDomicileNoticeProps) {
  const { parameters, domicileCode } = useContext(PageContext);

  return (
    <EuiCallOut
      title={`Not available in ${
        parameters.domiciles.find((domicile) => domicile.code === domicileCode)?.displayName ?? 'your country'
      }`}
      color="warning"
      iconType="alert"
    >
      <p>
        {`Fund is available for sale only in the following countries: ${parameters.domiciles
          .filter((domicile) => fundDomiciles.includes(domicile.code))
          .map((domicile) => domicile.displayName)
          .join(', ')}`}
      </p>
    </EuiCallOut>
  );
}
