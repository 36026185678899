import React, { useContext } from 'react';
import { EuiText } from '@elastic/eui';
import { getAssetSourceIdTypeLabel } from '../model';
import { PageContext } from '../page_container';

const AssetIdAdminLabel = React.lazy(() => import('./asset_id_admin_label'));

export interface AssetIdLabelProps {
  id: string;
  sourceId: string;
}

export function AssetIdLabel({ id, sourceId }: AssetIdLabelProps) {
  const { settings } = useContext(PageContext);

  if (!settings.accessToken) {
    return (
      <EuiText title={getAssetSourceIdTypeLabel(sourceId)} size="s">
        {id}
      </EuiText>
    );
  }

  return <AssetIdAdminLabel id={id} sourceId={sourceId} />;
}
