import React, { MouseEventHandler, useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Criteria,
  EuiCallOut,
  EuiIconTip,
  EuiInMemoryTable,
  EuiLink,
  EuiMark,
  EuiText,
  Pagination,
  PropertySort,
} from '@elastic/eui';
import {
  FundAsset,
  AsyncData,
  FundAssets,
  PAGINATION,
  formatWeight,
  FundProfile,
  SerializedFundProfile,
  deserializeFundProfile,
  getAssetSourceIdTypeLabel,
  getFundURL,
  Fund,
  isFundProfile,
  ASSET_ID_LABELS,
} from '../../model';
import { PageContext } from '../../page_container';
import { useNavigate } from 'react-router-dom';

export function FundAssetsTable({
  fundOrProfile,
  onAssetsLoaded,
}: {
  fundOrProfile: Fund | FundProfile;
  onAssetsLoaded?: (assets: FundAssets) => void;
}) {
  const navigate = useNavigate();

  const [fundAssets, setFundAssets] = useState<AsyncData<FundAssets>>({ status: 'pending' });
  const { settings, parameters, getURL } = useContext(PageContext);

  const fundId = isFundProfile(fundOrProfile) ? fundOrProfile.id : fundOrProfile.isin;

  useEffect(() => {
    if (!parameters.synced) {
      return;
    }

    axios
      .post('/api/funds/profile', {
        domicile: parameters.domicileCode,
        ids: [fundId],
        includeHoldings: true,
        conflateHoldings: settings.conflateHoldings,
      })
      .then(
        ({ data }: { data: { profiles: Array<SerializedFundProfile | null> } }) => {
          if (data.profiles.length > 0 && data.profiles[0]) {
            const fundAssets = deserializeFundProfile(data.profiles[0]).holdings ?? {
              assets: [],
              totalWeight: 0,
              asOfDate: '',
            };

            setFundAssets({ status: 'succeeded', data: fundAssets });
            setPagination({ ...pagination, totalItemCount: fundAssets.assets.length });
            onAssetsLoaded?.(fundAssets);
          } else {
            setFundAssets({ status: 'failed', error: `Fund with ISIN ${fundId} is not found.` });
          }
        },
        (err) => {
          setFundAssets({ status: 'failed', error: err?.message ?? err });
        },
      );
  }, [fundOrProfile, parameters]);

  const [sorting, setSorting] = useState<{ sort: PropertySort }>({ sort: { field: 'weight', direction: 'desc' } });

  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: settings.defaultPageSize,
    pageSizeOptions: PAGINATION.pageSizeOptions,
    totalItemCount: 0,
  });
  const onTableChange = useCallback(
    ({ page, sort }: Criteria<FundAsset>) => {
      setPagination({
        ...pagination,
        pageIndex: page?.index ?? 0,
        pageSize: page?.size ?? settings.defaultPageSize,
      });

      if (sort) {
        setSorting({ sort });
      }
    },
    [setPagination, pagination, settings],
  );

  const fundName = fundOrProfile ? <EuiMark>{fundOrProfile.name}</EuiMark> : <>'a fund'</>;
  const fundLinkSuggestion = fundOrProfile ? (
    <p>
      Try visit{' '}
      <EuiLink href={getFundURL(fundOrProfile)} target="_blank">
        fund info page
      </EuiLink>{' '}
      instead.
    </p>
  ) : null;

  let noItemsMessage;
  switch (fundAssets.status) {
    case 'failed':
      noItemsMessage = (
        <EuiCallOut title="Failed to retrieve holdings" color="danger" iconType="alert">
          <p>
            It is not possible to retrieve holdings for &nbsp;{fundName} at the moment. Most likely fund provider
            doesn't publish detailed holdings information for this fund.
          </p>
          {fundLinkSuggestion}
        </EuiCallOut>
      );
      break;
    case 'succeeded':
      noItemsMessage = (
        <EuiCallOut title="No holdings found" color="warning" iconType="alert">
          <p>
            There are no holdings for &nbsp;{fundName} available at the moment. Most likely fund provider doesn't
            publish detailed holdings information for this fund.
          </p>
          {fundLinkSuggestion}
        </EuiCallOut>
      );
      break;
    case 'pending':
      noItemsMessage = 'Loading...';
      break;
  }

  const onNavigateToAssetsPage: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      e.preventDefault();

      if (getURL('/assets') === location.pathname) {
        location.href = e.currentTarget.href;
      } else {
        navigate(e.currentTarget.href.replace(`${location.protocol}//${location.host}`, ''));
      }
    },
    [getURL],
  );

  return (
    <EuiInMemoryTable
      items={fundAssets.status === 'succeeded' ? fundAssets.data.assets : []}
      loading={fundAssets.status === 'pending'}
      // @ts-expect-error no definition
      noItemsMessage={noItemsMessage}
      pagination={pagination}
      allowNeutralSort={true}
      sorting={sorting}
      onTableChange={onTableChange}
      columns={[
        {
          name: 'ID',
          field: 'id',
          width: '145px',
          render: (_, fundAsset) => {
            const idLabel = getAssetSourceIdTypeLabel(fundAsset.sourceId);
            return fundAsset.id === '-' || idLabel === ASSET_ID_LABELS.cash || idLabel === ASSET_ID_LABELS.other ? (
              <EuiText title={idLabel}>-</EuiText>
            ) : (
              <EuiLink
                title={idLabel}
                href={getURL(`/assets?query=${fundAsset.id.includes(' ') ? fundAsset.id.split(' ')[0] : fundAsset.id}`)}
                onClick={onNavigateToAssetsPage}
                color={'success'}
              >
                {fundAsset.id}
              </EuiLink>
            );
          },
        },
        {
          name: 'Name',
          field: 'name',
          truncateText: true,
          sortable: true,
          footer: <strong>{fundAssets.status === 'succeeded' ? fundAssets.data.assets.length : 0} assets</strong>,
        },
        {
          name: 'Weight',
          field: 'weight',
          width: '120px',
          sortable: true,
          render: (_, fundAsset) => formatWeight(fundAsset.weight),
          footer: (
            <strong>
              {formatWeight(fundAssets.status === 'succeeded' ? fundAssets.data.totalWeight : 0)}{' '}
              <EuiIconTip
                content="The total weight can be slightly above or below 100% due to unclassified fund holdings and other inaccuracies in the fund provider data feed. The effect is usually negligible, but feel free to contact us if you believe that the precision is inadequate."
                position="top"
              />
            </strong>
          ),
        },
      ]}
    />
  );
}
