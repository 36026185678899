import React, { MouseEventHandler, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiCallOut,
  EuiLink,
  EuiEmptyPrompt,
  EuiIcon,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui';
import { settingsDontShowAlertsNotice } from '../../model';
import { PageContext } from '../../page_container';
import { AssetAlertsTable } from './asset_alerts_table';
import { usePageMeta } from '../../hooks';

export function AlertsPage() {
  usePageMeta('Alerts');

  const navigate = useNavigate();
  const { settings, setSettings, getURL, parameters } = useContext(PageContext);

  const dismissAlertsNotice = useCallback(() => {
    setSettings(settingsDontShowAlertsNotice(settings));
  }, [settings]);

  const onNavigateToAssets: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      e.preventDefault();
      navigate(getURL('/assets'));
    },
    [getURL],
  );

  const onNavigatePrivacyPolicy: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      e.preventDefault();
      navigate(getURL('/privacy'));
    },
    [getURL],
  );

  let content;
  if (!settings.alerts || settings.alerts.length === 0) {
    content = (
      <EuiFlexGroup
        direction={'column'}
        gutterSize={'s'}
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <EuiFlexItem>
          <EuiEmptyPrompt
            icon={<EuiIcon type={'watchesApp'} size={'xl'} />}
            title={<h2>You don't have any alerts yet</h2>}
            titleSize="s"
            style={{ maxWidth: '60em', display: 'flex' }}
            body={
              <p>
                Go to the the{' '}
                <EuiLink href={getURL('/assets')} onClick={onNavigateToAssets}>
                  Assets page
                </EuiLink>{' '}
                and add alerts for the asset events you are interested in.
              </p>
            }
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  } else {
    const alertsNotice =
      settings.dontShowAlertsNotice !== true ? (
        <EuiFlexItem grow={false}>
          <EuiCallOut title="How are your alerts stored and evaluated?" color="warning" iconType="help">
            <p>
              Your alerts as well as portfolios and other personal settings are stored locally in the cache of your
              browser and not synced between your devices. No one except you can access your data, and it remains
              available as long as you don't clear the cache. For more information, refer to{' '}
              <EuiLink href={getURL('/privacy')} onClick={onNavigatePrivacyPolicy}>
                Privacy Policy
              </EuiLink>
              .
            </p>
            <p>
              You can have at most <strong>{parameters.license.maxAlerts}</strong> alerts that are evaluated{' '}
              <strong>only</strong> when you visit this website. Contact us if you need more alerts or want to receive
              alert notifications over email.
            </p>
            <EuiButton color="warning" onClick={dismissAlertsNotice}>
              Understood
            </EuiButton>
          </EuiCallOut>
        </EuiFlexItem>
      ) : null;

    const assetsAlertsTable =
      settings.alerts.length > 0 ? (
        <EuiFlexItem grow={false}>
          <EuiTitle>
            <h3>Asset alerts</h3>
          </EuiTitle>
          <EuiSpacer />
          <AssetAlertsTable />
        </EuiFlexItem>
      ) : null;

    content = (
      <EuiFlexGroup direction={'column'} gutterSize={'s'} style={{ height: '100%' }}>
        {alertsNotice}
        {assetsAlertsTable}
      </EuiFlexGroup>
    );
  }

  return content;
}
