import { IconType, EuiBadgeProps } from '@elastic/eui';
import { ReactNode } from 'react';
import moment from 'moment';

export interface SerializedNotification {
  i: number;
  k: string;
  ts: string;
  t: string;
  c: string;
  l?: string;
}

export type NotificationType = 'news' | 'alert';

export interface Notification {
  id: number;
  type: NotificationType;
  title: string;
  messages: string[];
  time: moment.Moment;
  link?: string;
}

export enum NotificationStatusType {
  Read = 'read',
  Removed = 'removed',
}

export interface NotificationStatus {
  id: number;
  type: NotificationStatusType;
}

export interface NotificationEvent {
  id: string;
  type: string;
  iconType: IconType;
  iconAriaLabel: string;
  badgeColor: EuiBadgeProps['color'];
  time: ReactNode;
  title: string;
  messages: string[];
  isRead: boolean;
  link?: string;
}

export function deserializeNotification(serializedNotification: SerializedNotification): Notification {
  let messages;
  let title;
  if (serializedNotification.k === 'n') {
    messages = [serializedNotification.c];
    title = serializedNotification.t;
  } else {
    try {
      const parsedContent = JSON.parse(serializedNotification.c);
      messages = parsedContent?.result ?? [];
      title =
        parsedContent?.rule?.type === 'assetAddedToFund'
          ? 'Asset is added to a fund'
          : parsedContent?.rule?.type === 'assetRemovedFromFund'
            ? 'Asset is removed from a fund'
            : serializedNotification.t;
    } catch {
      title = serializedNotification.t;
    }
  }
  return {
    id: serializedNotification.i,
    type: serializedNotification.k === 'n' ? 'news' : 'alert',
    title,
    messages,
    time: moment(serializedNotification.ts),
    link: serializedNotification.l,
  };
}

export function getNotificationEvent(notification: Notification, isRead: boolean): NotificationEvent {
  return {
    id: notification.id.toString(),
    time: notification.time.fromNow(),
    title: notification.title,
    messages: notification.messages,
    link: notification.link,
    isRead,
    ...getNotificationEventProps(notification.type),
  };
}

function getNotificationEventProps(
  type: NotificationType,
): Pick<NotificationEvent, 'type' | 'iconType' | 'iconAriaLabel' | 'badgeColor'> {
  if (type === 'news') {
    return {
      badgeColor: 'success',
      iconAriaLabel: 'News',
      iconType: 'cheer',
      type: 'News',
    };
  }

  return {
    badgeColor: 'warning',
    iconAriaLabel: 'Alert',
    iconType: 'flag',
    type: 'Alert',
  };
}
