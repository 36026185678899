import { PAGINATION } from './pagination';
import { CacheUpdateApiParams } from './cache_update_api_params';
import { SettingsV1, SettingsV2, upgradeSettingsV1ToSettingsV3, upgradeSettingsV2ToSettingsV3 } from './legacy';
import { Alert, AlertDescriptor, AlertType } from './alerts';
import { NotificationStatus, NotificationStatusType } from './notifications';
import { Fund } from './fund';
import { FundConflatedAsset, getBestSourceId, matchesSourceId } from './fund_conflated_asset';

export const CURRENT_SETTINGS_VERSION = 3;

export interface PortfolioFund {
  isin: string;
  units: number;
  price?: number;
  name?: string;
}

export interface PortfolioAsset {
  id: string;
  units: number;
  price?: number;
  name?: string;
}

export interface Portfolio {
  name: string;
  funds: PortfolioFund[];
  assets: PortfolioAsset[];
}

export interface Settings {
  version: number;
  accessToken?: string;
  cacheUpdateApiParams?: CacheUpdateApiParams;
  defaultPageSize: number;
  conflateHoldings: boolean;
  dontShowPortfolioHints?: boolean;
  dontShowDataNotice?: boolean;
  dontShowAlertsNotice?: boolean;
  theme?: 'light' | 'dark';
  country?: string;
  portfolios: Portfolio[];
  notifications?: { statuses: NotificationStatus[] };
  alerts?: Alert[];
}

export function settingsGetBaseCurrency() {
  return '€';
}

function isFund(item: Fund | FundConflatedAsset): item is Fund {
  return 'isin' in item;
}

export function settingsSavePortfolio(settings: Settings, name: string, item: Fund | FundConflatedAsset): Settings {
  const trimmedName = name.trim();
  if (!name) {
    return settings;
  }

  const existingPortfolio = settings.portfolios.find((group) => group.name === trimmedName);
  if (existingPortfolio) {
    if (isFund(item)) {
      if (!existingPortfolio.funds.some((existingFund) => existingFund.isin === item.isin)) {
        existingPortfolio.funds.push({ isin: item.isin, units: 100 });
      }
    } else {
      if (!existingPortfolio.assets.some((existingAsset) => matchesSourceId(item, existingAsset.id))) {
        existingPortfolio.assets.push({ id: getBestSourceId(item), units: 100 });
      }
    }
  } else {
    settings.portfolios.push(
      isFund(item)
        ? { name: trimmedName, funds: [{ isin: item.isin, units: 100 }], assets: [] }
        : { name: trimmedName, funds: [], assets: [{ id: getBestSourceId(item), units: 100 }] },
    );
  }

  return { ...settings };
}

export function settingsAddPortfolio(settings: Settings, portfolio: Portfolio): Settings {
  const existingPortfolioIndex = settings.portfolios.findIndex((group) => group.name === portfolio.name);
  if (existingPortfolioIndex < 0) {
    return { ...settings, portfolios: [...settings.portfolios, portfolio] };
  }

  settings.portfolios.splice(existingPortfolioIndex, 1, portfolio);

  return { ...settings, portfolios: [...settings.portfolios] };
}

export function settingsRemovePortfolio(settings: Settings, portfolioName: string): Settings {
  const existingPortfolioIndex = settings.portfolios.findIndex((group) => group.name === portfolioName);
  if (existingPortfolioIndex < 0) {
    return settings;
  }

  settings.portfolios.splice(existingPortfolioIndex, 1);

  return { ...settings };
}

export function settingsSetPortfolios(settings: Settings, portfolios: Portfolio[]) {
  return { ...settings, portfolios: [...portfolios] };
}

export function settingsDontShowPortfolioHints(settings: Settings): Settings {
  return { ...settings, dontShowPortfolioHints: true };
}

export function settingsDontShowDataNotice(settings: Settings): Settings {
  return { ...settings, dontShowDataNotice: true };
}

export function settingsDontShowAlertsNotice(settings: Settings): Settings {
  return { ...settings, dontShowAlertsNotice: true };
}

export function settingsSetAccessToken(settings: Settings, accessToken: string): Settings {
  return { ...settings, accessToken };
}

export function settingsSetNotificationAsRead(settings: Settings, id: number, isRead: boolean): Settings {
  const existingStatusIndex =
    settings.notifications?.statuses.findIndex((existingStatus) => existingStatus.id === id) ?? -1;
  if (settings.notifications && existingStatusIndex >= 0) {
    if (isRead) {
      settings.notifications.statuses[existingStatusIndex] = { id, type: NotificationStatusType.Read };
    } else {
      settings.notifications.statuses.splice(existingStatusIndex, 1);
    }
  } else if (isRead) {
    const newStatus = { id, type: NotificationStatusType.Read };
    if (settings.notifications) {
      settings.notifications.statuses.push(newStatus);
    } else {
      settings.notifications = { statuses: [newStatus] };
    }
  }

  return { ...settings };
}

export function settingsSetNotificationAsRemoved(settings: Settings, id: number): Settings {
  const existingStatusIndex =
    settings.notifications?.statuses.findIndex((existingStatus) => existingStatus.id === id) ?? -1;
  const newStatus = { id, type: NotificationStatusType.Removed };
  if (settings.notifications && existingStatusIndex >= 0) {
    settings.notifications.statuses[existingStatusIndex] = newStatus;
  } else {
    if (settings.notifications) {
      settings.notifications.statuses.push(newStatus);
    } else {
      settings.notifications = { statuses: [newStatus] };
    }
  }

  return { ...settings };
}

export function settingsSetNotificationStatuses(
  settings: Settings,
  notificationStatuses: NotificationStatus[],
): Settings {
  return { ...settings, notifications: { statuses: notificationStatuses } };
}

export function settingsAddAlert(settings: Settings, alert: Alert): Settings {
  if (!settings.alerts) {
    return { ...settings, alerts: [alert] };
  }

  const existingAlertIndex = getAlertIndex(settings, alert);
  if (existingAlertIndex >= 0) {
    settings.alerts[existingAlertIndex] = alert;
  } else {
    settings.alerts.push(alert);
  }

  return { ...settings };
}

export function settingsRemoveAlert(settings: Settings, descriptor: AlertDescriptor): Settings {
  const existingAlertIndex = getAlertIndex(settings, descriptor);
  if (settings.alerts && existingAlertIndex >= 0) {
    settings.alerts.splice(existingAlertIndex, 1);
    return { ...settings };
  }

  return settings;
}

export function getAlert(settings: Settings, descriptor: AlertDescriptor): Alert | null {
  const alertIndex = getAlertIndex(settings, descriptor);
  return settings.alerts && alertIndex >= 0 ? settings.alerts[alertIndex] : null;
}

function getAlertIndex(settings: Settings, descriptor: AlertDescriptor): number {
  return (settings.alerts ?? []).findIndex((existingAlert) => {
    return (
      ((descriptor.type === AlertType.AssetIsAddedToFund && existingAlert.type === AlertType.AssetIsAddedToFund) ||
        (descriptor.type === AlertType.AssetIsRemovedFromFund &&
          existingAlert.type === AlertType.AssetIsRemovedFromFund)) &&
      descriptor.assetSourceId === existingAlert.assetSourceId
    );
  });
}

export function settingsSetCacheUpdateApiParams(
  settings: Settings,
  cacheUpdateApiParams: CacheUpdateApiParams,
): Settings {
  return { ...settings, cacheUpdateApiParams };
}

export function settingsDefault(): Settings {
  return {
    version: CURRENT_SETTINGS_VERSION,
    defaultPageSize: PAGINATION.defaultPageSize,
    conflateHoldings: true,
    country: 'gb',
    portfolios: [],
    notifications: { statuses: [] },
    alerts: [],
  };
}

export function upgradeSettings(settings: SettingsV1 | SettingsV2 | Settings): Settings {
  if (settings.version === CURRENT_SETTINGS_VERSION) {
    const upgradedSettings = settings as Settings;
    return {
      ...settings,
      portfolios: upgradedSettings.portfolios.filter(
        (portfolio) => portfolio.funds.length > 0 || portfolio.assets.length > 0,
      ),
    };
  }

  try {
    if (!settings.version || settings.version === 1) {
      return upgradeSettingsV1ToSettingsV3(settings as SettingsV1);
    }

    return upgradeSettingsV2ToSettingsV3(settings as SettingsV2);
  } catch {
    return settingsDefault();
  }
}
