import {
  EuiButton,
  EuiButtonEmpty,
  EuiComboBox,
  EuiForm,
  EuiFormRow,
  EuiLink,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from '@elastic/eui';
import { useNavigate } from 'react-router-dom';
import React, { MouseEventHandler, useCallback, useContext, useMemo, useState } from 'react';
import { Fund, FundConflatedAsset, settingsSavePortfolio } from '../model';
import { PageContext } from '../page_container';

export interface SaveToPortfolioModalParams {
  item: Fund | FundConflatedAsset;
  onClose: () => void;
}

export function AddToPortfolioModal({ item, onClose }: SaveToPortfolioModalParams) {
  const navigate = useNavigate();
  const { settings, getURL, setSettings, addToast } = useContext(PageContext);
  const existingPortfolios = useMemo(
    () => settings.portfolios.map((portfolio) => ({ label: portfolio.name })),
    [settings],
  );

  const [selectedPortfolios, setSelectedPortfolios] = useState<Array<{ label: string }>>([]);
  const onSelectedPortfolioChange = useCallback(setSelectedPortfolios, [selectedPortfolios]);
  const onCreateNewPortfolio = (searchValue = '') => {
    const normalizedSearchValue = searchValue.trim();
    if (!normalizedSearchValue) {
      return;
    }

    setSelectedPortfolios([...selectedPortfolios, { label: normalizedSearchValue }]);
  };

  const onNavigateToPortfoliosPage: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      e.preventDefault();
      navigate(
        getURL(
          selectedPortfolios.length === 1
            ? `/portfolios/${encodeURIComponent(selectedPortfolios[0].label)}`
            : '/portfolios',
        ),
      );

      onClose();
    },
    [selectedPortfolios, getURL],
  );

  const onCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const addToPortfolios = useCallback(() => {
    for (const { label: name } of selectedPortfolios) {
      setSettings(settingsSavePortfolio(settings, name, item));
    }

    const title =
      selectedPortfolios.length > 1 ? (
        <>
          Your{' '}
          <EuiLink href={getURL('/portfolios')} onClick={onNavigateToPortfoliosPage}>
            portfolios
          </EuiLink>{' '}
          were updated
        </>
      ) : (
        <>
          Your{' '}
          <EuiLink
            href={getURL(`/portfolios/${encodeURIComponent(selectedPortfolios[0].label)}`)}
            onClick={onNavigateToPortfoliosPage}
          >
            portfolio
          </EuiLink>{' '}
          was updated
        </>
      );

    addToast({
      id: `updated-portfolio-${Date.now()}`,
      iconType: 'submodule',
      color: 'success',
      title,
    });

    setSelectedPortfolios([]);

    onClose();
  }, [settings, selectedPortfolios, onClose, addToast]);

  return (
    <EuiModal onClose={onCloseModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Add to portfolio</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiForm id="save-selection-form" component="form">
          <EuiFormRow helpText="Select an existing portfolio, or create a new one.">
            <EuiComboBox
              placeholder="Type portfolio name"
              options={existingPortfolios}
              selectedOptions={selectedPortfolios}
              onChange={onSelectedPortfolioChange}
              onCreateOption={onCreateNewPortfolio}
              customOptionText="Save {searchValue} as a new portfolio"
            />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty href={getURL('/portfolios')} iconType={'submodule'} onClick={onNavigateToPortfoliosPage}>
          Manage portfolios
        </EuiButtonEmpty>
        <EuiButtonEmpty onClick={onCloseModal}>Cancel</EuiButtonEmpty>
        <EuiButton
          type="submit"
          form="save-selection-form"
          fill
          onClick={addToPortfolios}
          isDisabled={selectedPortfolios.length === 0}
        >
          Add
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}
