import { Fund, formatWeight } from '../../model';

export interface FundsScoreExplanationTableParams {
  fund: Fund;
}

export function FundsScoreExplanationTable(params: FundsScoreExplanationTableParams) {
  const content = JSON.parse(params.fund.meta._score) as {
    explanation: Array<[string, string, number | null, number | null]>;
  };

  return (
    <table className="funds-score-explanation-table">
      <tbody>
        {content.explanation
          .filter(([, , , weight]) => weight != null && weight > 0)
          .map(([id, name, , weight]) => (
            <tr key={id}>
              <td>{name}</td>
              <td>&nbsp;&nbsp;</td>
              <td>{formatWeight(weight)}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
