import { Parameters } from './parameters';

export const DEFAULT_DOMICILE_CODE = 'gb';

export interface Domicile {
  displayName: string;
  code: string;
}

export function getDomicileLabel(parameters: Parameters) {
  return `${
    parameters.domiciles.find((domicile) => domicile.code === parameters.domicileCode)?.displayName ?? 'Unknown'
  } | Private Investor`;
}

export const DOMICILE_CODES: string[] = [DEFAULT_DOMICILE_CODE, 'de', 'at', 'fr', 'it', 'lu', 'nl', 'es', 'ch'];
