import React, { useContext } from 'react';
import { EuiText } from '@elastic/eui';
import { ClassNames } from '@emotion/react';
import { PageContext } from '../page_container';
import { Fund, FundProfile } from '../model';

export interface FundProviderNameLabelProps {
  fundOrProfile: Fund | FundProfile;
}

export function FundProviderNameLabel({ fundOrProfile }: FundProviderNameLabelProps) {
  const { parameters } = useContext(PageContext);

  const fundProvider = fundOrProfile ? parameters.providers.get(fundOrProfile.provider) : null;
  return (
    <ClassNames>
      {({ css }) => (
        <EuiText
          size="s"
          className="eui-textNoWrap"
          css={css`
            display: inline;
            color: #6092c0;
            font-weight: 700;
            margin-right: 0.3rem;
          `}
        >
          {fundProvider?.name ?? fundOrProfile.provider}
        </EuiText>
      )}
    </ClassNames>
  );
}
