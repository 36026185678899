import { PortfolioFund, Settings } from '../settings';

export interface PortfolioV1 {
  name: string;
  funds: PortfolioFund[];
}

export interface SettingsV1 extends Omit<Settings, 'version' | 'portfolios'> {
  version?: 1;
  portfolios: {
    draft: PortfolioFund[];
    saved: PortfolioV1[];
  };
}

export function upgradeSettingsV1ToSettingsV3(settings: SettingsV1): Settings {
  const draftPortfolios =
    settings.portfolios.draft?.length > 0
      ? [{ name: 'Draft (auto-saved)', funds: settings.portfolios.draft, assets: [] }]
      : [];
  const savedPortfolios =
    settings.portfolios.saved?.length > 0
      ? settings.portfolios.saved
          .filter((portfolio) => portfolio.funds.length > 0)
          .map((portfolio) => ({ ...portfolio, assets: [] }))
      : [];

  return {
    ...settings,
    version: 3,
    portfolios: [...draftPortfolios, ...savedPortfolios],
  };
}
