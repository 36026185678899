import { deserializeFundAsset, FundAsset, SerializedFundAsset } from './fund_asset';
import { parseAssetSourceId } from './asset_id';

export interface AssetProfile {
  id: string;
  sourceId: string;
  name: string;
  funds?: Array<[string, FundAsset]>;
  basics?: AssetBasics;
  financials?: AssetFinancials;
}

export interface AssetBasics {
  url?: string;
  description?: string;
  sector?: string;
  country?: string;
  industry?: string;
}

export interface AssetFinancials {
  currency?: string;
  price?: number;
  basePrice?: number;
  exchange?: string;
  marketCap?: number;
  baseMarketCap?: number;
  asOfDate?: string;
}

export interface SerializedAssetProfile {
  i: string;
  n: string;
  f?: Array<[string, SerializedFundAsset]>;
  b?: SerializedAssetBasics;
  fi?: SerializedAssetFinancials;
}

export interface SerializedAssetBasics {
  u?: string;
  d?: string;
  s?: string;
  c?: string;
  i?: string;
}

export interface SerializedAssetFinancials {
  c?: string;
  p?: number;
  bp?: number;
  e?: string;
  m?: number;
  bm?: number;
  a?: string;
}

export function deserializeAssetProfile(serializedAssetProfile: SerializedAssetProfile): AssetProfile {
  const profile: AssetProfile = {
    id: parseAssetSourceId(serializedAssetProfile.i),
    sourceId: serializedAssetProfile.i,
    name: serializedAssetProfile.n,
  };

  if (serializedAssetProfile.f) {
    profile.funds = serializedAssetProfile.f.map(([fundIsin, fundAsset]) => [
      fundIsin,
      deserializeFundAsset(fundAsset),
    ]);
  }

  if (serializedAssetProfile.b) {
    profile.basics = deserializeAssetBasics(serializedAssetProfile.b);
  }

  if (serializedAssetProfile.fi) {
    profile.financials = deserializeAssetFinancials(serializedAssetProfile.fi);
  }

  return profile;
}

function deserializeAssetBasics(serializedAssetBasics: SerializedAssetBasics): AssetBasics {
  return {
    url: serializedAssetBasics.u,
    description: serializedAssetBasics.d,
    country: serializedAssetBasics.c,
    industry: serializedAssetBasics.i,
    sector: serializedAssetBasics.s,
  };
}

function deserializeAssetFinancials(serializedAssetFinancials: SerializedAssetFinancials): AssetFinancials {
  return {
    currency: serializedAssetFinancials.c,
    asOfDate: serializedAssetFinancials.a,
    exchange: serializedAssetFinancials.e,
    marketCap: serializedAssetFinancials.m,
    baseMarketCap: serializedAssetFinancials.bm,
    price: serializedAssetFinancials.p,
    basePrice: serializedAssetFinancials.bp,
  };
}
