import React, { useCallback, useContext } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText, EuiIcon, EuiButtonIcon } from '@elastic/eui';
import { settingsDontShowPortfolioHints } from '../model';
import { PageContext } from '../page_container';

export function PortfolioHintsPanel() {
  const { settings, setSettings } = useContext(PageContext);

  const dismissPortfolioHints = useCallback(() => {
    setSettings(settingsDontShowPortfolioHints(settings));
  }, [settings]);

  const showPortfolioHints = settings.dontShowPortfolioHints !== true && settings.portfolios.length === 0;
  if (!showPortfolioHints || settings.portfolios.length > 0) {
    return null;
  }

  return (
    <EuiPanel paddingSize={'s'} color="primary">
      <EuiFlexGroup alignItems={'center'} justifyContent={'flexStart'} gutterSize={'s'} responsive={false}>
        <EuiFlexItem grow={false} style={{ paddingTop: '8px', paddingBottom: '8px' }}>
          <EuiIcon color={'primary'} type="submodule" size={'l'} />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            Use <EuiIcon type={'plusInCircle'} /> button to group funds and standalone assets into portfolios for
            analysis
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            aria-label={'Do not show again'}
            iconType={'cross'}
            onClick={dismissPortfolioHints}
            title={'Do not show again'}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
}
