import { FundAsset, deserializeFundAsset, SerializedFundAsset } from './fund_asset';

export interface SerializedFundAssets {
  assets: SerializedFundAsset[];
  totalWeight: number;
  asOfDate: string;
}

export interface FundAssets {
  assets: FundAsset[];
  totalWeight: number;
  asOfDate: string;
}

export function deserializeFundAssets(serializedFundAssets: SerializedFundAssets) {
  return {
    assets: serializedFundAssets.assets.map((serializedAsset) => deserializeFundAsset(serializedAsset)),
    totalWeight: serializedFundAssets.totalWeight,
    asOfDate: serializedFundAssets.asOfDate,
  };
}
