import {
  EuiAccordion,
  EuiButtonEmpty,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiPanel,
  EuiSwitch,
  EuiTitle,
} from '@elastic/eui';
import React, { MouseEventHandler, useCallback, useContext } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { AlertType, settingsAddAlert, settingsRemoveAlert, getAlert, AssetProfile } from '../model';
import { PageContext } from '../page_container';

export interface AssetAlertsPanelProps {
  assetSourceId: string;
  assetProfile?: AssetProfile;
}

export function AssetAlertsPanel({ assetProfile, assetSourceId }: AssetAlertsPanelProps) {
  const { settings, setSettings, parameters, getURL } = useContext(PageContext);
  const navigate = useNavigate();

  const isAlertAssetAddedToFundEnabled = !!getAlert(settings, { type: AlertType.AssetIsAddedToFund, assetSourceId });
  const isAlertAssetRemovedFromFundEnabled = !!getAlert(settings, {
    type: AlertType.AssetIsRemovedFromFund,
    assetSourceId,
  });

  const assetName = assetProfile ? `"${assetProfile.name}"` : 'asset';
  const onToggleAlert = useCallback(
    (type: AlertType, checked: boolean) => {
      if (checked) {
        setSettings(settingsAddAlert(settings, { type, assetSourceId, timestamp: moment.utc().format() }));
      } else {
        setSettings(settingsRemoveAlert(settings, { type, assetSourceId }));
      }
    },
    [settings, assetSourceId],
  );

  const onNavigateToAlertsPage: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      e.preventDefault();
      navigate(getURL('/alerts'));
    },
    [getURL],
  );

  const maxAlertsExceeded = settings.alerts && settings.alerts.length >= parameters.license.maxAlerts;
  const maxAlertsNotice =
    (!isAlertAssetAddedToFundEnabled || !isAlertAssetRemovedFromFundEnabled) && maxAlertsExceeded ? (
      <EuiFlexItem grow={false}>
        <EuiCallOut title="You've reached the maximum number of alerts" color="warning" iconType="alert">
          <p>
            You can have at most <strong>{parameters.license.maxAlerts}</strong> alerts. To add new alerts, try to
            remove the alerts you no longer need or contact us.
          </p>
          {getURL('/alerts') !== location.pathname ? (
            <EuiButtonEmpty
              aria-label={'Manage alerts'}
              iconType="gear"
              color="primary"
              onClick={onNavigateToAlertsPage}
              title={'Manage alerts'}
              href={getURL('/alerts')}
            >
              Manage alerts
            </EuiButtonEmpty>
          ) : null}
        </EuiCallOut>
      </EuiFlexItem>
    ) : null;

  return (
    <EuiPanel hasShadow={false} color={'subdued'}>
      <EuiAccordion
        id="asset-alerts"
        isLoading={assetProfile === undefined}
        arrowDisplay="right"
        element="fieldset"
        buttonContent={
          <EuiFlexGroup gutterSize="s" alignItems="center" responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiIcon type="watchesApp" size="m" />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiTitle size="xs">
                <h3>Alerts</h3>
              </EuiTitle>
            </EuiFlexItem>
          </EuiFlexGroup>
        }
        paddingSize="m"
      >
        <EuiForm component="form">
          <EuiFlexGroup direction="column">
            {maxAlertsNotice}
            <EuiFlexItem>
              <EuiFormRow display="columnCompressedSwitch">
                <EuiSwitch
                  title={`Notify when ${assetName} is included to any ETF`}
                  label={'Inclusion in fund'}
                  checked={isAlertAssetAddedToFundEnabled}
                  onChange={(e) => onToggleAlert(AlertType.AssetIsAddedToFund, e.target.checked)}
                  disabled={!isAlertAssetAddedToFundEnabled && maxAlertsExceeded}
                />
              </EuiFormRow>
              <EuiFormRow display="columnCompressedSwitch">
                <EuiSwitch
                  title={`Notify when ${assetName} is excluded from any ETF`}
                  label={'Exclusion from fund'}
                  checked={isAlertAssetRemovedFromFundEnabled}
                  onChange={(e) => onToggleAlert(AlertType.AssetIsRemovedFromFund, e.target.checked)}
                  disabled={!isAlertAssetRemovedFromFundEnabled && maxAlertsExceeded}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>
      </EuiAccordion>
    </EuiPanel>
  );
}
