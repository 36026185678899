import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import {
  Criteria,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiInMemoryTable,
  Pagination,
  EuiButton,
  PropertySort,
} from '@elastic/eui';
import {
  FundConflatedAsset,
  AsyncData,
  deserializeFundConflatedAsset,
  PAGINATION,
  SerializedFundConflatedAsset,
} from '../../model';
import { PageContext } from '../../page_container';
import { AssetsSplashScreen, SplashScreenMode } from './assets_splash_screen';
import { AddToPortfolioModal, AssetDetailsFlyout, AssetIdLabel, PortfolioHintsPanel } from '../../components';
import { usePageMeta } from '../../hooks';

interface SearchParams {
  query?: string;
}

export function AssetsPage() {
  usePageMeta('Assets');

  const navigate = useNavigate();

  const location = useLocation();
  const parsedQueryString = new URLSearchParams(location.search);
  const searchQueryParam = parsedQueryString.get('query') ?? '';
  const [assetSearchQuery, setAssetSearchQuery] = useState<string>(searchQueryParam);

  const [searchOnLoad] = useState(!!searchQueryParam);

  const [searchState, setSearchState] = useState<
    AsyncData<FundConflatedAsset[] | null, { previousData: FundConflatedAsset[] }>
  >(searchOnLoad ? { status: 'pending' } : { status: 'succeeded', data: null });

  const { settings, parameters, getURL } = useContext(PageContext);

  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: settings.defaultPageSize,
    pageSizeOptions: PAGINATION.pageSizeOptions,
    totalItemCount: 0,
  });

  const [assetToInspect, setAssetToInspect] = useState<FundConflatedAsset | null>(null);
  const toggleAssetDetails = useCallback(
    (asset?: FundConflatedAsset) => {
      window.location.hash = asset ? `#${asset.id}` : '';
      setAssetToInspect(asset === assetToInspect ? null : asset ?? null);
    },
    [assetToInspect],
  );

  const [sorting, setSorting] = useState<{ sort: PropertySort }>({ sort: { field: '', direction: 'asc' } });

  const [assetToAddToPortfolio, setAssetToAddToPortfolio] = useState<FundConflatedAsset | null>(null);
  const toggleAddToPortfolioModal = useCallback((asset: FundConflatedAsset | null = null) => {
    setAssetToAddToPortfolio(asset);
  }, []);

  const onTableChange = useCallback(
    ({ page, sort }: Criteria<FundConflatedAsset>) => {
      setPagination({
        ...pagination,
        pageIndex: page?.index ?? 0,
        pageSize: page?.size ?? settings.defaultPageSize,
      });

      if (typeof sort?.field === 'string') {
        setSorting({ sort });
      }
    },
    [pagination, settings],
  );

  const updateCurrentLocation = useCallback(
    ({ query = assetSearchQuery }: SearchParams) => {
      const pathname = getURL('/assets');

      let search = '';
      if (query) {
        search = `?query=${encodeURIComponent(query)}`;
      }

      if (search !== location.search || pathname !== location.pathname) {
        navigate({ pathname, search });
      }
    },
    [assetSearchQuery, getURL],
  );

  const performSearch = useCallback(
    ({ query }: SearchParams) => {
      if (!query) {
        setPagination({ ...pagination, pageIndex: 0, totalItemCount: 0 });
        setSearchState({ status: 'succeeded', data: [] });
        return;
      }

      setSearchState({
        status: 'pending',
        state: searchState.status === 'succeeded' && searchState.data ? { previousData: searchState.data } : undefined,
      });

      axios.post('/api/assets/search', { domicile: parameters.domicileCode, query }).then(
        ({ data: assets }: { data: SerializedFundConflatedAsset[] }) => {
          setSearchState({ status: 'succeeded', data: assets.map(deserializeFundConflatedAsset) });
          setPagination({ ...pagination, pageIndex: 0, totalItemCount: assets.length });
        },
        () => {
          setPagination({ ...pagination, pageIndex: 0, totalItemCount: 0 });
          setSearchState({ status: 'succeeded', data: [] });
        },
      );
    },
    [searchState, pagination, parameters, assetSearchQuery],
  );

  const onSearchQueryChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const query = e.target.value;
      setAssetSearchQuery(query);
      updateCurrentLocation({ query });
    },
    [updateCurrentLocation],
  );

  const onSearchSubmit = useCallback(
    (query: string) => {
      if (!query) {
        setSearchState({ status: 'succeeded', data: null });
      } else {
        performSearch({ query });
      }
    },
    [performSearch],
  );
  const onSearchButtonClick = useCallback(
    () => performSearch({ query: assetSearchQuery }),
    [assetSearchQuery, performSearch],
  );

  useEffect(() => {
    if (parameters.synced && searchOnLoad) {
      performSearch({ query: assetSearchQuery });
    }
  }, [parameters, searchOnLoad]);

  const assetDetailsFlyout = assetToInspect ? (
    <AssetDetailsFlyout
      assetSourceId={assetToInspect.sourceId}
      assetName={assetToInspect.name}
      onClose={toggleAssetDetails}
    />
  ) : null;

  const addAssetToPortfolioModal = assetToAddToPortfolio ? (
    <AddToPortfolioModal item={assetToAddToPortfolio} onClose={() => toggleAddToPortfolioModal(null)} />
  ) : null;

  let mainContent;
  if (searchState.status === 'pending' && (!searchState.state || searchState.state.previousData.length === 0)) {
    mainContent = <AssetsSplashScreen mode={SplashScreenMode.Loading} />;
  } else if (searchState.status === 'succeeded' && !searchState.data) {
    mainContent = <AssetsSplashScreen mode={SplashScreenMode.Default} />;
  } else if (searchState.status === 'succeeded' && searchState.data?.length === 0) {
    mainContent = <AssetsSplashScreen mode={SplashScreenMode.NoData} />;
  } else {
    const isLoading = searchState.status === 'pending';
    const items =
      (searchState.status === 'succeeded'
        ? searchState.data
        : searchState.status === 'pending'
          ? searchState.state?.previousData
          : []) ?? [];
    mainContent = (
      <EuiInMemoryTable
        pagination={pagination}
        allowNeutralSort={true}
        sorting={sorting}
        onTableChange={onTableChange}
        loading={isLoading}
        items={items}
        itemId="id"
        columns={[
          {
            name: 'ID',
            field: 'id',
            width: '145px',
            render: (_, asset) => <AssetIdLabel id={asset.id} sourceId={asset.sourceId} />,
          },
          {
            name: 'Asset Class',
            field: 'id',
            width: '105px',
            render: (id, asset) => parameters.assetClasses.get(asset.funds[0]?.[1]?.assetClass)?.name ?? '-',
          },
          {
            name: 'Name',
            field: 'name',
            sortable: true,
            footer: <strong>{items.length} assets</strong>,
          },
          {
            name: 'Actions',
            field: 'fund',
            width: '75px',
            actions: [
              {
                name: 'View asset details',
                description: 'View asset details',
                icon: 'inspect',
                type: 'icon',
                enabled: () => !isLoading,
                onClick: toggleAssetDetails,
              },
              {
                name: 'Add to portfolio',
                description: 'Add to portfolio',
                icon: 'plusInCircle',
                type: 'icon',
                enabled: () => !isLoading,
                onClick: (asset) => toggleAddToPortfolioModal(asset),
              },
            ],
          },
        ]}
      />
    );
  }

  const portfolioHintsPanel =
    searchState.status === 'succeeded' && (searchState.data?.length ?? 0) > 0 ? (
      <EuiFlexItem grow={false}>
        <PortfolioHintsPanel />
      </EuiFlexItem>
    ) : null;

  return (
    <>
      <EuiFlexGroup direction={'column'} gutterSize={'s'} style={{ height: '100%' }}>
        <EuiFlexItem grow={false}>
          <EuiForm>
            <EuiFormRow fullWidth>
              <EuiFlexGroup gutterSize={'s'}>
                <EuiFlexItem>
                  <EuiFieldSearch
                    fullWidth
                    placeholder="Search asset by name, ISIN or ticker"
                    value={assetSearchQuery}
                    isClearable={true}
                    isLoading={searchState.status === 'pending'}
                    onChange={onSearchQueryChange}
                    onSearch={onSearchSubmit}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton iconType="search" onClick={onSearchButtonClick}>
                    Search
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFormRow>
          </EuiForm>
        </EuiFlexItem>
        {portfolioHintsPanel}
        <EuiFlexItem>{mainContent}</EuiFlexItem>
      </EuiFlexGroup>
      {assetDetailsFlyout}
      {addAssetToPortfolioModal}
    </>
  );
}
