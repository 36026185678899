export enum CacheUpdateMode {
  Skip = 'skip',
  Prefetch = 'prefetch',
  Full = 'full',
}

export enum CacheIndexArchiveUpdateMode {
  Skip = 'skip',
  KeepVersion = 'keepVersion',
  IncrementVersion = 'incrementVersion',
}

export enum CacheUpdateResource {
  ExchangeRates = 'exchange-rates',
  Taxonomy = 'taxonomy',
  Funds = 'funds',
  Assets = 'assets',
  ExcludedAssets = 'excluded-assets',
}

export interface CacheUpdateApiParams {
  domicile?: string;
  resources: {
    exchangeRates: {
      mode: CacheUpdateMode;
      maxAge?: number;
    };
    taxonomy: {
      mode: CacheUpdateMode;
      maxAge?: number;
    };
    funds: {
      mode: CacheUpdateMode;
      maxAge?: number;
      filter?: { fundsProvider: string };
      indexArchiveMode: CacheIndexArchiveUpdateMode;
    };
    assets: {
      mode: CacheUpdateMode;
      maxAge?: number;
      filter?: { fundsProvider?: string; fundsAssetClass?: string; fundsQuery?: string; fundsToExclude?: string[] };
      indexArchiveMode: CacheIndexArchiveUpdateMode;
    };
    excludedAssets: {
      mode: CacheUpdateMode;
      maxAge?: number;
    };
  };
}
