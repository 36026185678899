import { deserializeFundAssets, FundAssets, SerializedFundAssets } from './fund_assets';
import { Fund } from './fund';
import { deserializeTag, SerializedTag, Tag } from './tag';

export function isFundAnalyzable(fund: FundProfile) {
  const assetClass = fund.basics?.assetClass;
  return (
    typeof assetClass === 'string' &&
    assetClass !== 'fixed_income' &&
    assetClass !== 'currencies' &&
    assetClass !== 'commodities'
  );
}

export function getFundURL(fundOrProfile: Fund | FundProfile) {
  const fundLink = isFundProfile(fundOrProfile) ? fundOrProfile.basics?.url : fundOrProfile.infoLink;
  return fundLink ?? `https://www.google.com/search?q=${encodeURIComponent(fundOrProfile.name)}`;
}

export function isFundProfile(fundOrProfile: Fund | FundProfile): fundOrProfile is FundProfile {
  return 'id' in fundOrProfile;
}

export interface FundProfile {
  id: string;
  name: string;
  provider: string;
  domiciles: string[];
  tags?: Tag[];
  basics?: FundBasics;
  financials?: FundFinancials;
  holdings?: FundAssets;
}

export interface FundBasics {
  tickers?: string[];
  assetClass: string;
  isSynthetic: boolean;
  url: string;
  ter: number;
  aum: number;
}

export interface FundFinancials {
  currency?: string;
  price?: number;
  basePrice?: number;
  exchange?: string;
  asOfDate?: string;
}

export interface SerializedFundProfile {
  i: string;
  n: string;
  p: string;
  d: string[];
  tg?: SerializedTag[];
  b?: SerializedFundBasics;
  f?: SerializedFundFinancials;
  h?: SerializedFundAssets;
}

export interface SerializedFundBasics {
  tk?: string[];
  ac: string;
  r: string;
  u: string;
  t: number;
  a: number;
}

export interface SerializedFundFinancials {
  c?: string;
  p?: number;
  bp?: number;
  e?: string;
  a?: string;
}

export function deserializeFundProfile(serializedFundProfile: SerializedFundProfile): FundProfile {
  const profile: FundProfile = {
    id: serializedFundProfile.i,
    name: serializedFundProfile.n,
    provider: serializedFundProfile.p,
    domiciles: serializedFundProfile.d,
  };

  if (serializedFundProfile.tg) {
    profile.tags = serializedFundProfile.tg.map((serializedTag) => deserializeTag(serializedTag));
  }

  if (serializedFundProfile.b) {
    profile.basics = deserializeFundBasics(serializedFundProfile.b);
  }

  if (serializedFundProfile.f) {
    profile.financials = deserializeFundFinancials(serializedFundProfile.f);
  }

  if (serializedFundProfile.h) {
    profile.holdings = deserializeFundAssets(serializedFundProfile.h);
  }

  return profile;
}

function deserializeFundBasics(serializedFundBasics: SerializedFundBasics): FundBasics {
  return {
    tickers: serializedFundBasics.tk,
    assetClass: serializedFundBasics.ac,
    isSynthetic: serializedFundBasics.r === 's',
    url: serializedFundBasics.u,
    ter: serializedFundBasics.t,
    aum: serializedFundBasics.a,
  };
}

function deserializeFundFinancials(serializedFundFinancials: SerializedFundFinancials): FundFinancials {
  return {
    currency: serializedFundFinancials.c,
    asOfDate: serializedFundFinancials.a,
    exchange: serializedFundFinancials.e,
    price: serializedFundFinancials.p,
    basePrice: serializedFundFinancials.bp,
  };
}
