export const ASSET_ID_LABELS = Object.freeze({
  isin: 'ISIN',
  ticker: 'Ticker',
  cusip: 'CUSIP',
  sedol: 'SEDOL',
  wkn: 'WKN',
  commodityCode: 'Commodity code',
  crypto: 'Crypto',
  figi: 'FIGI',
  figiSynthetic: 'Synthetic',
  cash: 'Cash',
  other: 'Other',
});

export function parseAssetSourceId(sourceId: string) {
  const separatorIndex = sourceId.lastIndexOf(':');
  return separatorIndex >= 0 ? sourceId.slice(separatorIndex + 1) : sourceId;
}

export function getAssetSourceIdTypeLabel(sourceId: string) {
  if (sourceId === 'C') {
    return ASSET_ID_LABELS.cash;
  }

  if (sourceId === 'O') {
    return ASSET_ID_LABELS.other;
  }

  return parseAssetSourceIdLabel(sourceId) ?? ASSET_ID_LABELS.other;
}

function parseAssetSourceIdLabel(sourceId: string) {
  if (sourceId.startsWith('I:')) {
    return ASSET_ID_LABELS.isin;
  }

  if (sourceId.startsWith('T:')) {
    return ASSET_ID_LABELS.ticker;
  }

  if (sourceId.startsWith('C:')) {
    return ASSET_ID_LABELS.cusip;
  }

  if (sourceId.startsWith('S:')) {
    return ASSET_ID_LABELS.sedol;
  }

  if (sourceId.startsWith('W:')) {
    return ASSET_ID_LABELS.wkn;
  }

  if (sourceId.startsWith('CC:')) {
    return ASSET_ID_LABELS.commodityCode;
  }

  if (sourceId.startsWith('CR:')) {
    return ASSET_ID_LABELS.crypto;
  }

  if (sourceId.startsWith('F:$:')) {
    return ASSET_ID_LABELS.figiSynthetic;
  }

  if (sourceId.startsWith('F:')) {
    return ASSET_ID_LABELS.figi;
  }

  return null;
}
