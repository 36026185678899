import {
  EuiCallOut,
  EuiComboBox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiMark,
  EuiPanel,
  EuiText,
  EuiComboBoxOptionOption,
} from '@elastic/eui';
import React, { useContext, useEffect, useState } from 'react';
import { AsyncData, deserializeTag, formatTag, Fund, FundProfile, isFundProfile, SerializedTag, Tag } from '../model';
import { PageContext } from '../page_container';
import axios from 'axios';

export interface FundAdminPanelProps {
  fundOrProfile: Fund | FundProfile;
  updateTags?: (tags: Tag[]) => void;
}

export function FundAdminPanel({ fundOrProfile, updateTags }: FundAdminPanelProps) {
  const { settings, addToast } = useContext(PageContext);

  const fundId = isFundProfile(fundOrProfile) ? fundOrProfile.id : fundOrProfile.isin;

  const [tags, setTags] = useState<AsyncData<Array<EuiComboBoxOptionOption<Tag>>>>({ status: 'pending' });
  useEffect(() => {
    axios.get('/api/taxonomy/tags', { headers: { Authorization: `Bearer ${settings.accessToken}` } }).then(
      ({ data }: { data: SerializedTag[] }) => {
        setTags({
          status: 'succeeded',
          data: data.map((serializedTag) => {
            const tag = deserializeTag(serializedTag);
            return { key: tag.id.toString(), value: tag, label: formatTag(tag) };
          }),
        });
      },
      (err) => {
        setTags({ status: 'failed', error: err?.message ?? err });
      },
    );
  }, [settings.accessToken]);

  const [selectedTags, setSelectedTags] = useState<Array<EuiComboBoxOptionOption<Tag>>>(
    (fundOrProfile.tags ?? []).map((tag) => ({
      key: tag.id.toString(),
      value: tag,
      label: formatTag(tag),
    })),
  );

  const onTagsChange = (options: Array<EuiComboBoxOptionOption<Tag>>) => {
    setSelectedTags([...options]);

    axios
      .post(
        `/api/funds/meta/add`,
        {
          fundId: fundId,
          tags: options.map((option) => option.value?.value),
        },
        { headers: { Authorization: `Bearer ${settings.accessToken}` } },
      )
      .then(() => {
        updateTags?.(options.map((option) => option.value!));
      })
      .catch(() => {
        addToast({
          color: 'danger',
          id: fundId,
          title: 'Failed to update tags',
          iconType: 'alert',
        });
      });
  };

  const content =
    tags.status === 'pending' ? (
      <EuiFlexGroup justifyContent={'center'} alignItems={'center'} style={{ height: '100%' }} responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiLoadingSpinner size="xl" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiText>Loading tags...</EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    ) : tags.status === 'failed' ? (
      <EuiCallOut title="Failed to retrieve tags" color="danger" iconType="alert">
        <p>
          It is not possible to retrieve metadata for &nbsp;<EuiMark>{fundOrProfile.name}</EuiMark> at the moment.
        </p>
      </EuiCallOut>
    ) : (
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiComboBox
            fullWidth
            isClearable={true}
            onChange={onTagsChange}
            options={tags.data}
            placeholder="Select tags"
            selectedOptions={selectedTags}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    );

  return <EuiPanel color="subdued">{content}</EuiPanel>;
}
