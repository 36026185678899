import {
  EuiFilterButton,
  EuiFilterGroup,
  EuiPopover,
  EuiSelect,
  EuiComboBox,
  EuiForm,
  EuiFormRow,
  EuiSwitch,
  EuiButton,
  EuiRange,
  EuiPopoverFooter,
  EuiSwitchEvent,
  EuiComboBoxOptionOption,
} from '@elastic/eui';
import axios from 'axios';
import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import { PageContext } from '../../page_container';
import { deserializeFundConflatedAsset, SerializedFundConflatedAsset } from '../../model';

export interface FundsSearchFilterValue {
  fundProviderType: string;
  fundAssetClass: string;
  holdingsMatchAll: boolean;
  holdingsMinWeight: number;
  holdings: Array<{ name: string; id: string }>;
}

export interface FundsSearchFilterParams {
  value: FundsSearchFilterValue;
  onChange: (filterValue: FundsSearchFilterValue) => void;
  onApply: (filterValue: FundsSearchFilterValue) => void;
}

function debounce(callback: (searchValue: string) => void) {
  let timeout: number;
  return (searchValue: string) => {
    window.clearTimeout(timeout);
    timeout = window.setTimeout(() => callback(searchValue), 300);
  };
}

export function FundsSearchFilter(params: FundsSearchFilterParams) {
  const { parameters } = useContext(PageContext);

  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<FundsSearchFilterValue>(params.value);

  const onFundProviderTypeChange = useCallback(
    async (e: ChangeEvent<HTMLSelectElement>) => {
      const newFilterValue = { ...filterValue, fundProviderType: e.target.value };
      setFilterValue(newFilterValue);
      params.onChange(newFilterValue);
    },
    [params, filterValue],
  );

  const onFundAssetClassChange = useCallback(
    async (e: ChangeEvent<HTMLSelectElement>) => {
      const newFilterValue = { ...filterValue, fundAssetClass: e.target.value };
      setFilterValue(newFilterValue);
      params.onChange(newFilterValue);
    },
    [params, filterValue],
  );

  const onHoldingsMatchAllChange = useCallback(
    async (e: EuiSwitchEvent) => {
      const newFilterValue = { ...filterValue, holdingsMatchAll: e.target.checked };
      setFilterValue(newFilterValue);
      params.onChange(newFilterValue);
    },
    [params, filterValue],
  );

  const onHoldingsMinWeightChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>, isValid: boolean) => {
      if (!isValid) {
        return;
      }

      const newFilterValue = { ...filterValue, holdingsMinWeight: +e.target.value };
      setFilterValue(newFilterValue);
      params.onChange(newFilterValue);
    },
    [params, filterValue],
  );

  const [areHoldingsLoading, setAreHoldingsLoading] = useState(false);
  const [holdingsOptions, setHoldingsOptions] = useState<EuiComboBoxOptionOption<{ id: string; name: string }>[]>([]);
  const onHoldingsSearchChange = useCallback(
    debounce((searchValue: string) => {
      if (!searchValue) {
        setAreHoldingsLoading(false);
        return;
      }

      setAreHoldingsLoading(true);
      setHoldingsOptions([]);

      axios.post('/api/assets/search', { domicile: parameters.domicileCode, query: searchValue, group: true }).then(
        ({ data: assets }: { data: Record<string, SerializedFundConflatedAsset[]> }) => {
          setAreHoldingsLoading(false);

          const groups = Object.entries(assets).sort(([nameA], [nameB]) =>
            nameA < nameB ? -1 : nameA > nameB ? 1 : 0,
          );

          setHoldingsOptions(
            groups.map(([groupName, group]) => ({
              isGroupLabelOption: true,
              label: groupName,
              options: group.map((serializedAsset) => {
                const asset = deserializeFundConflatedAsset(serializedAsset);
                return { value: { name: asset.name, id: asset.sourceId }, key: asset.sourceId, label: asset.name };
              }),
            })),
          );
        },
        () => {
          setAreHoldingsLoading(false);
        },
      );
    }),
    [areHoldingsLoading],
  );

  const onHoldingsChange = useCallback(
    (options: EuiComboBoxOptionOption<{ id: string; name: string }>[]) => {
      const newFilterValue = { ...filterValue, holdings: options.map((option) => option.value!) };
      setFilterValue(newFilterValue);
      params.onChange(newFilterValue);
    },
    [filterValue],
  );

  const onHoldingsBlur = useCallback(() => {
    setHoldingsOptions([]);
  }, []);

  const onCloseFilterPanel = useCallback(() => {
    setIsFilterPanelOpen(false);
  }, []);

  const onToggleFilterPanel = useCallback(() => {
    setIsFilterPanelOpen(!isFilterPanelOpen);
  }, [isFilterPanelOpen]);

  const onApply = useCallback(() => {
    setIsFilterPanelOpen(false);
    params.onApply(filterValue);
  }, [params, filterValue]);

  const numFilter =
    (filterValue.fundProviderType !== 'all' ? 1 : 0) +
    (filterValue.fundAssetClass !== 'all' ? 1 : 0) +
    (filterValue.holdings.length > 0 ? 1 : 0) +
    (filterValue.holdingsMatchAll ? 1 : 0) +
    (filterValue.holdingsMinWeight > 0 ? 1 : 0);

  const filterButton = (
    <EuiFilterButton
      iconType="arrowDown"
      onClick={onToggleFilterPanel}
      isSelected={isFilterPanelOpen}
      numFilters={numFilter}
      hasActiveFilters={numFilter > 0}
      numActiveFilters={numFilter}
    >
      Filters
    </EuiFilterButton>
  );

  return (
    <>
      <EuiFilterGroup>
        <EuiPopover
          button={filterButton}
          isOpen={isFilterPanelOpen}
          closePopover={onCloseFilterPanel}
          anchorPosition={'downCenter'}
        >
          <EuiForm component="form" style={{ minWidth: '300px', maxWidth: '300px' }}>
            <EuiFormRow display="rowCompressed" label="Fund provider" fullWidth={true}>
              <EuiSelect
                fullWidth={true}
                value={filterValue.fundProviderType}
                options={[{ type: 'all', name: 'Any provider' }, ...Array.from(parameters.providers.values())].map(
                  (providerType) => ({
                    value: providerType.type,
                    text: providerType.name,
                  }),
                )}
                onChange={onFundProviderTypeChange}
              />
            </EuiFormRow>
            <EuiFormRow display="rowCompressed" label="Fund asset class" fullWidth={true}>
              <EuiSelect
                fullWidth={true}
                value={filterValue.fundAssetClass}
                options={[
                  { type: 'all', name: 'Any asset class' },
                  ...Array.from(parameters.fundAssetClasses.values()),
                ].map((assetClass) => ({
                  value: assetClass.type,
                  text: assetClass.name,
                }))}
                onChange={onFundAssetClassChange}
              />
            </EuiFormRow>
            <EuiFormRow display="rowCompressed" label="Fund holdings" fullWidth={true}>
              <EuiComboBox
                async={true}
                fullWidth={true}
                isLoading={areHoldingsLoading}
                onSearchChange={onHoldingsSearchChange}
                onChange={onHoldingsChange}
                onBlur={onHoldingsBlur}
                options={holdingsOptions}
                placeholder="Search asset by name or ID"
                selectedOptions={filterValue.holdings.map((holding) => ({
                  key: holding.id,
                  value: holding,
                  label: holding.name,
                }))}
              />
            </EuiFormRow>
            <EuiFormRow
              display="rowCompressed"
              label="Fund holding weight"
              isDisabled={filterValue.holdings.length === 0}
            >
              <EuiRange
                min={0}
                max={10}
                value={filterValue.holdingsMinWeight}
                step={0.1}
                showLabels
                showValue
                valuePrepend={'≥ '}
                valueAppend={'%'}
                // @ts-expect-error invalid signature
                onChange={onHoldingsMinWeightChange}
                aria-label="Minimum weight a holding should have."
              />
            </EuiFormRow>
            <EuiFormRow display="rowCompressed" isDisabled={filterValue.holdings.length === 0}>
              <EuiSwitch
                compressed={true}
                label="Must include all holdings"
                onChange={onHoldingsMatchAllChange}
                checked={filterValue.holdingsMatchAll}
              />
            </EuiFormRow>
            <EuiPopoverFooter>
              <EuiButton onClick={onApply}>Apply</EuiButton>
            </EuiPopoverFooter>
          </EuiForm>
        </EuiPopover>
      </EuiFilterGroup>
    </>
  );
}
