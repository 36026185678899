/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */
import { useEuiTheme } from '../../services/theme/hooks';
import { EuiThemeBreakpoints } from '../variables';
/**
 * Generates a CSS media query rule string based on the input breakpoint ranges.
 * Examples:
 * euiBreakpoint(['s']) becomes `@media only screen and (min-width: 575px) and (max-width: 767px)`
 * euiBreakpoint(['s', 'l']) becomes `@media only screen and (min-width: 575px) and (max-width: 1199px)`
 *
 * Use the `xs` and `xl` sizes to generate media queries with only min/max-width.
 * Examples:
 * euiBreakpoint(['xs', 'm']) becomes `@media only screen and (max-width: 991px)`
 * euiBreakpoint(['l', 'xl']) becomes `@media only screen and (min-width: 992px)`
 */

export var euiBreakpoint = function euiBreakpoint(_ref, sizes) {
  var euiTheme = _ref.euiTheme;
  // Ensure the array is in the correct ascending size order
  var orderedSizes = sizes.sort(function (a, b) {
    return EuiThemeBreakpoints.indexOf(a) - EuiThemeBreakpoints.indexOf(b);
  });
  var firstBreakpoint = orderedSizes[0];
  var minBreakpointSize = euiTheme.breakpoint[firstBreakpoint];
  var lastBreakpoint = orderedSizes[sizes.length - 1];
  var maxBreakpointSize; // To get the correct screen range, we set the max-width
  // to the next breakpoint size in the sizes array

  if (lastBreakpoint !== 'xl') {
    var nextBreakpoint = EuiThemeBreakpoints.indexOf(lastBreakpoint) + 1;
    maxBreakpointSize = euiTheme.breakpoint[EuiThemeBreakpoints[nextBreakpoint]];
  }

  return ['@media only screen', minBreakpointSize ? "(min-width: ".concat(minBreakpointSize, "px)") : false, // If xs/0, don't render a min-width
  maxBreakpointSize ? "(max-width: ".concat(maxBreakpointSize - 1, "px)") : false // If xl/undefined, don't render a max-width
  ].filter(Boolean).join(' and ');
};
export var useEuiBreakpoint = function useEuiBreakpoint(sizes) {
  var euiTheme = useEuiTheme();
  return euiBreakpoint(euiTheme, sizes);
};