import React, { useContext, useMemo, useState } from 'react';
import {
  EuiBadge,
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiPanel,
  EuiSpacer,
  EuiToolTip,
  useIsWithinBreakpoints,
} from '@elastic/eui';
import {
  formatTer,
  formatAum,
  FundProfile,
  getFundURL,
  Fund,
  isFundProfile,
  isServiceTag,
  formatTag,
  Tag,
  isMarketTag,
  isRegionTag,
  isCountryTag,
} from '../model';
import {
  FundAdminPanel,
  FundNotSupportedDomicileNotice,
  FundProviderNameLabel,
  FundSyntheticNotice,
} from '../components';
import { PageContext } from '../page_container';
import { css } from '@emotion/react';

export interface FundInfoParams {
  fundOrProfile: Fund | FundProfile;
}

export function FundInfoPanel({ fundOrProfile }: FundInfoParams) {
  const { parameters, domicileCode, settings } = useContext(PageContext);
  const isWithinBreakpoint = useIsWithinBreakpoints(['xl', 'l', 'm']);

  const providerName = parameters.providers.get(fundOrProfile.provider)?.name;
  const [id, isSynthetic, assetClass, ter, aum] = useMemo(
    () =>
      isFundProfile(fundOrProfile)
        ? [
            fundOrProfile.id,
            fundOrProfile.basics?.isSynthetic ?? false,
            fundOrProfile.basics?.assetClass,
            fundOrProfile.basics?.ter ?? 0,
            fundOrProfile.basics?.aum ?? 0,
          ]
        : [
            fundOrProfile.isin,
            fundOrProfile.isSynthetic,
            fundOrProfile.assetClass,
            fundOrProfile.ter,
            fundOrProfile.aum,
          ],
    [fundOrProfile],
  );

  const notAvailableInDomicileNotice = useMemo(() => {
    if (fundOrProfile.domiciles.includes(domicileCode)) {
      return null;
    }

    return (
      <>
        <FundNotSupportedDomicileNotice fundDomiciles={fundOrProfile.domiciles} />
        <EuiSpacer />
      </>
    );
  }, [fundOrProfile, domicileCode]);

  const syntheticNotice = useMemo(() => {
    if (!isSynthetic) {
      return null;
    }

    return (
      <>
        <FundSyntheticNotice title="Synthetic ETF" />
        <EuiSpacer />
      </>
    );
  }, [isSynthetic]);

  const [fundTags, setFundTags] = useState(fundOrProfile.tags);
  const fundTagsPanel = useMemo(() => {
    if (!fundTags || fundTags.length === 0) {
      return null;
    }

    const groupedTags: Array<Tag[]> = [[], [], [], []];
    for (const tag of fundTags) {
      if (isMarketTag(tag)) {
        groupedTags[0].push(tag);
      } else if (isRegionTag(tag)) {
        groupedTags[1].push(tag);
      } else if (isCountryTag(tag)) {
        groupedTags[2].push(tag);
      } else if (!isServiceTag(tag)) {
        groupedTags[3].push(tag);
      }
    }

    const sorter = (tagA: Tag, tagB: Tag) => (tagA.value < tagB.value ? -1 : tagA.value > tagB.value ? 1 : 0);
    const sortedTags = groupedTags.flatMap((tagsGroup) => tagsGroup.sort(sorter));
    if (sortedTags.length === 0) {
      return null;
    }

    const getColor = (tag: Tag) =>
      isMarketTag(tag) ? '#d46969' : isRegionTag(tag) || isCountryTag(tag) ? '#6092c0' : 'warning';
    return (
      <>
        <EuiSpacer />
        <EuiFlexGroup gutterSize="s" wrap responsive={false}>
          {sortedTags.map((tag) => (
            <EuiFlexItem grow={false} key={tag.id}>
              <EuiBadge color={getColor(tag)}>{formatTag(tag)}</EuiBadge>
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      </>
    );
  }, [fundTags]);

  const fundAdminPanel = useMemo(() => {
    if (!settings.accessToken) {
      return null;
    }

    return (
      <>
        <FundAdminPanel fundOrProfile={fundOrProfile} updateTags={setFundTags} />
        <EuiSpacer />
      </>
    );
  }, [settings, fundOrProfile]);

  const assetClassName = assetClass ? parameters.fundAssetClasses.get(assetClass)?.name : null;

  return (
    <div>
      <EuiPanel hasShadow={false} color="subdued">
        <EuiFlexGroup>
          <EuiFlexItem
            css={
              isWithinBreakpoint
                ? css`
                    max-width: 65%;
                  `
                : null
            }
          >
            <EuiDescriptionList
              listItems={[
                {
                  title: 'Provider',
                  description: <FundProviderNameLabel fundOrProfile={fundOrProfile} />,
                },
                { title: 'ISIN', description: id },
                { title: 'Asset Class', description: assetClassName ?? assetClass ?? '-' },
              ]}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiDescriptionList
              listItems={[
                {
                  title: (
                    <EuiToolTip
                      content={
                        <span>
                          {providerName} is the <b>only</b> source of the most accurate data, charts and documents for
                          this fund. Never rely on any data provided by the intermediaries when making your investment
                          decisions.
                        </span>
                      }
                    >
                      <span>
                        Full overview{' '}
                        <EuiIcon size="s" color="subdued" type="questionInCircle" className="eui-alignTop" />
                      </span>
                    </EuiToolTip>
                  ),
                  description: (
                    <EuiLink
                      target={'_blank'}
                      href={getFundURL(fundOrProfile)}
                    >{`Fund profile at ${providerName}`}</EuiLink>
                  ),
                },
                {
                  title: (
                    <EuiToolTip content="Total Expense Ratio (TER)">
                      <span>
                        TER <EuiIcon size="s" color="subdued" type="questionInCircle" className="eui-alignTop" />
                      </span>
                    </EuiToolTip>
                  ),
                  description: formatTer(ter),
                },
                {
                  title: (
                    <EuiToolTip content="Assets Under Management (AUM)">
                      <span>
                        AUM <EuiIcon size="s" color="subdued" type="questionInCircle" className="eui-alignTop" />
                      </span>
                    </EuiToolTip>
                  ),
                  description: formatAum(aum),
                },
              ]}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        {fundTagsPanel}
      </EuiPanel>
      <EuiSpacer />
      {notAvailableInDomicileNotice}
      {syntheticNotice}
      {fundAdminPanel}
    </div>
  );
}
