import React, { ChangeEvent, useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  EuiButton,
  EuiButtonEmpty,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiRadio,
  EuiSelect,
} from '@elastic/eui';
import { DEFAULT_DOMICILE_CODE } from '../model';
import { PageContext } from './page_context';

export interface CountrySelectorModalProps {
  onClose: () => void;
}

export function DomicileSelectorModal({ onClose }: CountrySelectorModalProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { settings, setSettings, parameters, domicileCode } = useContext(PageContext);

  const [selectedDomicile, setSelectedDomicile] = useState<string>(domicileCode);
  const onDomicileSelectionChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedDomicile(e.target.value);
  }, []);

  const onDomicileSelectionSave = useCallback(() => {
    setSettings({ ...settings, country: selectedDomicile });

    const pathname =
      domicileCode === DEFAULT_DOMICILE_CODE
        ? `/${selectedDomicile}${location.pathname}`
        : selectedDomicile === DEFAULT_DOMICILE_CODE
          ? location.pathname.slice(domicileCode.length + 1)
          : location.pathname.replace(`/${domicileCode}`, `/${selectedDomicile}`);
    navigate({ pathname, hash: location.hash, search: location.search }, { replace: true });

    onClose();
  }, [selectedDomicile, settings, domicileCode]);

  const domiciles = useMemo(
    () => parameters.domiciles.map((domicile) => ({ text: domicile.displayName, value: domicile.code })),
    [parameters],
  );

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Change domicile</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm id="save-country-selection-form" component="form">
          <EuiFormRow label="Investor type">
            <EuiRadio
              id="investor-type"
              label="Private Investor"
              checked
              disabled
              onChange={() => {
                // noop
              }}
            />
          </EuiFormRow>
          <EuiFormRow label="Country">
            <EuiSelect value={selectedDomicile} options={domiciles} onChange={onDomicileSelectionChange} />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiButton
          type="submit"
          form="save-country-selection-form"
          fill
          onClick={onDomicileSelectionSave}
          isDisabled={selectedDomicile === domicileCode}
        >
          Save
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}
