import React, { useMemo, useState } from 'react';
import { EuiConfirmModal, EuiText, EuiFormRow, EuiFieldNumber, EuiFieldText } from '@elastic/eui';
import { DetailedPortfolioAsset } from './portfolios_assets_table';
import { formatPrice } from '../../model';
import { DetailedPortfolioFund } from './portfolios_funds_table';

export interface PortfolioPositionValues {
  units?: number;
  price?: number;
  name?: string;
}

export interface EditPositionModalProps {
  onCancel: () => void;
  onSave: (value: PortfolioPositionValues) => void;
  position: DetailedPortfolioAsset | DetailedPortfolioFund;
}

export function EditPositionModal({ onCancel, onSave, position }: EditPositionModalProps) {
  const [units, setUnits] = useState<number>(position.units);
  const [price, setPrice] = useState<number | null>(
    typeof position.profile?.financials?.basePrice === 'number' ? null : position.price ?? 0,
  );
  const [name, setName] = useState<string | null>(!!position.profile?.name ? null : position.name ?? '');

  const hasValueChanged = useMemo(() => {
    if (position.units !== units) {
      return true;
    }

    if (name !== null && name !== position.name) {
      return true;
    }

    if (price === null || (price === 0 && !position.price)) {
      return false;
    }

    return price !== position.price;
  }, [position, units, price, name]);

  const nameControl =
    name === null ? (
      <EuiText>{position.profile?.name ?? '-'}</EuiText>
    ) : (
      <EuiFieldText
        fullWidth
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value?.toUpperCase() ?? '')}
      />
    );

  const priceControl =
    price === null ? (
      <EuiText>
        {position.profile?.financials?.basePrice ? `${formatPrice(position.profile.financials.basePrice)}` : '-'}
      </EuiText>
    ) : (
      <EuiFieldNumber
        fullWidth
        min={0}
        placeholder="Price"
        step={0.1}
        value={price}
        onChange={(e) => setPrice(+e.target.value)}
      />
    );

  const totalValue = units * (price ?? position.profile?.financials?.basePrice ?? 0);

  return (
    <EuiConfirmModal
      maxWidth={250}
      title="Edit position"
      onCancel={() => onCancel()}
      onConfirm={() =>
        onSave({
          units: position.units !== units ? units : undefined,
          price: price !== null ? price : undefined,
          name: name !== null ? name : undefined,
        })
      }
      cancelButtonText="Cancel"
      confirmButtonText="Save"
      confirmButtonDisabled={!hasValueChanged}
      buttonColor="primary"
    >
      <EuiFormRow label="Name">{nameControl}</EuiFormRow>
      <EuiFormRow label="Price">{priceControl}</EuiFormRow>
      <EuiFormRow
        label="Units"
        title={'Name for the imported portfolio. If not specified, uses name from the portfolio file.'}
      >
        <EuiFieldNumber
          fullWidth
          min={0}
          placeholder="Units"
          step={1}
          value={units}
          onChange={(e) => setUnits(+e.target.value)}
        />
      </EuiFormRow>
      <EuiFormRow label="Value">
        <EuiText>{formatPrice(totalValue)}</EuiText>
      </EuiFormRow>
    </EuiConfirmModal>
  );
}
