export interface Tag {
  id: number;
  value: string;
}

export interface SerializedTag {
  i: number;
  v: string;
}

export function deserializeTag(serializedTag: SerializedTag): Tag {
  return { id: serializedTag.i, value: serializedTag.v };
}

export function isMarketTag(tag: Tag) {
  return tag.value.startsWith('market:');
}

export function isRegionTag(tag: Tag) {
  return tag.value.startsWith('region:');
}

export function isCountryTag(tag: Tag) {
  return tag.value.startsWith('country:');
}

export function isServiceTag(tag: Tag) {
  return tag.value.startsWith('service:');
}
