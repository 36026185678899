import React, { MouseEventHandler, useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  Criteria,
  EuiCallOut,
  EuiIcon,
  EuiInMemoryTable,
  EuiLink,
  EuiToolTip,
  Pagination,
  PropertySort,
} from '@elastic/eui';
import { AssetDetailsFlyout } from '../../components';
import {
  AlertType,
  AsyncData,
  PAGINATION,
  settingsRemoveAlert,
  matchesSourceId,
  SerializedAssetProfile,
  deserializeAssetProfile,
  AssetProfile,
  getAssetSourceIdTypeLabel,
} from '../../model';
import { PageContext } from '../../page_container';

type AssetAlert = AssetProfile & {
  alert: {
    type: AlertType.AssetIsAddedToFund | AlertType.AssetIsRemovedFromFund;
    timestamp: string;
    assetSourceId: string;
  };
};

export function AssetAlertsTable() {
  const navigate = useNavigate();
  const { settings, setSettings, parameters, getURL } = useContext(PageContext);

  const onNavigateToAssetsPage: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      e.preventDefault();

      if (getURL('/assets') === location.pathname) {
        location.href = e.currentTarget.href;
      } else {
        navigate(e.currentTarget.href.replace(`${location.protocol}//${location.host}`, ''));
      }
    },
    [getURL],
  );

  const [assetAlertToInspect, setAssetAlertToInspect] = useState<AssetAlert | null>(null);
  const toggleAssetDetails = useCallback(
    (asset?: AssetAlert) => {
      setAssetAlertToInspect(asset === assetAlertToInspect ? null : asset ?? null);
    },
    [assetAlertToInspect],
  );

  // Resolve assets as soon as parameters are available.
  const [alerts, setAlerts] = useState<AsyncData<AssetAlert[]>>({ status: 'pending' });
  useEffect(() => {
    if (!parameters.synced) {
      return;
    }

    const assetAlerts: Array<AssetAlert['alert']> = [];
    for (const alert of settings.alerts ?? []) {
      if (alert.type === AlertType.AssetIsAddedToFund || alert.type === AlertType.AssetIsRemovedFromFund) {
        assetAlerts.push({ type: alert.type, assetSourceId: alert.assetSourceId, timestamp: alert.timestamp });
      }
    }

    if (assetAlerts.length === 0) {
      setAlerts({ status: 'succeeded', data: [] });
      return;
    }

    // Don't re-resolve assets if effect is triggered by removal.
    if (alerts.status === 'succeeded' && (assetAlerts.length === alerts.data.length || !assetAlertToInspect)) {
      return;
    }

    setAlerts({ status: 'pending' });

    const uniqueAssetIds = Array.from(new Set(assetAlerts.map(({ assetSourceId }) => assetSourceId)));
    axios
      .post('/api/assets/profile', { domicile: parameters.domicileCode, ids: uniqueAssetIds, includeFunds: true })
      .then(
        ({ data }: { data: { profiles: Array<SerializedAssetProfile | null> } }) => {
          const resolvedAssetProfiles = [];
          for (const serializedAssetProfile of data.profiles) {
            if (serializedAssetProfile) {
              resolvedAssetProfiles.push(deserializeAssetProfile(serializedAssetProfile));
            }
          }

          const mergedData: AssetAlert[] = [];
          for (const alert of assetAlerts) {
            const resolvedAsset = resolvedAssetProfiles.find((asset) => matchesSourceId(asset, alert.assetSourceId));
            if (resolvedAsset) {
              mergedData.push({ ...resolvedAsset, alert });
            }
          }

          setAlerts({ status: 'succeeded', data: mergedData });
        },
        (err) => setAlerts({ status: 'failed', error: err?.message ?? 'Unknown error' }),
      );
  }, [settings, parameters, assetAlertToInspect]);

  const removeAssetAlert = useCallback(
    (assetAlert: AssetAlert) => {
      if (alerts.status !== 'succeeded') {
        return;
      }

      alerts.data.splice(
        alerts.data.findIndex((asset) => asset === assetAlert),
        1,
      );

      setAlerts({ status: 'succeeded', data: [...alerts.data] });
      setSettings(settingsRemoveAlert(settings, assetAlert.alert));
    },
    [alerts, settings],
  );

  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: settings.defaultPageSize,
    pageSizeOptions: PAGINATION.pageSizeOptions,
    totalItemCount: 0,
  });
  const [sorting, setSorting] = useState<{ sort: PropertySort }>({ sort: { field: '', direction: 'asc' } });
  const onTableChange = useCallback(
    ({ page, sort }: Criteria<AssetAlert>) => {
      setPagination({
        ...pagination,
        pageIndex: page?.index ?? 0,
        pageSize: page?.size ?? settings.defaultPageSize,
      });

      if (sort?.field) {
        setSorting({ sort });
      }
    },
    [pagination, settings],
  );

  let noItemsMessage;
  if (alerts.status === 'failed') {
    noItemsMessage = (
      <EuiCallOut title="Failed to retrieve asset alerts" color="danger" iconType="alert">
        <p>It is not possible to retrieve asset alerts at the moment. Try again later.</p>
      </EuiCallOut>
    );
  } else if (alerts.status === 'pending') {
    noItemsMessage = 'Loading...';
  }

  return (
    <>
      <EuiInMemoryTable
        pagination={pagination}
        allowNeutralSort={true}
        sorting={sorting}
        onTableChange={onTableChange}
        items={alerts.status === 'succeeded' ? alerts.data : []}
        itemId={(asset) => `${asset.alert.type}-${asset.alert.assetSourceId}`}
        loading={alerts.status === 'pending'}
        // @ts-expect-error no definition
        noItemsMessage={noItemsMessage}
        columns={[
          {
            name: (
              <EuiToolTip content="A certain condition when the alert should trigger">
                <span>
                  Condition <EuiIcon size="s" color="subdued" type="questionInCircle" className="eui-alignTop" />
                </span>
              </EuiToolTip>
            ),
            field: 'alert',
            width: '160px',
            render: (alert: AssetAlert['alert']) => {
              switch (alert.type) {
                case AlertType.AssetIsAddedToFund:
                  return 'Inclusion in fund';
                case AlertType.AssetIsRemovedFromFund:
                  return 'Exclusion from fund';
                default:
                  return '-';
              }
            },
          },
          {
            name: 'Date created',
            width: '180px',
            field: 'alert',
            render: (alert: AssetAlert['alert']) => moment(alert.timestamp).format('MMM Do YYYY, HH:mm'),
          },
          {
            name: 'Asset ID',
            field: 'asset',
            width: '145px',
            render: (_, { id, sourceId }: AssetAlert) => (
              <EuiLink
                title={getAssetSourceIdTypeLabel(sourceId)}
                href={getURL(`/assets?query=${id.includes(' ') ? id.split(' ')[0] : id}`)}
                onClick={onNavigateToAssetsPage}
                color={'success'}
              >
                {id}
              </EuiLink>
            ),
          },
          {
            name: 'Asset name',
            field: 'name',
            footer: alerts.status === 'succeeded' ? <strong>{alerts.data.length} alerts</strong> : undefined,
            render: (_, { name }: AssetAlert) => name,
            sortable: ({ name }: AssetAlert) => name,
          },
          {
            name: 'Actions',
            field: 'alert',
            width: '75px',
            actions: [
              {
                name: 'View asset details',
                description: 'View asset details',
                icon: 'inspect',
                type: 'icon',
                onClick: toggleAssetDetails,
              },
              {
                name: 'Remove alert',
                description: 'Remove alert',
                icon: 'minusInCircle',
                type: 'icon',
                onClick: removeAssetAlert,
              },
            ],
          },
        ]}
      />
      {assetAlertToInspect ? (
        <AssetDetailsFlyout
          assetSourceId={assetAlertToInspect.sourceId}
          assetName={assetAlertToInspect.name}
          onClose={toggleAssetDetails}
        />
      ) : null}
    </>
  );
}
