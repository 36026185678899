import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import { EuiConfirmModal, EuiFilePicker, EuiText, EuiSpacer, EuiFieldText, EuiFormRow } from '@elastic/eui';
import { Portfolio, PortfolioAsset, PortfolioFund, settingsAddPortfolio } from '../../model';
import { PageContext } from '../../page_container';

export interface ImportPortfolioModalProps {
  onCancel: () => void;
  onImport: (portfolio: Portfolio) => void;
}

interface ImportedPortfolio {
  name?: unknown;
  funds?: Array<Partial<{ isin: unknown; units: unknown; price?: unknown; name?: unknown }>>;
  assets?: Array<Partial<{ id: unknown; units: unknown; price?: unknown; name?: unknown }>>;
}

export function ImportPortfolioModal({ onCancel, onImport }: ImportPortfolioModalProps) {
  const { settings, setSettings, addToast } = useContext(PageContext);

  const [customName, setCustomName] = useState<string>('');
  const onCustomNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCustomName(e.target.value);
  }, []);

  const [fileToImport, setFileToImport] = useState<File | null>(null);
  const onChange = (files: FileList | null) => {
    if (files && files.length > 0) {
      setFileToImport(files[0]);
    } else {
      setFileToImport(null);
    }
  };

  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [isFailedToImport, setIsFailedToImport] = useState<boolean>(false);
  const onConfirm = useCallback(() => {
    if (!fileToImport) {
      return;
    }

    setIsImporting(true);
    setIsFailedToImport(false);

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      setIsImporting(false);

      let rawPortfolio: ImportedPortfolio;
      try {
        rawPortfolio = JSON.parse(e.target?.result?.toString() ?? '{}') as ImportedPortfolio;
      } catch {
        setIsFailedToImport(true);
        return;
      }

      const name = customName
        ? customName
        : typeof rawPortfolio?.name === 'string' && rawPortfolio.name
          ? rawPortfolio.name
          : null;

      if (!name || !Array.isArray(rawPortfolio.funds) || !Array.isArray(rawPortfolio.assets)) {
        setIsFailedToImport(true);
        return;
      }

      const funds: PortfolioFund[] = [];
      for (const fund of rawPortfolio.funds) {
        if (
          typeof fund.isin === 'string' &&
          fund.isin &&
          typeof fund.units === 'number' &&
          (fund.price === undefined || typeof fund.price === 'number') &&
          (fund.name === undefined || typeof fund.name === 'string')
        ) {
          const fundToImport: PortfolioFund = { isin: fund.isin, units: fund.units };
          if (fund.price != null) {
            fundToImport.price = fund.price;
          }

          if (fund.name != null) {
            fundToImport.name = fund.name;
          }

          funds.push(fundToImport);
        } else {
          setIsFailedToImport(true);
          return;
        }
      }

      const assets: PortfolioAsset[] = [];
      for (const asset of rawPortfolio.assets) {
        if (
          typeof asset.id === 'string' &&
          asset.id &&
          typeof asset.units === 'number' &&
          (asset.price === undefined || typeof asset.price === 'number') &&
          (asset.name === undefined || typeof asset.name === 'string')
        ) {
          const assetToImport: PortfolioAsset = { id: asset.id, units: asset.units };
          if (asset.price != null) {
            assetToImport.price = asset.price;
          }

          if (asset.name != null) {
            assetToImport.name = asset.name;
          }

          assets.push(assetToImport);
        } else {
          setIsFailedToImport(true);
          return;
        }
      }

      if (funds.length === 0 && assets.length === 0) {
        setIsFailedToImport(true);
        return;
      }

      const portfolio = { name, funds, assets };
      setSettings(settingsAddPortfolio(settings, portfolio));

      addToast({
        id: `new-portfolio-${Date.now()}`,
        iconType: 'submodule',
        color: 'success',
        title: 'Portfolio was imported',
      });

      onImport(portfolio);
    };
    fileReader.onerror = () => {
      setIsImporting(false);
      setIsFailedToImport(true);
    };
    fileReader.readAsText(fileToImport);
  }, [fileToImport, customName, onImport, settings]);

  const errorPanel = isFailedToImport ? (
    <>
      <EuiSpacer size="s" />
      <EuiText color={'danger'} size="s">
        Incompatible portfolio file format
      </EuiText>
    </>
  ) : null;

  return (
    <EuiConfirmModal
      title="Import portfolio"
      onCancel={onCancel}
      onConfirm={onConfirm}
      isLoading={isImporting}
      cancelButtonText="Cancel"
      confirmButtonText="Import"
      confirmButtonDisabled={!fileToImport}
      buttonColor="primary"
    >
      <EuiFormRow label="Portfolio file">
        <EuiFilePicker
          initialPromptText="Select or drag and drop JSON file with the portfolio"
          accept=".json"
          onChange={onChange}
          isLoading={isImporting}
          isInvalid={isFailedToImport}
          fullWidth={true}
        />
      </EuiFormRow>
      <EuiFormRow
        isDisabled={isImporting}
        label="Portfolio name (optional)"
        title={'Name for the imported portfolio. If not specified, uses name from the portfolio file.'}
      >
        <EuiFieldText
          placeholder="Specify custom name for the portfolio"
          value={customName}
          onChange={onCustomNameChange}
        />
      </EuiFormRow>
      {errorPanel}
    </EuiConfirmModal>
  );
}
