import React, { ChangeEvent, useCallback, useContext } from 'react';
import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiFormRow,
  EuiSelect,
  EuiTitle,
  EuiSwitch,
  EuiSwitchEvent,
} from '@elastic/eui';
import { PAGINATION } from '../model';
import { PageContext } from './page_context';

export interface Props {
  onClose: () => void;
}

export function SettingsFlyout({ onClose }: Props) {
  const { settings, setSettings } = useContext(PageContext);

  const onDefaultPageSizeChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setSettings({ ...settings, defaultPageSize: +e.target.value });
    },
    [settings, setSettings],
  );

  const onThemeChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setSettings({ ...settings, theme: e.target.value as 'light' | 'dark' });
      if (e.target.value === 'light') {
        window.location.reload();
      }
    },
    [settings, setSettings],
  );

  const onConflateHoldingsChange = useCallback(
    (e: EuiSwitchEvent) => {
      setSettings({ ...settings, conflateHoldings: e.target.checked });
    },
    [settings, setSettings],
  );

  return (
    <EuiFlyout size="s" onClose={onClose} ownFocus={true}>
      <EuiFlyoutHeader>
        <EuiTitle size="s">
          <h1>Settings</h1>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiFormRow label={'Theme'}>
          <EuiSelect
            options={[
              { value: 'light', text: 'Light' },
              { value: 'dark', text: 'Dark' },
            ]}
            value={settings.theme ?? 'light'}
            onChange={onThemeChange}
          />
        </EuiFormRow>
        <EuiFormRow label={'Default page size'}>
          <EuiSelect
            options={PAGINATION.pageSizeOptions.map((option) => ({
              value: option.toString(),
              text: option.toString(),
            }))}
            value={settings.defaultPageSize.toString()}
            onChange={onDefaultPageSizeChange}
          />
        </EuiFormRow>
        <EuiFormRow
          helpText={'Treat different share classes and depositary receipts of the same company as the same asset'}
        >
          <EuiSwitch
            label={'Conflate similar assets'}
            checked={settings.conflateHoldings ?? true}
            onChange={onConflateHoldingsChange}
          />
        </EuiFormRow>
      </EuiFlyoutBody>
    </EuiFlyout>
  );
}
