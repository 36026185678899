/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */
import React from 'react';
import { EuiGlobalStyles } from '../../global_styling/reset/global_styles';
import { EuiUtilityClasses } from '../../global_styling/utility/utility';
import { EuiThemeProvider, CurrentEuiBreakpointProvider } from '../../services';
import { EuiThemeAmsterdam } from '../../themes';
import { EuiCacheProvider } from './cache';
import { jsx as ___EmotionJSX } from "@emotion/react";

var isEmotionCacheObject = function isEmotionCacheObject(obj) {
  return obj.hasOwnProperty('key');
};

export var EuiProvider = function EuiProvider(_ref) {
  var cache = _ref.cache,
      _ref$theme = _ref.theme,
      theme = _ref$theme === void 0 ? EuiThemeAmsterdam : _ref$theme,
      _ref$globalStyles = _ref.globalStyles,
      Globals = _ref$globalStyles === void 0 ? EuiGlobalStyles : _ref$globalStyles,
      _ref$utilityClasses = _ref.utilityClasses,
      Utilities = _ref$utilityClasses === void 0 ? EuiUtilityClasses : _ref$utilityClasses,
      colorMode = _ref.colorMode,
      modify = _ref.modify,
      children = _ref.children;
  var defaultCache;
  var globalCache;
  var utilityCache;

  if (cache) {
    if (isEmotionCacheObject(cache)) {
      defaultCache = cache;
    } else {
      defaultCache = cache.default;
      globalCache = cache.global;
      utilityCache = cache.utility;
    }
  }

  return ___EmotionJSX(EuiCacheProvider, {
    cache: defaultCache
  }, ___EmotionJSX(EuiThemeProvider, {
    theme: theme !== null && theme !== void 0 ? theme : undefined,
    colorMode: colorMode,
    modify: modify
  }, theme && ___EmotionJSX(React.Fragment, null, ___EmotionJSX(EuiCacheProvider, {
    cache: globalCache,
    children: Globals && ___EmotionJSX(Globals, null)
  }), ___EmotionJSX(EuiCacheProvider, {
    cache: utilityCache,
    children: Utilities && ___EmotionJSX(Utilities, null)
  })), ___EmotionJSX(CurrentEuiBreakpointProvider, null, children)));
};