import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import 'regenerator-runtime/runtime';
import '@elastic/eui/dist/eui_theme_light.min.css';
import './index.css';

import { appendIconComponentCache } from '@elastic/eui/es/components/icon/icon';
import { icon as EuiIconAlert } from '@elastic/eui/es/components/icon/assets/alert';
import { icon as EuiIconArrowDown } from '@elastic/eui/es/components/icon/assets/arrow_down';
import { icon as EuiIconArrowLeft } from '@elastic/eui/es/components/icon/assets/arrow_left';
import { icon as EuiIconArrowRight } from '@elastic/eui/es/components/icon/assets/arrow_right';
import { icon as EuiIconArrowUp } from '@elastic/eui/es/components/icon/assets/arrow_up';
import { icon as EuiIconArrowStart } from '@elastic/eui/es/components/icon/assets/arrowStart';
import { icon as EuiIconArrowEnd } from '@elastic/eui/es/components/icon/assets/arrowEnd';
import { icon as EuiIconBell } from '@elastic/eui/es/components/icon/assets/bell';
import { icon as EuiIconBeaker } from '@elastic/eui/es/components/icon/assets/beaker';
import { icon as EuiIconBoxesHorizontal } from '@elastic/eui/es/components/icon/assets/boxes_horizontal';
import { icon as EuiIconBoxesVertical } from '@elastic/eui/es/components/icon/assets/boxes_vertical';
import { icon as EuiIconCalendar } from '@elastic/eui/es/components/icon/assets/calendar';
import { icon as EuiIconCheck } from '@elastic/eui/es/components/icon/assets/check';
import { icon as EuiIconCheer } from '@elastic/eui/es/components/icon/assets/cheer';
import { icon as EuiIconClock } from '@elastic/eui/es/components/icon/assets/clock';
import { icon as EuiIconCross } from '@elastic/eui/es/components/icon/assets/cross';
import { icon as EuiIconDocument } from '@elastic/eui/es/components/icon/assets/document';
import { icon as EuiIconDot } from '@elastic/eui/es/components/icon/assets/dot';
import { icon as EuiIconAppNotebook } from '@elastic/eui/es/components/icon/assets/app_notebook';
import { icon as EuiIconAppWatches } from '@elastic/eui/es/components/icon/assets/app_watches';
import { icon as EuiIconEmpty } from '@elastic/eui/es/components/icon/assets/empty';
import { icon as EuiIconExpandMini } from '@elastic/eui/es/components/icon/assets/expandMini';
import { icon as EuiIconExport } from '@elastic/eui/es/components/icon/assets/export';
import { icon as EuiIconEyeClosed } from '@elastic/eui/es/components/icon/assets/eye_closed';
import { icon as EuiIconFlag } from '@elastic/eui/es/components/icon/assets/flag';
import { icon as EuiIconFullScreen } from '@elastic/eui/es/components/icon/assets/full_screen';
import { icon as EuiIconFullScreenExit } from '@elastic/eui/es/components/icon/assets/fullScreenExit';
import { icon as EuiIconGear } from '@elastic/eui/es/components/icon/assets/gear';
import { icon as EuiIconGrab } from '@elastic/eui/es/components/icon/assets/grab';
import { icon as EuiIconGrid } from '@elastic/eui/es/components/icon/assets/grid';
import { icon as EuiIconImport } from '@elastic/eui/es/components/icon/assets/import';
import { icon as EuiIconInputOutput } from '@elastic/eui/es/components/icon/assets/inputOutput';
import { icon as EuiIconInspect } from '@elastic/eui/es/components/icon/assets/inspect';
import { icon as EuiIconLink } from '@elastic/eui/es/components/icon/assets/link';
import { icon as EuiIconList } from '@elastic/eui/es/components/icon/assets/list';
import { icon as EuiIconListAdd } from '@elastic/eui/es/components/icon/assets/list_add';
import { icon as EuiIconHelp } from '@elastic/eui/es/components/icon/assets/help';
import { icon as EuiIconMapMarker } from '@elastic/eui/es/components/icon/assets/map_marker';
import { icon as EuiIconMinusInCircle } from '@elastic/eui/es/components/icon/assets/minus_in_circle';
import { icon as EuiIconNested } from '@elastic/eui/es/components/icon/assets/nested';
import { icon as EuiIconPencil } from '@elastic/eui/es/components/icon/assets/pencil';
import { icon as EuiIconPercent } from '@elastic/eui/es/components/icon/assets/percent';
import { icon as EuiIconPlusInCircle } from '@elastic/eui/es/components/icon/assets/plus_in_circle';
import { icon as EuiIconQuestionInCircle } from '@elastic/eui/es/components/icon/assets/question_in_circle';
import { icon as EuiIconReturnKey } from '@elastic/eui/es/components/icon/assets/return_key';
import { icon as EuiIconSave } from '@elastic/eui/es/components/icon/assets/save';
import { icon as EuiIconSearch } from '@elastic/eui/es/components/icon/assets/search';
import { icon as EuiIconSortable } from '@elastic/eui/es/components/icon/assets/sortable';
import { icon as EuiIconSortUp } from '@elastic/eui/es/components/icon/assets/sort_up';
import { icon as EuiIconSortDown } from '@elastic/eui/es/components/icon/assets/sort_down';
import { icon as EuiIconSortRight } from '@elastic/eui/es/components/icon/assets/sortRight';
import { icon as EuiIconSortLeft } from '@elastic/eui/es/components/icon/assets/sortLeft';
import { icon as EuiIconSubmodule } from '@elastic/eui/es/components/icon/assets/submodule';
import { icon as EuiIconVisTable } from '@elastic/eui/es/components/icon/assets/vis_table';
import { icon as EuiIconTableDensityCompact } from '@elastic/eui/es/components/icon/assets/table_density_compact';
import { icon as EuiIconTableDensityExpanded } from '@elastic/eui/es/components/icon/assets/table_density_expanded';
import { icon as EuiIconTableDensityNormal } from '@elastic/eui/es/components/icon/assets/table_density_normal';
import { icon as EuiIconTokenNumber } from '@elastic/eui/es/components/icon/assets/tokenNumber';
import { icon as EuiIconTokenString } from '@elastic/eui/es/components/icon/assets/tokenString';
import { icon as EuiIconTrash } from '@elastic/eui/es/components/icon/assets/trash';
import { icon as EuiIconPopout } from '@elastic/eui/es/components/icon/assets/popout';

import { AboutUsPage, FundsPage, PortfoliosPage, AssetsPage, AlertsPage } from './pages';
import { PageContainer } from './page_container';
import { DEFAULT_DOMICILE_CODE, DOMICILE_CODES, PROVIDER_TYPES } from './model';

const FundPage = React.lazy(() => import('./pages/fund'));
const ServicePage = React.lazy(() => import('./pages/service'));
const AnalyzePage = React.lazy(() => import('./pages/analyze'));
const PrivacyPolicyPage = React.lazy(() => import('./pages/privacy_policy'));
const TermsPage = React.lazy(() => import('./pages/terms'));

appendIconComponentCache({
  alert: EuiIconAlert,
  arrowDown: EuiIconArrowDown,
  arrowLeft: EuiIconArrowLeft,
  arrowRight: EuiIconArrowRight,
  arrowUp: EuiIconArrowUp,
  arrowStart: EuiIconArrowStart,
  arrowEnd: EuiIconArrowEnd,
  bell: EuiIconBell,
  beaker: EuiIconBeaker,
  boxesHorizontal: EuiIconBoxesHorizontal,
  boxesVertical: EuiIconBoxesVertical,
  calendar: EuiIconCalendar,
  check: EuiIconCheck,
  cheer: EuiIconCheer,
  clock: EuiIconClock,
  cross: EuiIconCross,
  document: EuiIconDocument,
  dot: EuiIconDot,
  empty: EuiIconEmpty,
  expandMini: EuiIconExpandMini,
  exportAction: EuiIconExport,
  eyeClosed: EuiIconEyeClosed,
  flag: EuiIconFlag,
  fullScreen: EuiIconFullScreen,
  fullScreenExit: EuiIconFullScreenExit,
  gear: EuiIconGear,
  grab: EuiIconGrab,
  grid: EuiIconGrid,
  help: EuiIconHelp,
  importAction: EuiIconImport,
  inspect: EuiIconInspect,
  inputOutput: EuiIconInputOutput,
  link: EuiIconLink,
  list: EuiIconList,
  listAdd: EuiIconListAdd,
  mapMarker: EuiIconMapMarker,
  minusInCircle: EuiIconMinusInCircle,
  nested: EuiIconNested,
  notebookApp: EuiIconAppNotebook,
  pencil: EuiIconPencil,
  percent: EuiIconPercent,
  plusInCircle: EuiIconPlusInCircle,
  popout: EuiIconPopout,
  questionInCircle: EuiIconQuestionInCircle,
  returnKey: EuiIconReturnKey,
  save: EuiIconSave,
  search: EuiIconSearch,
  sortable: EuiIconSortable,
  sortUp: EuiIconSortUp,
  sortDown: EuiIconSortDown,
  sortRight: EuiIconSortRight,
  sortLeft: EuiIconSortLeft,
  submodule: EuiIconSubmodule,
  tableDensityCompact: EuiIconTableDensityCompact,
  tableDensityExpanded: EuiIconTableDensityExpanded,
  tableDensityNormal: EuiIconTableDensityNormal,
  tokenNumber: EuiIconTokenNumber,
  tokenString: EuiIconTokenString,
  trash: EuiIconTrash,
  visTable: EuiIconVisTable,
  watchesApp: EuiIconAppWatches,
});

const IndexPage = () => {
  return (
    <BrowserRouter>
      <Routes>
        {DOMICILE_CODES.map((domicileCode) => {
          const path = domicileCode === DEFAULT_DOMICILE_CODE ? '/' : domicileCode;
          const domicile = domicileCode === DEFAULT_DOMICILE_CODE ? undefined : domicileCode;
          return (
            <Route key={path} path={path} element={<PageContainer domicile={domicile} />}>
              <Route index element={<FundsPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
              {PROVIDER_TYPES.map((providerType) => (
                <Route key={providerType} path={providerType} element={<FundsPage providerType={providerType} />} />
              ))}
              <Route path="assets" element={<AssetsPage />} />
              <Route path="alerts" element={<AlertsPage />} />
              <Route
                path="analyze"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <AnalyzePage />
                  </React.Suspense>
                }
              />
              <Route
                path="service"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <ServicePage />
                  </React.Suspense>
                }
              />
              <Route path="portfolios" element={<PortfoliosPage />} />
              <Route path="portfolios/:portfolio" element={<PortfoliosPage />} />
              <Route
                path="fund/:isin"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <FundPage />
                  </React.Suspense>
                }
              />
              <Route
                path="about-us"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <AboutUsPage />
                  </React.Suspense>
                }
              />
              <Route
                path="privacy"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <PrivacyPolicyPage />
                  </React.Suspense>
                }
              />
              <Route
                path="terms"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <TermsPage />
                  </React.Suspense>
                }
              />
            </Route>
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};

createRoot(document.getElementById('root')!).render(<IndexPage />);

// Insert plausible analytics.
const script = document.createElement('script');
script.dataset.domain = location.hostname;
script.defer = true;
script.src = '/js/script.js';
document.head.append(script);
