import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from '@elastic/eui';
import { AsyncData, FundProfile, SerializedFundProfile, deserializeFundProfile } from '../../model';
import { PageErrorState, PageLoadingState } from '../../components';
import { usePageMeta } from '../../hooks';
import { PageContext } from '../../page_container';
import { FundAssetsTable } from './fund_assets_table';
import { FundInfoPanel } from '../../components/fund_info_panel';

export function FundPage() {
  const { parameters } = useContext(PageContext);
  const { isin } = useParams<{ isin: string }>();
  const [profile, setProfile] = useState<AsyncData<FundProfile>>({ status: 'pending' });
  useEffect(() => {
    if (!parameters.synced) {
      return;
    }

    axios.post('/api/funds/profile', { domicile: parameters.domicileCode, ids: [isin], includeBasics: true }).then(
      ({ data }: { data: { profiles: Array<SerializedFundProfile | null> } }) => {
        if (data.profiles.length > 0 && data.profiles[0]) {
          setProfile({ status: 'succeeded', data: deserializeFundProfile(data.profiles[0]) });
        } else {
          setProfile({ status: 'failed', error: 'Fund is not found' });
        }
      },
      (err) => {
        setProfile({ status: 'failed', error: err?.message ?? err });
      },
    );
  }, [isin, parameters]);

  const fundName = profile.status === 'succeeded' ? ` ${profile.data.name.toUpperCase()}` : undefined;
  const providerName =
    profile.status === 'succeeded' ? parameters.providers.get(profile.data.provider)?.name : undefined;
  const pageTitle = profile.status === 'succeeded' ? `${providerName} ${profile.data.name} (${isin})` : undefined;
  usePageMeta(pageTitle ?? isin ?? 'Fund', pageTitle);

  let mainContent;
  if (profile.status === 'pending') {
    mainContent = <PageLoadingState />;
  } else if (profile.status === 'failed') {
    mainContent = <PageErrorState title={'Failed to load fund information'} />;
  } else {
    mainContent = (
      <>
        <EuiTitle size={'s'}>
          <h1>{fundName}</h1>
        </EuiTitle>
        <EuiSpacer />
        <EuiFlexGroup direction={'column'} gutterSize={'s'} style={{ height: '100%' }}>
          <EuiFlexItem grow={false}>
            <FundInfoPanel fundOrProfile={profile.data} />
          </EuiFlexItem>
          <EuiFlexItem>
            <FundAssetsTable fundOrProfile={profile.data} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </>
    );
  }

  return mainContent;
}
