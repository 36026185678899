import React from 'react';
import { EuiConfirmModal, EUI_MODAL_CONFIRM_BUTTON } from '@elastic/eui';

export interface DeletePortfolioModalProps {
  portfolioName: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export function DeletePortfolioModal({ portfolioName, onCancel, onConfirm }: DeletePortfolioModalProps) {
  return (
    <EuiConfirmModal
      title="Delete portfolio"
      onCancel={onCancel}
      onConfirm={onConfirm}
      cancelButtonText="Cancel"
      confirmButtonText="Delete"
      defaultFocusedButton={EUI_MODAL_CONFIRM_BUTTON}
      buttonColor="danger"
    >
      <p>
        Delete <strong>{portfolioName}</strong> portfolio?
      </p>
    </EuiConfirmModal>
  );
}
