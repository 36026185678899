import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, Location } from 'react-router-dom';
import axios from 'axios';
import {
  Criteria,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiInMemoryTable,
  EuiButtonIcon,
  Pagination,
  EuiButton,
  EuiIcon,
  EuiToolTip,
  PropertySort,
  EuiPopover,
} from '@elastic/eui';
import { AddToPortfolioModal, FundDetailsFlyout, FundNameLabel, PortfolioHintsPanel } from '../../components';
import {
  AsyncData,
  deserializeFund,
  formatTer,
  formatAum,
  Fund,
  PAGINATION,
  SerializedFund,
  FundProfile,
  SerializedFundProfile,
  deserializeFundProfile,
} from '../../model';
import { PageContext } from '../../page_container';
import { FundsSplashScreen, SplashScreenMode } from './funds_splash_screen';
import { usePageMeta } from '../../hooks';
import { FundsSearchFilter, FundsSearchFilterValue } from './funds_search_filter';
import { FundsScoreExplanationTable } from './funds_score_explanation_table';

interface Props {
  providerType?: string;
}

interface SavedSearch {
  query: string;
  filter: FundsSearchFilterValue;
}

interface SerializedSavedSearch {
  q?: string;
  f?: { a?: string; hma?: boolean; hmw?: number; h?: Array<[string, string]> };
}

function parseSavedSearch(fundProviderType: string, location: Location): SavedSearch | null {
  const q = new URLSearchParams(location.search).get('q');
  if (!q && fundProviderType === 'all') {
    return null;
  }

  const defaultSavedSearch = {
    query: '',
    filter: { fundProviderType, fundAssetClass: 'all', holdingsMatchAll: false, holdingsMinWeight: 0, holdings: [] },
  };
  if (!q) {
    return defaultSavedSearch;
  }

  try {
    const serializedSavedSearch = JSON.parse(atob(q)) as SerializedSavedSearch;
    return {
      ...defaultSavedSearch,
      query: serializedSavedSearch.q ?? defaultSavedSearch.query,
      filter: serializedSavedSearch.f
        ? {
            fundProviderType,
            fundAssetClass: serializedSavedSearch.f.a ?? defaultSavedSearch.filter.fundAssetClass,
            holdingsMatchAll: serializedSavedSearch.f.hma ?? defaultSavedSearch.filter.holdingsMatchAll,
            holdingsMinWeight: serializedSavedSearch.f.hmw ?? defaultSavedSearch.filter.holdingsMinWeight,
            holdings:
              serializedSavedSearch.f.h?.map(([id, name]) => ({ id, name })) ?? defaultSavedSearch.filter.holdings,
          }
        : defaultSavedSearch.filter,
    };
  } catch {
    return null;
  }
}

export function FundsPage({ providerType }: Props) {
  const { settings, parameters, getURL } = useContext(PageContext);
  const navigate = useNavigate();

  const providerName = providerType && parameters.providers.get(providerType)?.name;
  usePageMeta(
    parameters.synced && providerName ? `${providerName} UCITS ETFs` : 'UCITS ETFs',
    parameters.synced && providerName
      ? `Explore UCITS compliant exchange traded funds (ETFs) from ${providerName} and see what's inside.`
      : undefined,
  );

  const location = useLocation();
  const parsedSavedSearch = useMemo(() => {
    return parseSavedSearch(providerType ?? 'all', location);
  }, [providerType, location]);
  const [searchOnLoad] = useState(!!parsedSavedSearch);

  const [searchState, setSearchState] = useState<AsyncData<Fund[] | null, { previousData: Fund[] }>>(
    searchOnLoad ? { status: 'pending' } : { status: 'succeeded', data: null },
  );

  const [savedSearch, setSavedSearch] = useState<SavedSearch>(
    parsedSavedSearch ?? {
      query: '',
      filter: {
        fundProviderType: 'all',
        fundAssetClass: 'all',
        holdingsMatchAll: false,
        holdingsMinWeight: 0,
        holdings: [],
      },
    },
  );

  const updateCurrentLocation = useCallback(
    (currentSavedSearch: SavedSearch = savedSearch) => {
      const pathname = getURL(
        currentSavedSearch.filter.fundProviderType === 'all' ? '/' : `/${currentSavedSearch.filter.fundProviderType}`,
      );

      const serializedSavedSearch: SerializedSavedSearch = {};
      if (currentSavedSearch.query) {
        serializedSavedSearch.q = currentSavedSearch.query;
      }

      if (currentSavedSearch.filter.fundAssetClass !== 'all') {
        serializedSavedSearch.f = { a: currentSavedSearch.filter.fundAssetClass };
      }

      if (currentSavedSearch.filter.holdingsMatchAll) {
        serializedSavedSearch.f = {
          ...(serializedSavedSearch.f ?? {}),
          hma: currentSavedSearch.filter.holdingsMatchAll,
        };
      }

      if (currentSavedSearch.filter.holdingsMinWeight) {
        serializedSavedSearch.f = {
          ...(serializedSavedSearch.f ?? {}),
          hmw: currentSavedSearch.filter.holdingsMinWeight,
        };
      }

      if (currentSavedSearch.filter.holdings?.length > 0) {
        serializedSavedSearch.f = {
          ...(serializedSavedSearch.f ?? {}),
          h: currentSavedSearch.filter.holdings.map(({ id, name }) => [id, name]),
        };
      }

      const search =
        Object.keys(serializedSavedSearch).length > 0
          ? `?q=${encodeURIComponent(btoa(JSON.stringify(serializedSavedSearch)))}`
          : '';

      if (search !== location.search || pathname !== location.pathname) {
        navigate({ pathname, search });
      }
    },
    [savedSearch, getURL, location],
  );

  const onSearchFilterChange = useCallback(
    (searchFilter: FundsSearchFilterValue) => {
      setSavedSearch({ ...savedSearch, filter: searchFilter });
    },
    [savedSearch],
  );

  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: settings.defaultPageSize,
    pageSizeOptions: PAGINATION.pageSizeOptions,
    totalItemCount: 0,
  });

  const [sorting, setSorting] = useState<{ sort: PropertySort }>({ sort: { field: '', direction: 'asc' } });

  const [explanationPopoverId, setExplanationPopoverId] = useState<string | null>(null);
  const toggleExplanationPopover = useCallback(
    (fund: Fund) => {
      setExplanationPopoverId(fund.isin === explanationPopoverId ? null : fund.isin);
    },
    [explanationPopoverId],
  );
  const onCloseExplanationPopover = useCallback(() => {
    setExplanationPopoverId(null);
  }, []);

  const [fundOrProfileToInspect, setFundOrProfileToInspect] = useState<Fund | FundProfile | null>(null);
  const toggleFundDetails = useCallback(
    (fund?: Fund) => {
      window.location.hash = fund ? `#${fund.isin}` : '';
      setFundOrProfileToInspect(fund === fundOrProfileToInspect ? null : fund ?? null);
    },
    [fundOrProfileToInspect],
  );

  const [fundToAddToPortfolio, setFundToAddToPortfolio] = useState<Fund | null>(null);
  const toggleAddToPortfolioModal = useCallback((fund: Fund | null = null) => {
    setFundToAddToPortfolio(fund);
  }, []);

  const onTableChange = useCallback(
    ({ page, sort }: Criteria<Fund>) => {
      setPagination({
        ...pagination,
        pageIndex: page?.index ?? 0,
        pageSize: page?.size ?? settings.defaultPageSize,
      });

      if (sort) {
        setSorting({ sort });
      }
    },
    [pagination, settings],
  );

  const performSearch = useCallback(
    (currentSavedSearch = savedSearch) => {
      setSearchState({
        status: 'pending',
        state: searchState.status === 'succeeded' && searchState.data ? { previousData: searchState.data } : undefined,
      });

      updateCurrentLocation(currentSavedSearch);

      let params: {
        query?: string;
        assetClass?: string;
        provider?: string;
        domicile: string;
        holdings?: { assets: string[]; shouldMatchAll: boolean; minimumWeight: number };
      } =
        currentSavedSearch.filter.fundProviderType === 'all'
          ? { domicile: parameters.domicileCode }
          : { domicile: parameters.domicileCode, provider: currentSavedSearch.filter.fundProviderType };
      if (currentSavedSearch.query) {
        params = { ...params, query: currentSavedSearch.query };
      }

      if (currentSavedSearch.filter.fundAssetClass !== 'all') {
        params = { ...params, assetClass: currentSavedSearch.filter.fundAssetClass };
      }

      if (currentSavedSearch.filter.holdings.length > 0) {
        params = {
          ...params,
          holdings: {
            assets: currentSavedSearch.filter.holdings.map((holding: unknown) => (holding as { id: string }).id),
            shouldMatchAll: currentSavedSearch.filter.holdingsMatchAll,
            minimumWeight: currentSavedSearch.filter.holdingsMinWeight / 100,
          },
        };
      }

      axios.post('/api/funds/search', params).then(
        ({ data: funds }: { data: SerializedFund[] }) => {
          setSearchState({ status: 'succeeded', data: funds.map(deserializeFund) });
          setPagination({ ...pagination, pageIndex: 0, totalItemCount: funds.length });
        },
        () => {
          setPagination({ ...pagination, pageIndex: 0, totalItemCount: 0 });
          setSearchState({ status: 'succeeded', data: [] });
        },
      );
    },
    [searchState, pagination, parameters, savedSearch, updateCurrentLocation],
  );

  const onSearchFilterApply = useCallback(
    async (searchFilter: FundsSearchFilterValue) => {
      const newSavedSearch = { ...savedSearch, filter: searchFilter };
      setSavedSearch(newSavedSearch);
      await performSearch(newSavedSearch);
    },
    [savedSearch, performSearch],
  );

  const onSearchQueryChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSavedSearch({ ...savedSearch, query: e.target.value });
    },
    [savedSearch],
  );

  const onSearchSubmit = useCallback(
    (query: string) => {
      if (!query) {
        setSearchState({ status: 'succeeded', data: null });
        updateCurrentLocation({ ...savedSearch, query: '' });
      } else {
        performSearch({ ...savedSearch, query });
      }
    },
    [performSearch, savedSearch],
  );
  const onSearchButtonClick = useCallback(() => {
    performSearch();
  }, [performSearch]);

  useEffect(() => {
    if (parameters.synced && searchOnLoad) {
      performSearch();
    }
  }, [parameters, searchOnLoad]);

  const [autoShowFundDetails, setAutoShowFundDetails] = useState<boolean>(location.hash.length === 13);
  useEffect(() => {
    if (!autoShowFundDetails || !parameters.synced) {
      return;
    }

    setAutoShowFundDetails(false);

    axios
      .post('/api/funds/profile', {
        domicile: parameters.domicileCode,
        ids: [location.hash.slice(1)],
        includeBasics: true,
      })
      .then(
        ({ data }: { data: { profiles: Array<SerializedFundProfile | null> } }) => {
          if (data.profiles.length > 0 && data.profiles[0]) {
            setFundOrProfileToInspect(deserializeFundProfile(data.profiles[0]));
          }
        },
        () => {
          // Just ignore error.
        },
      );
  }, [autoShowFundDetails, location, settings, parameters]);

  let mainContent;
  if (searchState.status === 'pending' && (!searchState.state || searchState.state.previousData.length === 0)) {
    mainContent = <FundsSplashScreen mode={SplashScreenMode.Loading} />;
  } else if (searchState.status === 'succeeded' && !searchState.data) {
    mainContent = <FundsSplashScreen mode={SplashScreenMode.Default} />;
  } else if (searchState.status === 'succeeded' && searchState.data?.length === 0) {
    mainContent = <FundsSplashScreen mode={SplashScreenMode.NoData} />;
  } else {
    const isLoading = searchState.status === 'pending';
    const items =
      (searchState.status === 'succeeded'
        ? searchState.data
        : searchState.status === 'pending'
          ? searchState.state?.previousData
          : []) ?? [];
    const filteredWithHoldings = !!items[0]?.meta?._score;
    mainContent = (
      <EuiInMemoryTable
        pagination={pagination}
        allowNeutralSort={true}
        sorting={sorting}
        onTableChange={onTableChange}
        loading={isLoading}
        items={items}
        itemId="isin"
        hasActions
        columns={[
          {
            name: 'ISIN',
            field: 'isin',
            width: '145px',
          },
          {
            name: 'Asset Class',
            field: 'assetClass',
            width: '105px',
            render: (assetClass, fund) => parameters.fundAssetClasses.get(fund.assetClass)?.name ?? '-',
          },
          {
            name: (
              <EuiToolTip content="Total Expense Ratio (TER)">
                <span>
                  TER <EuiIcon size="s" color="subdued" type="questionInCircle" className="eui-alignTop" />
                </span>
              </EuiToolTip>
            ),
            field: 'ter',
            width: '75px',
            render: (ter, fund: Fund) => formatTer(fund.ter),
            sortable: ({ ter }) => ter,
          },
          {
            name: (
              <EuiToolTip content="Assets Under Management (AUM)">
                <span>
                  AUM <EuiIcon size="s" color="subdued" type="questionInCircle" className="eui-alignTop" />
                </span>
              </EuiToolTip>
            ),
            field: 'aum',
            width: '95px',
            render: (aum, fund: Fund) => formatAum(fund.aum),
            sortable: ({ aum }) => aum,
          },
          {
            name: 'Name',
            field: 'name',
            textOnly: true,
            render: (name, fund) => <FundNameLabel fundOrProfile={fund} displayHints displayProvider />,
            footer: <strong>{items.length} funds</strong>,
          },
          ...(filteredWithHoldings
            ? [
                {
                  width: '40px',
                  render: (fund: Fund) => (
                    <EuiPopover
                      button={
                        <EuiButtonIcon
                          aria-label="View filtered holdings weight"
                          title="View filtered holdings weight"
                          iconType="percent"
                          color="warning"
                          display="fill"
                          isDisabled={isLoading}
                          onClick={() => toggleExplanationPopover(fund)}
                        />
                      }
                      isOpen={explanationPopoverId === fund.isin}
                      closePopover={onCloseExplanationPopover}
                      anchorPosition={'downCenter'}
                    >
                      <FundsScoreExplanationTable fund={fund} />
                    </EuiPopover>
                  ),
                },
              ]
            : []),
          {
            name: 'Actions',
            field: 'infoLink',
            width: '75px',
            actions: [
              {
                name: 'View fund details',
                description: 'View fund details',
                icon: 'inspect',
                type: 'icon',
                onClick: toggleFundDetails,
              },
              {
                name: 'Add to portfolio',
                description: 'Add to portfolio',
                icon: 'plusInCircle',
                type: 'icon',
                enabled: () => !isLoading,
                onClick: (fund) => toggleAddToPortfolioModal(fund),
              },
            ],

            render: (infoLink, fund) => {
              const explanation = filteredWithHoldings ? (
                <EuiPopover
                  button={
                    <EuiButtonIcon
                      aria-label="View filtered holdings weight"
                      title="View filtered holdings weight"
                      iconType="percent"
                      color="warning"
                      display="fill"
                      isDisabled={isLoading}
                      onClick={() => toggleExplanationPopover(fund)}
                    />
                  }
                  isOpen={explanationPopoverId === fund.isin}
                  closePopover={onCloseExplanationPopover}
                  anchorPosition={'downCenter'}
                >
                  <FundsScoreExplanationTable fund={fund} />
                </EuiPopover>
              ) : null;

              return (
                <EuiFlexGroup justifyContent={'spaceEvenly'} gutterSize="none">
                  {explanation}
                  <EuiButtonIcon
                    aria-label="Go to fund provider website"
                    title="Go to fund provider website"
                    iconType="link"
                    href={fund.infoLink}
                    isDisabled={isLoading}
                    target="_blank"
                  />
                  <EuiButtonIcon
                    aria-label="View fund details"
                    title="View fund details"
                    iconType="inspect"
                    isDisabled={isLoading}
                    onClick={() => toggleFundDetails(fund)}
                  />
                  <EuiButtonIcon
                    aria-label="Add to portfolio"
                    title="Add to portfolio"
                    iconType="plusInCircle"
                    isDisabled={isLoading}
                    onClick={() => toggleAddToPortfolioModal(fund)}
                  />
                </EuiFlexGroup>
              );
            },
          },
        ]}
      />
    );
  }

  const searchBar = (
    <EuiFlexItem>
      <EuiFieldSearch
        fullWidth
        placeholder="Search fund by name, ISIN or ticker"
        value={savedSearch.query}
        isClearable={true}
        isLoading={searchState.status === 'pending'}
        onChange={onSearchQueryChange}
        onSearch={onSearchSubmit}
      />
    </EuiFlexItem>
  );

  const fundDetailsFlyout = fundOrProfileToInspect ? (
    <FundDetailsFlyout fundOrProfile={fundOrProfileToInspect} onClose={toggleFundDetails} />
  ) : null;

  const addFundToPortfolioModal = fundToAddToPortfolio ? (
    <AddToPortfolioModal item={fundToAddToPortfolio} onClose={() => toggleAddToPortfolioModal(null)} />
  ) : null;

  const items =
    searchState.status === 'succeeded'
      ? searchState.data
      : searchState.status === 'pending'
        ? searchState.state?.previousData
        : undefined;

  const portfolioHintsPanel =
    !!items && items.length > 0 ? (
      <EuiFlexItem grow={false}>
        <PortfolioHintsPanel />
      </EuiFlexItem>
    ) : null;

  return (
    <>
      <EuiFlexGroup direction={'column'} gutterSize={'s'} style={{ height: '100%' }}>
        <EuiFlexItem grow={false}>
          <EuiForm>
            <EuiFormRow fullWidth>
              <EuiFlexGroup gutterSize={'s'}>
                {searchBar}
                <EuiFlexItem grow={false}>
                  <FundsSearchFilter
                    value={savedSearch.filter}
                    onChange={onSearchFilterChange}
                    onApply={onSearchFilterApply}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton iconType="search" onClick={onSearchButtonClick}>
                    Search
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFormRow>
          </EuiForm>
        </EuiFlexItem>
        {portfolioHintsPanel}
        <EuiFlexItem>{mainContent}</EuiFlexItem>
      </EuiFlexGroup>
      {fundDetailsFlyout}
      {addFundToPortfolioModal}
    </>
  );
}
