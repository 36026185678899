import { deserializeTag, SerializedTag, Tag } from './tag';

export interface Fund {
  provider: string;
  domiciles: string[];
  tags?: Tag[];
  name: string;
  isin: string;
  assetClass: string;
  infoLink: string;
  ter: number;
  isSynthetic: boolean;
  aum: number;
  meta: Record<string, string>;
}

export interface SerializedFund {
  p: string;
  dm: string[];
  tg?: SerializedTag[];
  n: string;
  i: string;
  a: string;
  l: string;
  r: string;
  e: number;
  u: number;
  m: Record<string, string>;
}

export function deserializeFund(serializedFund: SerializedFund): Fund {
  const fund: Fund = {
    provider: serializedFund.p,
    domiciles: serializedFund.dm,
    isin: serializedFund.i,
    name: serializedFund.n,
    assetClass: serializedFund.a,
    infoLink: serializedFund.l,
    ter: serializedFund.e,
    isSynthetic: serializedFund.r === 's',
    aum: serializedFund.u,
    meta: serializedFund.m,
  };

  if (serializedFund.tg) {
    fund.tags = serializedFund.tg.map((serializedTag) => deserializeTag(serializedTag));
  }

  return fund;
}
