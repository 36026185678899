import React, { MouseEventHandler, useCallback, useContext, useMemo, useState } from 'react';
import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import { Fund, FundAssets, FundProfile, isFundProfile } from '../model';
import { PageContext } from '../page_container';
import { useNavigate } from 'react-router-dom';
import { FundAssetsTable } from '../pages/fund';
import { FundInfoPanel } from './fund_info_panel';

export function FundDetailsFlyout({
  fundOrProfile,
  onClose,
}: {
  fundOrProfile: Fund | FundProfile;
  onClose: () => void;
}) {
  const navigate = useNavigate();

  const [fundAssets, setFundAssets] = useState<FundAssets | null>(null);
  const { getURL } = useContext(PageContext);
  const fundId = isFundProfile(fundOrProfile) ? fundOrProfile.id : fundOrProfile.isin;

  const onNavigateToFundPage: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      e.preventDefault();
      navigate(getURL(`/fund/${fundId}`));
      onClose();
    },
    [getURL, fundOrProfile],
  );

  const asOfDate = useMemo(() => {
    if (!fundAssets || !fundAssets.asOfDate) {
      return ' ';
    }

    return `As of ${new Date(fundAssets.asOfDate).toLocaleDateString()}`;
  }, [fundAssets]);

  const actionsPanel = useMemo(() => {
    return (
      <>
        <EuiPanel paddingSize="s" color="subdued">
          <EuiButtonEmpty
            aria-label={'View as page'}
            iconType="document"
            color="primary"
            onClick={onNavigateToFundPage}
            title={'View as page'}
            href={getURL(`/fund/${fundId}`)}
          >
            View as page
          </EuiButtonEmpty>
        </EuiPanel>
        <EuiSpacer />
      </>
    );
  }, [getURL, fundOrProfile]);

  return (
    <EuiFlyout size="m" onClose={() => onClose()} ownFocus={true}>
      <EuiFlyoutHeader>
        <EuiTitle size="s" textTransform="uppercase">
          <EuiFlexGroup alignItems={'center'} gutterSize="s">
            <EuiFlexItem>
              <h1>{fundOrProfile.name}</h1>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText size={'xs'} color={'subdued'}>
                {asOfDate}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <FundInfoPanel fundOrProfile={fundOrProfile} />
        {actionsPanel}
        <FundAssetsTable fundOrProfile={fundOrProfile} onAssetsLoaded={setFundAssets} />
      </EuiFlyoutBody>
    </EuiFlyout>
  );
}
