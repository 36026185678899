import { settingsGetBaseCurrency } from './settings';
import { Tag } from './tag';

export function formatTer(ter: number) {
  if (ter === 0 || Number.isNaN(ter)) {
    return '-';
  }

  return `${(ter / 100).toFixed(2)}%`;
}

export function formatAum(aum: number) {
  if (aum === 0) {
    return '-';
  }

  if (aum > 1_000_000_000_000) {
    return `${settingsGetBaseCurrency()}${(aum / 1_000_000_000_000).toFixed(2)} T`;
  }

  if (aum > 1_000_000_000) {
    return `${settingsGetBaseCurrency()}${(aum / 1_000_000_000).toFixed(2)} B`;
  }

  return `${settingsGetBaseCurrency()}${(aum / 1_000_000).toFixed(2)} M`;
}

export function formatPrice(price: number) {
  if (price === 0) {
    return '-';
  }

  return Number(price.toFixed(2)).toLocaleString(undefined, {
    currency: 'EUR',
    style: 'currency',
    useGrouping: true,
  });
}

export function formatWeight(weight: number | null | undefined) {
  return `${(Math.round((weight ?? 0) * 1000000) / 10000).toFixed(4)}%`;
}

export function formatTag(tag: Tag) {
  const separatorIndex = tag.value.lastIndexOf(':');
  return separatorIndex >= 0 ? tag.value.slice(separatorIndex + 1) : tag.value;
}
