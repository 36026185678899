import { Domicile } from './domicile';
import { DomicileNotice } from './domicile_notice';
import { ServerStatus } from './server_status';

export const PROVIDER_TYPES: string[] = [
  'amundi',
  'first_trust',
  'global_x',
  'hsbc',
  'invesco',
  'ishares',
  'legal_and_general',
  'lyxor',
  'rize',
  'spdr',
  'ubs',
  'vaneck',
  'vanguard',
  'wisdom_tree',
  'xtrackers',
];

/**
 * Licence-based properties.
 */
export interface License {
  /**
   * A maximum number of portfolio funds to analyze.
   */
  maxFundsToAnalyze: number;

  /**
   * A maximum number of portfolio assets to analyze.
   */
  maxAssetsToAnalyze: number;

  /**
   * A maximum number of alerts.
   */
  maxAlerts: number;
}

export interface SerializedParameters {
  status: ServerStatus;
  domicileCode: string;
  domiciles: Domicile[];
  notices: DomicileNotice[];
  license: License;
  providers: Parameter[];
  fundAssetClasses: Parameter[];
  assetClasses: Parameter[];
}

export interface Parameters {
  synced: boolean;
  status: ServerStatus;
  domicileCode: string;
  domiciles: Domicile[];
  providers: Map<string, Parameter>;
  fundAssetClasses: Map<string, Parameter>;
  assetClasses: Map<string, Parameter>;
  notices: DomicileNotice[];
  license: License;
}

export interface Parameter {
  type: string;
  name: string;
}
