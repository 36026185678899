import { Fund } from './fund';
import { parseAssetSourceId } from './asset_id';

export interface FundAsset {
  id: string;
  sourceId: string;
  name: string;
  assetClass: string;
  weight: number;
}

export interface FundAssetExtended extends FundAsset {
  fund: Fund;
}

export interface SerializedFundAsset {
  i: string;
  n: string;
  a: string;
  w: number;
}

export function deserializeFundAsset(serializedAsset: SerializedFundAsset): FundAsset {
  return {
    id: parseAssetSourceId(serializedAsset.i),
    sourceId: serializedAsset.i,
    name: serializedAsset.n,
    assetClass: serializedAsset.a,
    weight: serializedAsset.w,
  };
}
