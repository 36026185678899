import { Portfolio, Settings } from '../settings';

export interface SettingsV2 extends Omit<Settings, 'version' | 'portfolios'> {
  version: 2;
  portfolios: { draft: Omit<Portfolio, 'name'>; saved: Portfolio[] };
}

export function upgradeSettingsV2ToSettingsV3(settings: SettingsV2): Settings {
  const draftPortfolios =
    settings.portfolios.draft &&
    (settings.portfolios.draft.assets.length > 0 || settings.portfolios.draft.funds.length > 0)
      ? [
          {
            name: 'Draft (auto-saved)',
            funds: settings.portfolios.draft.funds,
            assets: settings.portfolios.draft.assets,
          },
        ]
      : [];
  return {
    ...settings,
    version: 3,
    portfolios: [
      ...draftPortfolios,
      ...settings.portfolios.saved.filter((portfolio) => portfolio.assets.length > 0 || portfolio.funds.length > 0),
    ],
  };
}
