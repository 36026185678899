import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import axios from 'axios';
import {
  EuiMark,
  EuiInMemoryTable,
  Pagination,
  Criteria,
  EuiCallOut,
  EuiIcon,
  EuiToolTip,
  PropertySort,
  EuiLoadingContent,
  EuiText,
  useCurrentEuiBreakpoint,
  EuiBasicTableColumn,
} from '@elastic/eui';
import { AssetDetailsFlyout } from '../../components';
import {
  AsyncData,
  PAGINATION,
  PortfolioAsset,
  Portfolio,
  settingsRemovePortfolio,
  parseAssetSourceId,
  SerializedAssetProfile,
  AssetProfile,
  deserializeAssetProfile,
  settingsGetBaseCurrency,
  getAssetSourceIdTypeLabel,
  formatPrice,
  isAbortError,
} from '../../model';
import { PageContext } from '../../page_container';
import { EditPositionModal, PortfolioPositionValues } from './portfolios_edit_position_modal';
import { PortfolioPositionPrice } from './portfolios_position_price';

export interface PortfoliosAssetsTable {
  portfolio: Portfolio;
  onSelectionChange?: (selectedAssets: DetailedPortfolioAsset[]) => void;
  onValueChange?: (value: number) => void;
}

export interface DetailedPortfolioAsset extends PortfolioAsset {
  profile?: AssetProfile;
}

export function PortfoliosAssetsTable({ portfolio, onSelectionChange, onValueChange }: PortfoliosAssetsTable) {
  const { settings, setSettings, parameters } = useContext(PageContext);
  const currentBreakpoint = useCurrentEuiBreakpoint();

  // Clear selection if portfolio changes.
  const [selectedAssets, setSelectedAssets] = useState<DetailedPortfolioAsset[]>([]);
  const tableRef = useRef<EuiInMemoryTable<DetailedPortfolioAsset>>();

  // Resolve assets as soon as parameters are available.
  const [loadingExtendedProfile, setLoadingExtendedProfile] = useState({
    loading: true,
    portfolioName: portfolio.name,
  });
  const [assets, setAssets] = useState<AsyncData<DetailedPortfolioAsset[], string> | null>(null);
  useEffect(() => {
    if (!parameters.synced) {
      return;
    }

    tableRef?.current?.setSelection([]);

    // If there is no assets to resolve, bail out.
    if (portfolio.assets.length === 0) {
      setAssets({ status: 'succeeded', data: [] });
      return;
    }

    setAssets({ status: 'pending', state: portfolio.name });
    setLoadingExtendedProfile({ loading: true, portfolioName: portfolio.name });

    const controller = new AbortController();
    const assetIds = portfolio.assets.map(({ id }) => id);
    axios
      .post('/api/assets/profile', { domicile: parameters.domicileCode, ids: assetIds }, { signal: controller.signal })
      .then(
        ({ data }: { data: { profiles: Array<SerializedAssetProfile | null> } }) => {
          setAssets((prevState) => {
            if (prevState?.state !== portfolio.name) {
              return prevState;
            }
            return {
              status: 'succeeded',
              state: prevState?.state,
              data: portfolio.assets.map((portfolioAsset, assetIndex) => {
                const deserializedProfile = data.profiles[+assetIndex]
                  ? deserializeAssetProfile(data.profiles[+assetIndex]!)
                  : undefined;

                // If we couldn't load profile previously, just use this profile as is.
                if (prevState?.status !== 'succeeded') {
                  return { ...portfolioAsset, profile: deserializedProfile };
                }

                const previousAsset = prevState.data[+assetIndex];
                if (!deserializedProfile) {
                  return previousAsset;
                }

                return {
                  ...previousAsset,
                  profile: deserializedProfile ? { ...previousAsset.profile, ...deserializedProfile } : undefined,
                };
              }),
            };
          });
        },
        (err) => {
          setAssets((prevState) => {
            if (isAbortError(err) || prevState?.state !== portfolio.name) {
              return prevState;
            }

            return { status: 'failed', error: err?.message ?? 'Unknown error', state: prevState.state };
          });
        },
      );

    axios
      .post(
        '/api/assets/profile',
        {
          domicile: parameters.domicileCode,
          ids: assetIds,
          includeBasics: true,
          includeFinancials: true,
        },
        { signal: controller.signal },
      )
      .then(
        ({ data }: { data: { profiles: Array<SerializedAssetProfile | null> } }) => {
          setLoadingExtendedProfile((prevState) => {
            if (prevState.portfolioName !== portfolio.name) {
              return prevState;
            }

            return { ...prevState, loading: false };
          });

          setAssets((prevState) => {
            if (prevState?.state !== portfolio.name) {
              return prevState;
            }

            return {
              status: 'succeeded',
              state: prevState?.state,
              data: portfolio.assets.map((portfolioAsset, assetIndex) => {
                const deserializedProfile = data.profiles[+assetIndex]
                  ? deserializeAssetProfile(data.profiles[+assetIndex]!)
                  : undefined;

                // If we couldn't load profile previously, just use this profile as is.
                if (prevState?.status !== 'succeeded') {
                  return { ...portfolioAsset, profile: deserializedProfile };
                }

                const previousAsset = prevState.data[+assetIndex];
                if (!deserializedProfile) {
                  return previousAsset;
                }

                return {
                  ...previousAsset,
                  profile: deserializedProfile ? { ...previousAsset.profile, ...deserializedProfile } : undefined,
                };
              }),
            };
          });
        },
        (err) => {
          setLoadingExtendedProfile((prevState) => {
            if (isAbortError(err) || prevState.portfolioName !== portfolio.name) {
              return prevState;
            }

            return { ...prevState, loading: false };
          });
        },
      );

    return () => {
      controller.abort();
    };
  }, [portfolio, parameters, tableRef]);

  const removeAssetFromPortfolio = useCallback(
    (assetsToRemove: DetailedPortfolioAsset[]) => {
      if (assets?.status !== 'succeeded') {
        return;
      }

      let selectionUpdated = false;
      for (const assetToRemove of assetsToRemove) {
        portfolio.assets.splice(
          portfolio.assets.findIndex((asset) => asset.id === assetToRemove.id),
          1,
        );
        assets.data.splice(
          assets.data.findIndex((asset) => asset.id === assetToRemove.id),
          1,
        );

        const indexInCurrentSelection = selectedAssets.findIndex((asset) => asset.id === assetToRemove.id);
        if (indexInCurrentSelection >= 0) {
          selectionUpdated = true;
          selectedAssets.splice(indexInCurrentSelection, 1);
        }
      }

      if (selectionUpdated) {
        const newSelectedAssets = [...selectedAssets];
        setSelectedAssets(newSelectedAssets);
        onSelectionChange?.(newSelectedAssets);
      }

      if (portfolio.funds.length === 0 && portfolio.assets.length === 0) {
        setSettings(settingsRemovePortfolio(settings, portfolio.name));
      } else {
        setSettings({ ...settings });
      }
    },
    [assets, selectedAssets, settings, portfolio],
  );

  const onAssetSelectionChange = useCallback((selectedAssets: DetailedPortfolioAsset[]) => {
    setSelectedAssets(selectedAssets);
    onSelectionChange?.(selectedAssets);
  }, []);

  const [assetToInspect, setAssetToInspect] = useState<DetailedPortfolioAsset | null>(null);
  const toggleAssetDetails = useCallback(
    (asset?: DetailedPortfolioAsset) => {
      setAssetToInspect(asset === assetToInspect ? null : asset ?? null);
    },
    [assetToInspect],
  );

  const [assetToEdit, setAssetToEdit] = useState<DetailedPortfolioAsset | null>(null);
  const toggleAssetEditModal = (asset?: DetailedPortfolioAsset) => {
    setAssetToEdit((currentAsset) => (asset === currentAsset ? null : asset ?? null));
  };
  const saveAssetValues = (values: PortfolioPositionValues) => {
    toggleAssetEditModal();

    if (!assetToEdit) {
      return;
    }

    const portfolioAssetToUpdate = portfolio.assets.find((portfolioAsset) => portfolioAsset.id === assetToEdit.id);
    if (!portfolioAssetToUpdate) {
      return;
    }

    if (values.units !== undefined) {
      portfolioAssetToUpdate.units = assetToEdit.units = values.units;
    }

    if (values.price !== undefined) {
      if (values.price > 0) {
        portfolioAssetToUpdate.price = assetToEdit.price = values.price;
      } else {
        delete assetToEdit.price;
        delete portfolioAssetToUpdate.price;
      }
    }

    if (values.name !== undefined) {
      if (values.name) {
        portfolioAssetToUpdate.name = assetToEdit.name = values.name;
      } else {
        delete assetToEdit.name;
        delete portfolioAssetToUpdate.name;
      }
    }

    setSettings({ ...settings });
  };

  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: settings.defaultPageSize,
    pageSizeOptions: PAGINATION.pageSizeOptions,
    totalItemCount: 0,
  });
  const [sorting, setSorting] = useState<{ sort: PropertySort }>({ sort: { field: '', direction: 'asc' } });
  const onTableChange = useCallback(
    ({ page, sort }: Criteria<DetailedPortfolioAsset>) => {
      setPagination({
        ...pagination,
        pageIndex: page?.index ?? 0,
        pageSize: page?.size ?? settings.defaultPageSize,
      });

      if (sort) {
        setSorting({ sort });
      }
    },
    [pagination, settings],
  );

  let noItemsMessage;
  if (assets?.status === 'failed') {
    noItemsMessage = (
      <EuiCallOut title="Failed to retrieve portfolio assets" color="danger" iconType="alert">
        <p>
          It is not possible to retrieve portfolio&nbsp;
          <EuiMark>{portfolio.name}</EuiMark> assets at the moment. Try again later.
        </p>
      </EuiCallOut>
    );
  } else if (!assets || assets?.status === 'pending') {
    noItemsMessage = 'Loading...';
  }

  const loadingContent = (
    <EuiLoadingContent
      css={css`
        width: 100%;
      `}
      lines={1}
    />
  );

  const { totalValue, totalValueLabel } = useMemo(() => {
    if (!assets || assets.status !== 'succeeded' || loadingExtendedProfile.loading) {
      return { totalValue: undefined, totalValueLabel: undefined };
    }

    const totalValue = assets.data.reduce((agg, asset) => {
      const { basePrice } = asset.profile?.financials ?? {};
      return agg + asset.units * (basePrice ?? asset.price ?? 0);
    }, 0);

    return {
      totalValue,
      totalValueLabel: totalValue > 0 ? <strong>{formatPrice(totalValue)}</strong> : undefined,
    };
  }, [assets, onValueChange, loadingExtendedProfile, settings]);

  useEffect(() => {
    if (totalValue !== undefined) {
      onValueChange?.(totalValue);
    }
  }, [totalValue]);

  return (
    <>
      <EuiInMemoryTable
        // @ts-expect-error no definition
        ref={tableRef}
        pagination={pagination}
        allowNeutralSort={true}
        sorting={sorting}
        hasActions={true}
        onTableChange={onTableChange}
        items={assets?.status === 'succeeded' ? assets.data : []}
        itemId={(asset) => asset.profile?.sourceId ?? asset.id}
        loading={!assets || assets?.status === 'pending'}
        selection={{ onSelectionChange: onAssetSelectionChange }}
        noItemsMessage={noItemsMessage}
        tableLayout={'auto'}
        columns={[
          ...(currentBreakpoint === 'm'
            ? []
            : [
                {
                  name: 'ID',
                  field: 'asset',
                  width: '145px',
                  render: (_, asset) => (
                    <EuiText
                      title={
                        asset.profile || asset.id
                          ? getAssetSourceIdTypeLabel(asset.profile?.sourceId ?? asset.id)
                          : undefined
                      }
                      size="s"
                    >
                      {asset.profile?.id ?? parseAssetSourceId(asset.id)}
                    </EuiText>
                  ),
                } as EuiBasicTableColumn<DetailedPortfolioAsset>,
              ]),
          {
            name: 'Name',
            field: 'profile',
            textOnly: true,
            footer: assets?.status === 'succeeded' ? <strong>{assets.data.length} assets</strong> : undefined,
            render: (profile: AssetProfile | undefined, asset) => profile?.name ?? asset.name ?? '-',
            sortable: ({ profile }) => profile?.name ?? '-',
          },
          ...(currentBreakpoint === 'm'
            ? []
            : ([
                {
                  name: 'Sector',
                  field: 'sector',
                  render: (_, asset) =>
                    loadingExtendedProfile.loading ? (
                      loadingContent
                    ) : asset.profile?.basics?.sector ? (
                      <EuiText size="s" className="eui-textNoWrap">
                        {asset.profile?.basics?.sector}
                      </EuiText>
                    ) : (
                      '-'
                    ),
                  sortable: ({ profile }) => profile?.basics?.sector ?? 0,
                },
                {
                  name: 'Country',
                  field: 'country',
                  render: (_, asset) =>
                    loadingExtendedProfile.loading ? (
                      loadingContent
                    ) : asset.profile?.basics?.country ? (
                      <EuiText size="s" className="eui-textNoWrap">
                        {asset.profile?.basics?.country}
                      </EuiText>
                    ) : (
                      '-'
                    ),
                  sortable: ({ profile }) => profile?.basics?.country ?? 0,
                },
              ] as Array<EuiBasicTableColumn<DetailedPortfolioAsset>>)),
          {
            name: 'Units',
            description: 'Defines how much of the asset you have in your portfolio.',
            field: 'units',
            textOnly: true,
            render: (units: number) => (
              <EuiText size="s" className="eui-textNoWrap">
                {units}
              </EuiText>
            ),
            datatype: 'number',
          },
          {
            name: (
              <EuiToolTip content={`Price, converted to ${settingsGetBaseCurrency()}`}>
                <span>
                  Price <EuiIcon size="s" color="subdued" type="questionInCircle" className="eui-alignTop" />
                </span>
              </EuiToolTip>
            ),
            field: 'price',
            render: (_, asset) =>
              loadingExtendedProfile.loading ? loadingContent : <PortfolioPositionPrice position={asset} />,
          },
          {
            name: 'Value',
            description: 'Defines the current value of the asset you have (units * price).',
            field: 'value',
            datatype: 'number',
            render: (_, asset) =>
              loadingExtendedProfile.loading ? (
                loadingContent
              ) : (
                <PortfolioPositionPrice position={asset} multiplier={asset.units} />
              ),
            sortable: (asset) => {
              const { basePrice } = asset.profile?.financials ?? {};
              return asset.units * (basePrice ?? asset.price ?? 0);
            },
            footer: totalValueLabel,
          },
          {
            name: 'Actions',
            field: 'asset',
            actions: [
              {
                name: 'Edit',
                description: 'Edit portfolio position',
                isPrimary: true,
                icon: 'pencil',
                type: 'icon',
                onClick: toggleAssetEditModal,
              },
              {
                name: 'View details',
                description: 'View portfolio position details',
                icon: 'inspect',
                type: 'icon',
                isPrimary: true,
                enabled: (asset) => !!asset.profile,
                onClick: toggleAssetDetails,
              },
              {
                name: 'Remove',
                description: 'Remove position from portfolio',
                icon: 'minusInCircle',
                type: 'icon',
                onClick: (asset) => removeAssetFromPortfolio([asset]),
              },
            ],
          },
        ]}
      />
      {assetToInspect?.profile ? (
        <AssetDetailsFlyout
          assetSourceId={assetToInspect.profile.sourceId}
          assetName={assetToInspect.profile.name}
          onClose={toggleAssetDetails}
        />
      ) : null}
      {assetToEdit ? (
        <EditPositionModal position={assetToEdit} onCancel={toggleAssetEditModal} onSave={saveAssetValues} />
      ) : null}
    </>
  );
}
