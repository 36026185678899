import React, { ChangeEvent, MouseEventHandler, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSelect,
  EuiButton,
  EuiCallOut,
  EuiLink,
  EuiEmptyPrompt,
  EuiIcon,
  EuiSpacer,
  EuiBadge,
  EuiTitle,
  EuiHorizontalRule,
  EuiPopover,
  EuiContextMenuPanel,
  EuiContextMenuItem,
  EuiStat,
  EuiToolTip,
} from '@elastic/eui';
import { DeletePortfolioModal } from '../../components';
import {
  CSVReporter,
  formatTer,
  formatAum,
  isFundAnalyzable,
  settingsDontShowDataNotice,
  settingsRemovePortfolio,
  Portfolio,
  Downloader,
  parseAssetSourceId,
  formatPrice,
} from '../../model';
import { PageContext } from '../../page_container';
import { DetailedPortfolioFund, PortfoliosFundsTable } from './portfolios_funds_table';
import { DetailedPortfolioAsset, PortfoliosAssetsTable } from './portfolios_assets_table';
import { ImportPortfolioModal } from './portfolios_import_portfolio_modal';
import { usePageMeta } from '../../hooks';

export function PortfoliosPage() {
  usePageMeta('Portfolios');

  const navigate = useNavigate();
  const { settings, setSettings, parameters, getURL } = useContext(PageContext);

  const { portfolio: portfolioNameParam } = useParams<{ portfolio?: string }>();
  const getPortfolio = () => {
    return (
      (portfolioNameParam ? settings.portfolios.find((portfolio) => portfolio.name === portfolioNameParam) : null) ??
      settings.portfolios.find((portfolio) => portfolio.assets.length > 0 || portfolio.funds.length > 0) ??
      null
    );
  };

  const [currentPortfolio, setCurrentPortfolio] = useState<Portfolio | null>(getPortfolio());

  const [portfolioTotals, setPortfolioTotals] = useState<{
    fundsValue: number | null;
    fundsTer: number | null;
    assetsValue: number | null;
  }>({ fundsValue: null, fundsTer: null, assetsValue: null });
  const [selectedFunds, setSelectedFunds] = useState<DetailedPortfolioFund[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<DetailedPortfolioAsset[]>([]);

  useEffect(() => {
    if (currentPortfolio && (currentPortfolio.assets.length > 0 || currentPortfolio.funds.length > 0)) {
      return;
    }

    const portfolio = portfolioNameParam
      ? settings.portfolios.find((portfolio) => portfolio.name === portfolioNameParam)
      : undefined;

    setCurrentPortfolio(
      portfolio ??
        settings.portfolios.find((portfolio) => portfolio.assets.length > 0 || portfolio.funds.length > 0) ??
        null,
    );
  }, [portfolioNameParam, currentPortfolio, settings]);

  useEffect(() => {
    if (currentPortfolio) {
      setPortfolioTotals({ assetsValue: null, fundsValue: null, fundsTer: null });
      navigate(getURL(`/portfolios/${encodeURIComponent(currentPortfolio.name)}`), { replace: true });
    } else {
      navigate(getURL('/portfolios'), { replace: true });
    }
  }, [currentPortfolio, getURL]);

  const [isDeletePortfolioModalVisible, setIsDeletePortfolioModalVisible] = useState<boolean>(false);
  const toggleDeletePortfolioModal = useCallback(() => {
    setIsDeletePortfolioModalVisible(!isDeletePortfolioModalVisible);
  }, [isDeletePortfolioModalVisible]);
  const deletePortfolio = useCallback(() => {
    if (currentPortfolio) {
      setSettings(settingsRemovePortfolio(settings, currentPortfolio.name));
      setCurrentPortfolio(null);
    }

    setIsDeletePortfolioModalVisible(false);
  }, [settings, currentPortfolio]);

  const [isImportPortfolioModalVisible, setIsImportPortfolioModalVisible] = useState<boolean>(false);
  const toggleImportPortfolioModal = useCallback(() => {
    setIsImportPortfolioModalVisible(!isImportPortfolioModalVisible);
  }, [isImportPortfolioModalVisible]);
  const importPortfolio = useCallback((portfolio: Portfolio) => {
    setIsImportPortfolioModalVisible(false);
    setCurrentPortfolio(portfolio);
  }, []);

  const analyzableSelectionState = useMemo(() => {
    const nonAnalyzableFund = selectedFunds.find((fund) => !fund.profile || !isFundAnalyzable(fund.profile));
    const assetClassName =
      (nonAnalyzableFund?.profile?.basics?.assetClass &&
        parameters.fundAssetClasses.get(nonAnalyzableFund?.profile?.basics?.assetClass)?.name) ??
      nonAnalyzableFund?.profile?.basics?.assetClass ??
      'Unknown';
    const { maxFundsToAnalyze, maxAssetsToAnalyze } = parameters.license;

    const fundsExceeded = selectedFunds.length > maxFundsToAnalyze;
    const assetsExceeded = selectedAssets.length > maxAssetsToAnalyze;

    const fundWithZeroValue = selectedFunds.find(
      (fund) => fund.units * (fund.profile?.financials?.basePrice ?? fund.price ?? 0) === 0,
    );
    const assetWithZeroValue = selectedAssets.find(
      (asset) => asset.units * (asset.profile?.financials?.basePrice ?? asset.price ?? 0) === 0,
    );
    if (fundWithZeroValue != null || assetWithZeroValue != null) {
      return {
        allComparable: false,
        message:
          'You can only analyze assets with non-zero value. Set non-zero price and units values for all selected assets and try again.',
      };
    }

    if (nonAnalyzableFund == null && !fundsExceeded && !assetsExceeded) {
      return { allComparable: true };
    }

    if (nonAnalyzableFund != null) {
      return { allComparable: false, message: `"${assetClassName}" funds cannot be analyzed.` };
    }

    return {
      allComparable: false,
      message:
        `You can analyze up to ${maxFundsToAnalyze} funds and ${maxAssetsToAnalyze} assets at once, ` +
        `but selected ${selectedFunds.length} funds and ${selectedAssets.length} assets.`,
    };
  }, [parameters, selectedFunds, selectedAssets]);

  const dismissDataNotice = useCallback(() => {
    setSettings(settingsDontShowDataNotice(settings));
  }, [settings]);

  const [isExportPopoverOpen, setIsExportPopoverOpen] = useState<boolean>(false);
  const onExportAsCSV = useCallback(() => {
    setIsExportPopoverOpen(false);

    const fundsRows: string[][] =
      selectedFunds.length > 0
        ? [
            ['Type', 'ID', 'Name', 'Asset Class', 'TER', 'AUM', 'Units', 'Link'],
            ...selectedFunds.map((fund) => [
              'ETF',
              fund.isin,
              fund.profile
                ? `${parameters.providers.get(fund.profile.provider)!.name} ${fund.profile.name.toUpperCase()}`
                : '-',
              fund.profile?.basics
                ? parameters.fundAssetClasses.get(fund.profile?.basics.assetClass)?.name ??
                  fund.profile?.basics.assetClass
                : '-',
              fund.profile?.basics ? formatTer(fund.profile?.basics.ter) : '-',
              fund.profile?.basics ? formatAum(fund.profile?.basics.aum) : '-',
              fund.units.toString(),
              fund.profile?.basics ? fund.profile?.basics.url : '',
            ]),
          ]
        : [];
    const assetsRows: string[][] =
      selectedAssets.length > 0
        ? [
            ['Type', 'ID', 'Name', 'Sector', 'Country', 'Market Cap', 'Units', 'Link'],
            ...selectedAssets.map((asset) => [
              'Asset',
              asset.profile?.id ?? parseAssetSourceId(asset.id),
              asset.profile?.name.toUpperCase() ?? asset.name?.toUpperCase() ?? '-',
              asset.profile?.basics?.sector ?? '-',
              asset.profile?.basics?.country ?? '-',
              formatAum(asset.profile?.financials?.baseMarketCap ?? 0),
              asset.units.toString(),
              asset.profile?.basics?.url ?? '',
            ]),
          ]
        : [];
    CSVReporter.download(`portfolio-${currentPortfolio?.name}.csv`, [
      ...fundsRows,
      ...(fundsRows.length > 0 && assetsRows.length > 0 ? [['', '', '', '', '', '', '', '']] : []),
      ...assetsRows,
    ]);
  }, [settings, selectedFunds, selectedAssets, currentPortfolio, parameters]);

  const onExportAsJSON = useCallback(() => {
    setIsExportPopoverOpen(false);

    if (!currentPortfolio) {
      return;
    }

    Downloader.download(
      `portfolio-${currentPortfolio.name}.json`,
      JSON.stringify({
        name: currentPortfolio.name,
        funds:
          selectedFunds.length > 0
            ? selectedFunds.map((fund) => ({
                isin: fund.isin,
                name: fund.profile?.name.toUpperCase() ?? fund.name?.toUpperCase(),
                ter: fund.profile?.basics?.ter,
                aum: fund.profile?.basics?.aum,
                infoLink: fund.profile?.basics?.url,
                units: fund.units,
                price: fund.price,
              }))
            : [],
        assets:
          selectedAssets.length > 0
            ? selectedAssets.map((asset) => ({
                id: asset.id,
                name: asset.profile?.name.toUpperCase() ?? asset.name ?? '-',
                infoLink: asset.profile?.basics?.url,
                units: asset.units,
                price: asset.price,
              }))
            : [],
      }),
      'data:text/json;charset=utf-8',
    );
  }, [settings, selectedFunds, selectedAssets, currentPortfolio, parameters]);

  const onAnalyze = useCallback(() => {
    navigate(
      getURL(
        `/analyze?q=${encodeURIComponent(
          btoa(
            JSON.stringify({
              funds: selectedFunds.map((fund) => [
                fund.isin,
                fund.units * (fund.profile?.financials?.basePrice ?? fund.price ?? 0),
              ]),
              assets: selectedAssets.map((asset) => [
                asset.id,
                asset.units * (asset.profile?.financials?.basePrice ?? asset.price ?? 0),
              ]),
            }),
          ),
        )}`,
      ),
    );
  }, [selectedFunds, selectedAssets, getURL]);

  const onFundSelectionChange = useCallback((selectedFunds: DetailedPortfolioFund[]) => {
    setSelectedFunds(selectedFunds);
  }, []);

  const onFundsValueChange = useCallback((value: number, ter: number) => {
    setPortfolioTotals((totals) => ({ ...totals, fundsValue: value, fundsTer: ter }));
  }, []);

  const onAssetSelectionChange = useCallback((selectedAssets: DetailedPortfolioAsset[]) => {
    setSelectedAssets(selectedAssets);
  }, []);

  const onAssetsValueChange = useCallback((value: number) => {
    setPortfolioTotals((totals) => ({ ...totals, assetsValue: value }));
  }, []);

  const onPortfolioChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setCurrentPortfolio(settings.portfolios.find((portfolio) => portfolio.name === e.target.value) ?? null);
    },
    [settings],
  );

  const onNavigateToFunds: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      e.preventDefault();

      navigate(getURL('/'));
    },
    [getURL],
  );

  const onNavigatePrivacyPolicy: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      e.preventDefault();
      navigate(getURL('/privacy'));
    },
    [getURL],
  );

  const importPortfolioButton = (
    <EuiButton
      iconType={'importAction'}
      onClick={toggleImportPortfolioModal}
      title="Import previously exported portfolio"
    >
      Import
    </EuiButton>
  );

  const importModal = isImportPortfolioModalVisible ? (
    <ImportPortfolioModal onCancel={toggleImportPortfolioModal} onImport={importPortfolio} />
  ) : null;

  if (!currentPortfolio) {
    return (
      <EuiFlexGroup
        direction={'column'}
        gutterSize={'s'}
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <EuiFlexItem>
          <EuiEmptyPrompt
            icon={<EuiIcon type={'submodule'} size={'xl'} />}
            title={<h2>You don't have any portfolios yet</h2>}
            titleSize="s"
            style={{ maxWidth: '60em', display: 'flex' }}
            body={
              <div>
                <p>
                  Go to the{' '}
                  <EuiLink href={getURL('/')} onClick={onNavigateToFunds}>
                    Funds page
                  </EuiLink>{' '}
                  and save some funds to a portfolio.
                </p>
                {importPortfolioButton}
              </div>
            }
          />
        </EuiFlexItem>
        {importModal}
      </EuiFlexGroup>
    );
  }

  const selectionAnalyzable =
    selectedFunds.length + selectedAssets.length >= 2 && analyzableSelectionState.allComparable;

  const dataNotice =
    settings.dontShowDataNotice !== true ? (
      <EuiFlexItem grow={false}>
        <EuiCallOut title="Where is your data stored?" color="warning" iconType="help">
          <p>
            Your portfolios and personal settings are stored locally in the cache of your browser and not synced between
            your devices. No one except you can access your data, and it remains available as long as you don't clear
            the cache. For more information, refer to{' '}
            <EuiLink href={getURL('/privacy')} onClick={onNavigatePrivacyPolicy}>
              Privacy Policy
            </EuiLink>
            .
          </p>
          <p>
            You can backup and transfer your portfolios at any time by using the{' '}
            <EuiBadge color="hollow" iconType="exportAction" iconSide="left">
              Export
            </EuiBadge>{' '}
            functionality.
          </p>
          <EuiButton color="warning" onClick={dismissDataNotice}>
            Understood
          </EuiButton>
        </EuiCallOut>
        <EuiSpacer />
      </EuiFlexItem>
    ) : null;

  const fundsTable =
    currentPortfolio && currentPortfolio.funds.length > 0 ? (
      <EuiFlexItem grow={false}>
        <EuiSpacer />
        <EuiTitle>
          <h3>ETFs</h3>
        </EuiTitle>
        <EuiSpacer />
        <PortfoliosFundsTable
          portfolio={currentPortfolio}
          onSelectionChange={onFundSelectionChange}
          onValueChange={onFundsValueChange}
        />
      </EuiFlexItem>
    ) : null;

  const assetsTable =
    currentPortfolio && currentPortfolio.assets.length > 0 ? (
      <EuiFlexItem grow={false}>
        {currentPortfolio.funds.length > 0 ? <EuiHorizontalRule size="half" /> : <EuiSpacer />}
        <EuiTitle>
          <h3>Stocks, Bonds, and Other Securities</h3>
        </EuiTitle>
        <EuiSpacer />
        <PortfoliosAssetsTable
          portfolio={currentPortfolio}
          onSelectionChange={onAssetSelectionChange}
          onValueChange={onAssetsValueChange}
        />
      </EuiFlexItem>
    ) : null;

  const deleteModal = isDeletePortfolioModalVisible ? (
    <DeletePortfolioModal
      portfolioName={currentPortfolio.name}
      onCancel={toggleDeletePortfolioModal}
      onConfirm={deletePortfolio}
    />
  ) : null;

  const areTotalsLoading =
    !currentPortfolio ||
    (currentPortfolio.assets.length > 0 && portfolioTotals.assetsValue === null) ||
    (currentPortfolio.funds.length > 0 && portfolioTotals.fundsValue === null);

  const totalPortfolioTer =
    currentPortfolio.funds.length > 0 ? (
      <EuiFlexItem>
        <EuiStat
          reverse
          title={formatTer(portfolioTotals.fundsTer ?? 0)}
          isLoading={areTotalsLoading}
          description={
            <EuiToolTip content="Total Expense Ratio (TER) of the portfolio is the weighted average of all ETF TERs">
              <span>
                Total Expense Ratio (TER)
                <EuiIcon size="s" color="subdued" type="questionInCircle" className="eui-alignTop" />
              </span>
            </EuiToolTip>
          }
          titleSize="m"
          textAlign="center"
        />
      </EuiFlexItem>
    ) : null;

  return (
    <>
      <EuiFlexGroup direction={'column'} gutterSize={'s'} style={{ height: '100%' }}>
        {dataNotice}
        <EuiFlexItem grow={false}>
          <EuiForm>
            <EuiFormRow fullWidth>
              <EuiFlexGroup direction={'row'} alignItems="center" justifyContent={'spaceBetween'} gutterSize={'s'}>
                <EuiFlexItem>
                  <EuiSelect
                    prepend={'Portfolio'}
                    options={settings.portfolios.map(({ name }) => ({ value: name, text: name }))}
                    value={currentPortfolio.name}
                    onChange={onPortfolioChange}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>{importPortfolioButton}</EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiPopover
                    anchorClassName="eui-fullWidth"
                    button={
                      <EuiButton
                        fullWidth={true}
                        disabled={selectedFunds.length === 0 && selectedAssets.length === 0}
                        iconType={'exportAction'}
                        onClick={() => setIsExportPopoverOpen(!isExportPopoverOpen)}
                        title={
                          selectedFunds.length > 0 || selectedAssets.length > 0
                            ? 'Export selected funds and assets'
                            : 'Select at least one fund or asset to use export'
                        }
                      >
                        Export
                      </EuiButton>
                    }
                    isOpen={isExportPopoverOpen}
                    closePopover={() => setIsExportPopoverOpen(false)}
                    panelPaddingSize="none"
                    anchorPosition="downLeft"
                  >
                    <EuiContextMenuPanel
                      size="m"
                      items={[
                        <EuiContextMenuItem key="csv" icon="visTable" onClick={onExportAsCSV}>
                          CSV
                        </EuiContextMenuItem>,
                        <EuiContextMenuItem key="json" icon="nested" onClick={onExportAsJSON}>
                          JSON
                        </EuiContextMenuItem>,
                      ]}
                    />
                  </EuiPopover>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    disabled={!selectionAnalyzable}
                    iconType={analyzableSelectionState.allComparable ? 'inputOutput' : 'alert'}
                    onClick={onAnalyze}
                    title={
                      selectionAnalyzable
                        ? 'Analyze selected funds and assets'
                        : analyzableSelectionState.message ?? 'Select at least two funds or assets to analyze'
                    }
                  >
                    Analyze
                  </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    color="danger"
                    iconType="trash"
                    onClick={toggleDeletePortfolioModal}
                    title="Delete portfolio"
                  >
                    Delete
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFormRow>
          </EuiForm>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiSpacer />
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiStat
                reverse
                title={formatPrice((portfolioTotals.assetsValue ?? 0) + (portfolioTotals.fundsValue ?? 0))}
                description="Total Portfolio Value (TPV)"
                isLoading={areTotalsLoading}
                titleSize="m"
                textAlign="center"
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiStat
                reverse
                title={currentPortfolio ? currentPortfolio.funds.length + currentPortfolio.assets.length : '-'}
                description="Total Portfolio Positions"
                isLoading={!currentPortfolio}
                titleSize="m"
                textAlign="center"
              />
            </EuiFlexItem>
            {totalPortfolioTer}
          </EuiFlexGroup>
        </EuiFlexItem>
        {fundsTable}
        {assetsTable}
      </EuiFlexGroup>
      {deleteModal}
      {importModal}
    </>
  );
}
