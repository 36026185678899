import { EuiCallOut, EuiLink } from '@elastic/eui';
import React from 'react';

export interface FundSyntheticNoticeProps {
  title: string;
}

export function FundSyntheticNotice({ title }: FundSyntheticNoticeProps) {
  return (
    <EuiCallOut title={title} color="warning" iconType="beaker">
      <p>
        Unlike physical ETF, a synthetic ETF does not hold the underlying assets it is designed to track. Instead, the
        ETF issuer enters into a swap agreement with a counterparty that contracts to provide the return of the
        underlying assets. Some ETF issuers buy a basket of assets as a collateral, usually from the swap counterparty,{' '}
        <strong>unrelated to the assets being tracked</strong>. In this case, we might treat collateral assets as the
        fund assets instead.
      </p>
      <p>
        For more information, refer to{' '}
        <EuiLink href="https://www.investopedia.com/terms/s/synthetic-etf.asp" target={'_blank'}>
          Synthetic Exchange-Traded Fund (ETF)
        </EuiLink>
        .
      </p>
    </EuiCallOut>
  );
}
