export enum AlertType {
  AssetIsAddedToFund = 'assetAddedToFund',
  AssetIsRemovedFromFund = 'assetRemovedFromFund',
  Unknown = 'unknown',
}

export type Alert = { timestamp: string } & AlertDescriptor;
export type AlertDescriptor =
  | { type: AlertType.AssetIsAddedToFund | AlertType.AssetIsRemovedFromFund; assetSourceId: string }
  | { type: AlertType.Unknown };
