function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */
import chroma from 'chroma-js';
export var HEX_FALLBACK = '';
export var HSV_FALLBACK = [0, 0, 0];
export var RGB_FALLBACK = [NaN, NaN, NaN, 1];
export var RGB_JOIN = ', '; // Given a string, this attempts to return a format that can be consumed by chroma-js

export var parseColor = function parseColor(input) {
  var parsed;
  if (!input) return null;

  if (input.indexOf(',') > 0) {
    if (!/^[\s,.0-9]*$/.test(input)) {
      return null;
    }

    var rgb = input.trim().split(',').filter(function (n) {
      return n !== '';
    }).map(Number);
    parsed = rgb.length > 2 && rgb.length < 5 ? rgb : HEX_FALLBACK;
  } else {
    parsed = input;
  }

  return parsed;
}; // Returns whether the given input will return a valid chroma-js object when designated as one of
// the acceptable formats: hex, rgb, rgba

export var chromaValid = function chromaValid(color) {
  var parsed = color;

  if (typeof color === 'string') {
    parsed = parseColor(color);
  }

  if (!parsed) return false;

  if (_typeof(parsed) === 'object') {
    return chroma.valid(parsed, 'rgb') || chroma.valid(parsed, 'rgba');
  }

  return chroma.valid(color, 'hex');
};