import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { css } from '@emotion/react';
import {
  EuiMark,
  EuiInMemoryTable,
  Pagination,
  Criteria,
  EuiCallOut,
  EuiIcon,
  EuiToolTip,
  PropertySort,
  EuiText,
  EuiLoadingContent,
  useCurrentEuiBreakpoint,
  EuiBasicTableColumn,
} from '@elastic/eui';
import { FundDetailsFlyout, FundNameLabel } from '../../components';
import {
  AsyncData,
  formatTer,
  PAGINATION,
  PortfolioFund,
  Portfolio,
  settingsRemovePortfolio,
  FundProfile,
  SerializedFundProfile,
  deserializeFundProfile,
  settingsGetBaseCurrency,
  formatPrice,
  isAbortError,
} from '../../model';
import { PageContext } from '../../page_container';
import { EditPositionModal, PortfolioPositionValues } from './portfolios_edit_position_modal';
import { PortfolioPositionPrice } from './portfolios_position_price';

export interface DetailedPortfolioFund extends PortfolioFund {
  profile?: FundProfile;
}

export interface PortfoliosFundsTableProps {
  portfolio: Portfolio;
  onSelectionChange?: (selectedFunds: DetailedPortfolioFund[]) => void;
  onValueChange?: (value: number, ter: number) => void;
}

export function PortfoliosFundsTable({ portfolio, onSelectionChange, onValueChange }: PortfoliosFundsTableProps) {
  const { settings, setSettings, parameters } = useContext(PageContext);
  const currentBreakpoint = useCurrentEuiBreakpoint();

  // Clear selection if portfolio changes.
  const [selectedFunds, setSelectedFunds] = useState<DetailedPortfolioFund[]>([]);
  const tableRef = useRef<EuiInMemoryTable<DetailedPortfolioFund>>();

  // Resolve funds as soon as parameters are available.
  const [loadingExtendedProfile, setLoadingExtendedProfile] = useState({
    loading: true,
    portfolioName: portfolio.name,
  });
  const [funds, setFunds] = useState<AsyncData<DetailedPortfolioFund[], string> | null>(null);
  useEffect(() => {
    if (!parameters.synced) {
      return;
    }

    tableRef?.current?.setSelection([]);

    // If there is no funds to resolve, bail out.
    if (portfolio.funds.length === 0) {
      setFunds({ status: 'succeeded', data: [] });
      return;
    }

    setFunds({ status: 'pending', state: portfolio.name });
    setLoadingExtendedProfile({ loading: true, portfolioName: portfolio.name });

    const controller = new AbortController();
    const fundIds = portfolio.funds.map(({ isin }) => isin);
    axios
      .post(
        '/api/funds/profile',
        { domicile: parameters.domicileCode, ids: fundIds, includeBasics: true },
        { signal: controller.signal },
      )
      .then(
        ({ data }: { data: { profiles: Array<SerializedFundProfile | null> } }) => {
          setFunds((prevState) => {
            if (prevState?.state !== portfolio.name) {
              return prevState;
            }

            return {
              status: 'succeeded',
              state: prevState?.state,
              data: portfolio.funds.map((portfolioFund, index) => {
                const deserializedProfile = data.profiles[+index]
                  ? deserializeFundProfile(data.profiles[+index]!)
                  : undefined;

                // If we couldn't load profile previously, just use this profile as is.
                if (prevState?.status !== 'succeeded') {
                  return { ...portfolioFund, profile: deserializedProfile };
                }

                const previousFund = prevState.data[+index];
                if (!deserializedProfile) {
                  return previousFund;
                }

                return {
                  ...previousFund,
                  profile: deserializedProfile ? { ...previousFund.profile, ...deserializedProfile } : undefined,
                };
              }),
            };
          });
        },
        (err) => {
          setFunds((prevState) => {
            if (isAbortError(err) || prevState?.state !== portfolio.name) {
              return prevState;
            }

            return { status: 'failed', error: err?.message ?? 'Unknown error', state: prevState.state };
          });
        },
      );

    axios
      .post(
        '/api/funds/profile',
        { domicile: parameters.domicileCode, ids: fundIds, includeFinancials: true },
        { signal: controller.signal },
      )
      .then(
        ({ data }: { data: { profiles: Array<SerializedFundProfile | null> } }) => {
          setLoadingExtendedProfile((prevState) => {
            if (prevState.portfolioName !== portfolio.name) {
              return prevState;
            }

            return { ...prevState, loading: false };
          });

          setFunds((prevState) => {
            if (prevState?.state !== portfolio.name) {
              return prevState;
            }

            return {
              status: 'succeeded',
              state: prevState?.state,
              data: portfolio.funds.map((portfolioFund, index) => {
                const deserializedProfile = data.profiles[+index]
                  ? deserializeFundProfile(data.profiles[+index]!)
                  : undefined;

                // If we couldn't load profile previously, just use this profile as is.
                if (prevState?.status !== 'succeeded') {
                  return { ...portfolioFund, profile: deserializedProfile };
                }

                const previousFund = prevState.data[+index];
                if (!deserializedProfile) {
                  return previousFund;
                }

                return {
                  ...previousFund,
                  profile: deserializedProfile ? { ...previousFund.profile, ...deserializedProfile } : undefined,
                };
              }),
            };
          });
        },
        (err) => {
          setLoadingExtendedProfile((prevState) => {
            if (isAbortError(err) || prevState.portfolioName !== portfolio.name) {
              return prevState;
            }

            return { ...prevState, loading: false };
          });
        },
      );

    return () => {
      controller.abort();
    };
  }, [portfolio, parameters, tableRef]);

  const removeFundFromPortfolio = useCallback(
    (fundISINs: string[]) => {
      if (funds?.status !== 'succeeded') {
        return;
      }

      let selectionUpdated = false;
      for (const fundISIN of fundISINs) {
        portfolio.funds.splice(
          portfolio.funds.findIndex(({ isin }) => isin === fundISIN),
          1,
        );
        funds.data.splice(
          funds.data.findIndex(({ isin }) => isin === fundISIN),
          1,
        );

        const indexInCurrentSelection = selectedFunds.findIndex(({ isin }) => isin === fundISIN);
        if (indexInCurrentSelection >= 0) {
          selectionUpdated = true;
          selectedFunds.splice(indexInCurrentSelection, 1);
        }
      }

      if (selectionUpdated) {
        const newSelectedFunds = [...selectedFunds];
        setSelectedFunds(newSelectedFunds);
        onSelectionChange?.(newSelectedFunds);
      }

      if (portfolio.funds.length === 0 && portfolio.assets.length === 0) {
        setSettings(settingsRemovePortfolio(settings, portfolio.name));
      } else {
        setSettings({ ...settings });
      }
    },
    [funds, selectedFunds, settings, portfolio],
  );

  const onFundSelectionChange = useCallback((selectedFunds: DetailedPortfolioFund[]) => {
    setSelectedFunds(selectedFunds);
    onSelectionChange?.(selectedFunds);
  }, []);

  const [fundToInspect, setFundToInspect] = useState<DetailedPortfolioFund | null>(null);
  const toggleFundDetails = useCallback(
    (fund?: DetailedPortfolioFund) => {
      setFundToInspect(fund === fundToInspect ? null : fund ?? null);
    },
    [fundToInspect],
  );

  const [fundToEdit, setFundToEdit] = useState<DetailedPortfolioFund | null>(null);
  const toggleFundEditModal = (fund?: DetailedPortfolioFund) => {
    setFundToEdit((currentFund) => (fund === currentFund ? null : fund ?? null));
  };
  const saveFundValues = (values: PortfolioPositionValues) => {
    toggleFundEditModal();

    if (!fundToEdit) {
      return;
    }

    const portfolioFundToUpdate = portfolio.funds.find((portfolioAsset) => portfolioAsset.isin === fundToEdit.isin);
    if (!portfolioFundToUpdate) {
      return;
    }

    if (values.units !== undefined) {
      portfolioFundToUpdate.units = fundToEdit.units = values.units;
    }

    if (values.price !== undefined) {
      if (values.price > 0) {
        portfolioFundToUpdate.price = fundToEdit.price = values.price;
      } else {
        delete fundToEdit.price;
        delete portfolioFundToUpdate.price;
      }
    }

    setSettings({ ...settings });
  };

  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: settings.defaultPageSize,
    pageSizeOptions: PAGINATION.pageSizeOptions,
    totalItemCount: 0,
  });
  const [sorting, setSorting] = useState<{ sort: PropertySort }>({ sort: { field: '', direction: 'asc' } });
  const onTableChange = useCallback(
    ({ page, sort }: Criteria<DetailedPortfolioFund>) => {
      setPagination({
        ...pagination,
        pageIndex: page?.index ?? 0,
        pageSize: page?.size ?? settings.defaultPageSize,
      });

      if (sort) {
        setSorting({ sort });
      }
    },
    [pagination, settings],
  );

  let noItemsMessage;
  if (funds?.status === 'failed') {
    noItemsMessage = (
      <EuiCallOut title="Failed to retrieve portfolio funds" color="danger" iconType="alert">
        <p>
          It is not possible to retrieve portfolio&nbsp;
          <EuiMark>{portfolio.name}</EuiMark> funds at the moment. Try again later.
        </p>
      </EuiCallOut>
    );
  } else if (!funds || funds.status === 'pending') {
    noItemsMessage = 'Loading...';
  }

  const loadingContent = (
    <EuiLoadingContent
      css={css`
        width: 100%;
      `}
      lines={1}
    />
  );

  const { totalValueLabel, totalValue, totalTerLabel, totalTer } = useMemo(() => {
    if (!funds || funds.status !== 'succeeded' || loadingExtendedProfile.loading) {
      return { totalValue: undefined, totalTerLabel: undefined };
    }

    let totalValue = 0;
    let totalTer = 0;
    for (const fund of funds.data) {
      const { basePrice } = fund.profile?.financials ?? {};
      const { ter } = fund.profile?.basics ?? {};

      const value = fund.units * (basePrice ?? fund.price ?? 0);
      totalValue += value;
      totalTer += value * (ter ?? 0);
    }

    totalTer = totalTer / totalValue;

    return {
      totalValue,
      totalValueLabel: totalValue > 0 ? <strong>{formatPrice(totalValue)}</strong> : undefined,
      totalTer,
      totalTerLabel:
        totalTer > 0 ? (
          <EuiToolTip content="Total Expense Ratio (TER) of the portfolio is the weighted average of all ETF TERs">
            <span>
              <strong>{formatTer(totalTer)}</strong>
              <EuiIcon size="s" color="subdued" type="questionInCircle" className="eui-alignTop" />
            </span>
          </EuiToolTip>
        ) : undefined,
    };
  }, [funds, loadingExtendedProfile, settings]);

  useEffect(() => {
    if (totalValue !== undefined || totalTer !== undefined) {
      onValueChange?.(totalValue as number, totalTer as number);
    }
  }, [totalValue, totalTer]);

  return (
    <>
      <EuiInMemoryTable
        // @ts-expect-error no definition
        ref={tableRef}
        pagination={pagination}
        allowNeutralSort={true}
        sorting={sorting}
        tableLayout={'auto'}
        onTableChange={onTableChange}
        items={funds?.status === 'succeeded' ? funds.data : []}
        itemId="isin"
        loading={!funds || funds.status === 'pending'}
        selection={{ onSelectionChange: onFundSelectionChange }}
        noItemsMessage={noItemsMessage}
        columns={[
          ...(currentBreakpoint === 'm'
            ? []
            : [
                {
                  name: 'ID',
                  field: 'isin',
                  width: '145px',
                  render: (isin: string) => (
                    <EuiText size="s" className="eui-textNoWrap">
                      {isin}
                    </EuiText>
                  ),
                } as EuiBasicTableColumn<DetailedPortfolioFund>,
              ]),
          {
            name: 'Name',
            field: 'profile',
            textOnly: true,
            render: (profile?: FundProfile) =>
              profile ? <FundNameLabel fundOrProfile={profile} displayHints displayProvider /> : '-',
            footer: funds?.status === 'succeeded' ? <strong>{funds.data.length} funds</strong> : undefined,
            sortable: ({ profile }) => (profile ? `${profile.provider} ${profile.name}` : '-'),
          },
          ...(currentBreakpoint === 'm'
            ? []
            : ([
                {
                  name: 'Asset Class',
                  field: 'assetClass',
                  render: (_, fund: DetailedPortfolioFund) => (
                    <EuiText size="s" className="eui-textNoWrap">
                      {fund.profile?.basics?.assetClass
                        ? parameters.fundAssetClasses.get(fund.profile?.basics?.assetClass)?.name ?? '-'
                        : '-'}
                    </EuiText>
                  ),
                  sortable: ({ profile }) => profile?.basics?.assetClass || '',
                },
                {
                  name: (
                    <EuiToolTip content="Total Expense Ratio (TER)">
                      <span>
                        TER <EuiIcon size="s" color="subdued" type="questionInCircle" className="eui-alignTop" />
                      </span>
                    </EuiToolTip>
                  ),
                  field: 'ter',
                  render: (_, fund) => (
                    <EuiText size="s" className="eui-textNoWrap">
                      {fund.profile?.basics?.ter !== undefined ? formatTer(fund.profile.basics.ter) : '-'}
                    </EuiText>
                  ),
                  sortable: ({ profile }) => profile?.basics?.ter || 0,
                  footer: totalTerLabel,
                },
              ] as Array<EuiBasicTableColumn<DetailedPortfolioFund>>)),
          {
            name: 'Units',
            description: 'Defines how much of the fund you have in your portfolio.',
            field: 'units',
            render: (units: number) => (
              <EuiText size="s" className="eui-textNoWrap">
                {units}
              </EuiText>
            ),
            datatype: 'number',
          },
          {
            name: (
              <EuiToolTip content={`Price, converted to ${settingsGetBaseCurrency()}`}>
                <span>
                  Price <EuiIcon size="s" color="subdued" type="questionInCircle" className="eui-alignTop" />
                </span>
              </EuiToolTip>
            ),
            field: 'price',
            render: (_, fund) =>
              loadingExtendedProfile.loading ? loadingContent : <PortfolioPositionPrice position={fund} />,
          },
          {
            name: 'Value',
            description: 'Defines the current value of the asset you have (units * price).',
            field: 'value',
            datatype: 'number',
            render: (_, fund) =>
              loadingExtendedProfile.loading ? (
                loadingContent
              ) : (
                <PortfolioPositionPrice position={fund} multiplier={fund.units} />
              ),
            sortable: (asset) => {
              const { basePrice } = asset.profile?.financials ?? {};
              return asset.units * (basePrice ?? asset.price ?? 0);
            },
            footer: totalValueLabel,
          },
          {
            name: 'Actions',
            field: 'fund',
            actions: [
              {
                name: 'Edit',
                description: 'Edit portfolio position',
                isPrimary: true,
                icon: 'pencil',
                type: 'icon',
                onClick: toggleFundEditModal,
              },
              {
                name: 'View details',
                description: 'View portfolio position details',
                icon: 'inspect',
                type: 'icon',
                isPrimary: true,
                onClick: toggleFundDetails,
              },
              {
                name: 'Remove',
                description: 'Remove position from portfolio',
                icon: 'minusInCircle',
                type: 'icon',
                isPrimary: true,
                onClick: (fund) => removeFundFromPortfolio([fund.isin]),
              },
            ],
          },
        ]}
      />
      {fundToInspect?.profile ? (
        <FundDetailsFlyout fundOrProfile={fundToInspect.profile} onClose={toggleFundDetails} />
      ) : null}
      {fundToEdit ? (
        <EditPositionModal position={fundToEdit} onCancel={toggleFundEditModal} onSave={saveFundValues} />
      ) : null}
    </>
  );
}
