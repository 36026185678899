import React from 'react';
import { EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiLoadingLogo } from '@elastic/eui';

export function PageLoadingState() {
  return (
    <EuiFlexGroup
      direction={'column'}
      gutterSize={'s'}
      style={{ height: '100%' }}
      alignItems="center"
      justifyContent="center"
    >
      <EuiFlexItem grow={false}>
        <EuiEmptyPrompt
          icon={<EuiLoadingLogo logo="/assets/azbyte-logo-initial.svg" size="xl" />}
          titleSize="s"
          title={<h2>Loading…</h2>}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
