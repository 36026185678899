import React from 'react';
import { EuiLink, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui';
import { usePageMeta } from '../../hooks';

export function AboutUsPage() {
  usePageMeta('About Us');

  return (
    <>
      <EuiTitle size={'s'} textTransform="uppercase">
        <h1>About Us</h1>
      </EuiTitle>
      <EuiSpacer />
      <EuiText textAlign={'center'} style={{ fontSize: '160%' }}>
        <p>
          <em>We help retail investors to break apart and fully understand their ETF portfolio</em>
        </p>
      </EuiText>
      <EuiSpacer />
      <EuiText>
        <p>
          <strong>Exchange-traded funds (ETF)</strong> have become one of the most popular investment vehicles in less
          than two decades, especially among young and inexperienced investors who believe in and want to benefit from
          the modern megatrends – such as clean energy, artificial intelligence, robotics, cloud computing, and
          sustainable future of food.
        </p>
        <p>
          While it's true that ETF can be easy to understand, cost-efficient, and sufficiently diversified type of
          investments, it's still not something you can buy and forget. Likewise, many seemingly different ETFs overlap
          so much that it wouldn’t make any sense to have them in your portfolio at the same time.
        </p>
        <p>
          Over the long horizon, the only thing that really matters is how solid and sound underlying businesses that
          compose the ETF are. We provide you with the tools to break apart and fully understand your ETF portfolio,
          down to every single company.
        </p>
        <p>
          If you have any questions or ideas, feel free to send us an email to{' '}
          <EuiLink href={'mailto:contact@azbyte.xyz'}>contact@azbyte.xyz</EuiLink>.
        </p>
      </EuiText>
    </>
  );
}
