import { deserializeFundAsset, FundAsset, SerializedFundAsset } from './fund_asset';
import { AssetProfile } from './asset_profile';
import { parseAssetSourceId } from './asset_id';

export interface SerializedFundConflatedAsset {
  i: string;
  n: string;
  f: Array<[string, SerializedFundAsset]>;
}

export interface FundConflatedAsset {
  id: string;
  sourceId: string;
  name: string;
  funds: Array<[string, FundAsset]>;
}

export function getBestSourceId(asset: FundConflatedAsset | AssetProfile) {
  if (!asset.funds || asset.funds.length === 0) {
    return asset.sourceId;
  }

  // ISINs always take precedence.
  return (asset.funds.find(([, asset]) => asset.sourceId.startsWith('I:')) ?? asset.funds[0])[1].sourceId;
}

export function matchesSourceId(asset: FundConflatedAsset | AssetProfile, sourceId: string) {
  if (asset.sourceId === sourceId) {
    return true;
  }

  return asset.funds?.some(([, asset]) => asset.sourceId === sourceId) ?? false;
}

export function deserializeFundConflatedAsset(serializedAsset: SerializedFundConflatedAsset): FundConflatedAsset {
  return {
    id: parseAssetSourceId(serializedAsset.i),
    sourceId: serializedAsset.i,
    name: serializedAsset.n,
    funds: serializedAsset.f.map(([fundIsin, fundAsset]) => [fundIsin, deserializeFundAsset(fundAsset)]),
  };
}
