/** @jsxImportSource @emotion/react */

import React, { useContext, useMemo } from 'react';
import { ClassNames } from '@emotion/react';
import { Fund, FundProfile, isFundProfile } from '../model';
import { EuiHealth } from '@elastic/eui';
import { PageContext } from '../page_container';
import { FundProviderNameLabel } from './fund_provider_name_label';

export interface FundNameLabelProps {
  fundOrProfile: Fund | FundProfile;
  displayHints?: boolean;
  displayProvider?: boolean;
}

export function FundNameLabel({ fundOrProfile, displayHints, displayProvider }: FundNameLabelProps) {
  const { parameters, domicileCode } = useContext(PageContext);

  const isSupportedDomicile = useMemo(
    () => fundOrProfile.domiciles.includes(domicileCode),
    [domicileCode, fundOrProfile],
  );
  const isSynthetic = isFundProfile(fundOrProfile)
    ? fundOrProfile.basics?.isSynthetic ?? false
    : fundOrProfile.isSynthetic;

  const hint = useMemo(() => {
    if (!displayHints || (!isSynthetic && isSupportedDomicile)) {
      return null;
    }

    const title = !isSupportedDomicile
      ? `Fund is available for sale only in the following countries: ${parameters.domiciles
          .filter((domicile) => fundOrProfile.domiciles.includes(domicile.code))
          .map((domicile) => domicile.displayName)
          .join(', ')}`
      : "Instead of holding the underlying security of the index it's designed to track, a synthetic ETF tracks the index using other types of derivatives. This exposes investors to counterparty risk.";

    return (
      <ClassNames>
        {({ css }) => (
          <EuiHealth
            color={!isSupportedDomicile ? '#fec514' : '#d46969'}
            css={css`
              vertical-align: sub;
            `}
            title={title}
          />
        )}
      </ClassNames>
    );
  }, [isSupportedDomicile, isSynthetic, displayHints]);

  return (
    <>
      {displayProvider ? <FundProviderNameLabel fundOrProfile={fundOrProfile} /> : null}
      <ClassNames>
        {({ css }) => (
          <span
            css={css`
              text-transform: uppercase;
              word-break: normal;
            `}
          >
            {fundOrProfile.name}
          </span>
        )}
      </ClassNames>
      {hint}
    </>
  );
}
