import React from 'react';
import { DEFAULT_DOMICILE_CODE, Parameters, Settings, settingsDefault } from '../model';
import { PageToast } from './page_container';

export interface PageContextValue {
  settings: Settings;
  setSettings: (settings: Settings) => void;
  parameters: Parameters;
  domicileCode: string;
  getURL: (path: string) => string;
  addToast: (toast: PageToast) => void;
}

export const PageContext = React.createContext<PageContextValue>({
  settings: settingsDefault(),
  setSettings: () => {
    //
  },
  parameters: {
    synced: false,
    status: { level: 'available' },
    domicileCode: DEFAULT_DOMICILE_CODE,
    domiciles: [],
    providers: new Map(),
    fundAssetClasses: new Map(),
    assetClasses: new Map(),
    notices: [],
    license: {
      maxFundsToAnalyze: Infinity,
      maxAssetsToAnalyze: Infinity,
      maxAlerts: Infinity,
    },
  },
  domicileCode: DEFAULT_DOMICILE_CODE,
  getURL: (path) => path,
  addToast: () => {
    // Empty impl
  },
});
