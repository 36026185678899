import {
  EuiCallOut,
  EuiEmptyPrompt,
  EuiIcon,
  EuiLink,
  EuiLoadingLogo,
  EuiMark,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import React, { MouseEventHandler, ReactNode, useCallback, useContext, useMemo } from 'react';
import { PageContext } from '../../page_container';
import { useNavigate } from 'react-router-dom';

export enum SplashScreenMode {
  Default = 'Default',
  NoData = 'NoData',
  Loading = 'Loading',
}

export interface Params {
  mode: SplashScreenMode;
}

export function AssetsSplashScreen({ mode }: Params) {
  const { getURL } = useContext(PageContext);
  const navigate = useNavigate();
  const onNavigateToFunds: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      e.preventDefault();
      navigate(getURL('/'));
    },
    [getURL],
  );

  const [icon, title, body]: [ReactNode, string, string] = useMemo(() => {
    switch (mode) {
      case 'NoData':
        return [
          <EuiIcon type={'search'} size={'xl'} />,
          'There are no assets to display',
          'Try adjusting your search query',
        ];
      case 'Loading':
        return [
          <EuiLoadingLogo logo="search" size="xl" />,
          'Searching assets…',
          'Use search bar to narrow down the search',
        ];
      default:
        return [
          <EuiIcon type={'search'} size={'xl'} />,
          'Explore assets and learn what ETFs hold them',
          'Use search bar to narrow down the search',
        ];
    }
  }, [mode]);

  return (
    <EuiEmptyPrompt
      icon={icon}
      title={<h2>{title}</h2>}
      titleSize="s"
      style={{ maxWidth: '60em', display: 'flex' }}
      body={
        <div>
          <p>{body}</p>
          <EuiPanel color={'primary'} title={'Search query hints'}>
            <EuiCallOut title={'Search query hints'} iconType={'help'} color={'primary'} style={{ padding: 0 }}>
              <EuiPanel color={'warning'}>
                Go to the{' '}
                <EuiLink href={getURL('/')} onClick={onNavigateToFunds}>
                  Funds page
                </EuiLink>{' '}
                if you want to search for ETFs
              </EuiPanel>
              <EuiSpacer size="s" />
              <div style={{ textAlign: 'left' }}>
                <EuiText size="s">
                  <EuiMark>US02079K3059</EuiMark> - displays asset with ISIN <strong>US02079K3059</strong>
                </EuiText>
                <EuiSpacer size="m" />
                <EuiText size="s">
                  <EuiMark>Alphabet OR "Meta Platforms"</EuiMark> or <EuiMark>Alphabet "Meta Platforms"</EuiMark> -
                  displays all assets with <strong>Alphabet</strong> or <strong>Meta Platforms</strong> in the name (
                  <strong>OR</strong> should be capitalized)
                </EuiText>
                <EuiSpacer size="m" />
                <EuiText size="s">
                  <EuiMark>Hyundai AND Industries</EuiMark> - displays all assets with both <strong>Hyundai</strong> and{' '}
                  <strong>Industries</strong> in the name (<strong>AND</strong> should be capitalized)
                </EuiText>
                <EuiSpacer size="m" />
                <EuiText size="s">
                  <EuiMark>Hyundai -Industries</EuiMark> - displays all assets with <strong>Hyundai</strong> in the name
                  excluding those with <strong>Industries</strong>
                </EuiText>
              </div>
            </EuiCallOut>
          </EuiPanel>
        </div>
      }
    />
  );
}
